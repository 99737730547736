import { Injectable } from '@angular/core';
import { CheckItemCategory, CheckItemCategoryApi, OptionApi, RepairItemCategory, RepairItemCategoryApi } from '../../loopback';
import { map } from 'rxjs/operators';
import { Observable, of } from '../../../node_modules/rxjs';

export interface OptionItem {
  value: number;
  label: string;
}

export interface MultiSelectObject {
  [key: string]: boolean;
}

export interface CheckItemCategoryTarget {
  number: number;
  label: string;
  categories: CheckItemCategory[];
}

export interface RepairItemCategoryTarget {
  number: number;
  label: string;
  categories: RepairItemCategory[];
}

/**
 * 各種選択肢の値とラベルの設定用クラス
 */
@Injectable({
  providedIn: 'root'
})
export class OptionsService {

  /**
   * 汎用のレコードステータス
   */
  recordStatus: OptionItem[] = [
    { value: 1, label: '有効' },
    { value: 2, label: '無効' }
  ];

  /**
   * チェック項目の対象作業
   */
  checkItemTargets: OptionItem[] = [
    { value: 1, label: '巡回' },
    { value: 2, label: '建物診断' },
    { value: 3, label: '部屋診断' }
  ];

  /**
   * 作業ステータス
   */
  workStatus: OptionItem[] = [
    { value: 1, label: '予定' },
    { value: 2, label: '作業中' },
    { value: 3, label: '作業完了' }
  ];

  /**
   * 承認ステータス
   */
  approvalStatus: OptionItem[] = [
    { value: 1, label: '未承認' },
    { value: 2, label: '承認' }
  ];

  /**
   * 診断の劣化度
   */
  inspectionDeterioration: OptionItem[] = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' }
  ];

  /**
   * 診断の緊急度
   */
  inspectionUrgency: OptionItem[] = [
    { value: 1, label: 'A' },
    { value: 2, label: 'B' },
    { value: 3, label: 'C' }
  ];

  /**
   * 修繕費用の数量タイプ
   */
  repairWorkMultiplierType: OptionItem[] = [
    { value: 1, label: '1' },
    { value: 2, label: '戸数' },
    { value: 3, label: '建築面積' },
    { value: 4, label: '床面積' },
    { value: 5, label: '外壁面積' },
    { value: 9, label: '作成時入力' }
  ];

  /**
   * 経年の起算方式
   */
  ageCountType: OptionItem[] = [
    { value: 1, label: '建築年起算' },
    { value: 2, label: '建築年の翌年起算' }
  ];

  /**
   * 建物情報の各種選択肢
   */
  buildingOptions: { [field: string]: OptionItem[] } = {
    otherConditions: [
      { value: 1, label: '鉄道' },
      { value: 2, label: '河川' },
      { value: 3, label: '公園' },
      { value: 4, label: '海沼' },
      { value: -1, label: 'その他' }
    ],
    regionCharacteristic: [
      { value: 1, label: '塩害' },
      { value: 2, label: '凍害' },
      { value: 3, label: '風害' },
      { value: 4, label: '浸水' },
      { value: -1, label: 'その他' }
    ],
    structure: [
      { value: null, label: '選択なし' },
      { value: 1, label: '木造' },
      { value: 4, label: 'RC/鉄骨' }
    ],
    dealingType: [
      { value: null, label: '選択なし' },
      { value: 1, label: '賃貸' },
      { value: 2, label: '分譲' }
    ],
    format: [
      { value: null, label: '選択なし' },
      { value: 1, label: '在来' },
      { value: 2, label: '2x4' },
      { value: 3, label: 'ユニット' },
      { value: 4, label: 'ラーメン' },
      { value: 5, label: '壁' },
      { value: 6, label: 'PC' }
    ],
    seismicRetrofit: [
      { value: null, label: '選択なし' },
      { value: 1, label: '未' },
      { value: 2, label: '済' }
    ],
    foundation: [
      { value: null, label: '選択なし' },
      { value: 1, label: '独立基礎' },
      { value: 2, label: '布基礎' },
      { value: 3, label: 'ベタ基礎' }
    ],
    pile: [
      { value: null, label: '選択なし' },
      { value: 1, label: '無' },
      { value: 2, label: '有' }
    ],
    outerWall1: [
      { value: null, label: '選択なし' },
      { value: 1, label: 'モルタル' },
      { value: 2, label: '金属サイディング' },
      { value: 3, label: '窯業系サイディング' },
      { value: -1, label: 'その他' }
    ],
    outerWall2: [
      { value: null, label: '選択なし' },
      { value: 1, label: 'モルタル' },
      { value: 2, label: '金属サイディング' },
      { value: 3, label: '窯業系サイディング' },
      { value: -1, label: 'その他' }
    ],
    eavesSoffit: [
      { value: null, label: '選択なし' },
      { value: 1, label: 'モルタル' },
      { value: 2, label: '金属板' },
      { value: 3, label: 'ボード' },
      { value: -1, label: 'その他' }
    ],
    roof: [
      { value: null, label: '選択なし' },
      { value: 1, label: '和瓦' },
      { value: 2, label: '洋瓦' },
      { value: 3, label: 'セメント瓦' },
      { value: 4, label: '彩色スレート' },
      { value: 5, label: 'カラー鋼板' },
      { value: -1, label: 'その他' }
    ],
    waterSystem: [
      { value: null, label: '選択なし' },
      { value: 1, label: '直圧' },
      { value: 2, label: '直結増圧' },
      { value: 3, label: '加圧タンク' },
      { value: 4, label: '高架水槽' }
    ],
    waterPipe: [
      { value: null, label: '選択なし' },
      { value: 1, label: 'ライニング鋼管' },
      { value: 2, label: '塩ビ管' },
      { value: 3, label: '架橋ポリエチレン管' }
    ],
    drainSystem: [
      { value: null, label: '選択なし' },
      { value: 1, label: '分流' },
      { value: 2, label: '合流' },
      { value: 3, label: '浄化槽' }
    ],
    drainPipe: [
      { value: null, label: '選択なし' },
      { value: 1, label: '塩ビ管' },
      { value: 2, label: '耐火二層管' },
      { value: 3, label: '鋳鉄管' }
    ],
    hotWaterSystem: [
      { value: null, label: '選択なし' },
      { value: 1, label: 'ガス釜' },
      { value: 2, label: '電気貯湯方式' },
      { value: 3, label: 'ハイブリッド式' }
    ],
    hotWaterPipe: [
      { value: null, label: '選択なし' },
      { value: 1, label: '鋼管' },
      { value: 2, label: '架橋ポリエチレン管' }
    ],
    gasType: [
      { value: null, label: '選択なし' },
      { value: 1, label: 'プロパンガス' },
      { value: 2, label: '都市ガス' }
    ],
    powerReceiving: [
      { value: null, label: '選択なし' },
      { value: 1, label: '低圧受電' },
      { value: 2, label: '高圧受電' }
    ],
    airConditioner: [
      { value: null, label: '選択なし' },
      { value: 1, label: '電気' },
      { value: 2, label: 'ガス' },
      { value: 3, label: '灯油' }
    ],
    fireAlarm: [
      { value: null, label: '選択なし' },
      { value: 1, label: '自動火災報知機' },
      { value: 2, label: '住宅用個別方式' }
    ],
    fireExtinguisher: [
      { value: null, label: '選択なし' },
      { value: 1, label: '無' },
      { value: 2, label: '有' }
    ],
    broadcastEqp: [
      { value: null, label: '選択なし' },
      { value: 1, label: '無' },
      { value: 2, label: '有' }
    ],
    bikePark: [
      { value: null, label: '選択なし' },
      { value: 1, label: '無' },
      { value: 2, label: '有' }
    ],
    carPark: [
      { value: null, label: '選択なし' },
      { value: 1, label: '無' },
      { value: 2, label: '有' }
    ],
    motoPark: [
      { value: null, label: '選択なし' },
      { value: 1, label: '無' },
      { value: 2, label: '有' }
    ]
  };

  /**
   * 日本の都道府県
   */
  prefectures: string[] = [
    '北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県',
    '茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県',
    '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県', '三重県',
    '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県',
    '鳥取県', '島根県', '岡山県', '広島県', '山口県', '徳島県', '香川県', '愛媛県', '高知県',
    '福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'
  ];

  /**
   * 複数選択タイプの項目をセレクター文字列で呼び出すためのオブジェクト
   */
  multiselectable: { [selector: string]: OptionItem[] } = {
    checkItemTargets: this.checkItemTargets,
    'buildingOptions.otherConditions': this.buildingOptions['otherConditions'],
    'buildingOptions.regionCharacteristic': this.buildingOptions['regionCharacteristic']
  };

  ratingLabels;

  constructor(
    private checkItemCategoryApi: CheckItemCategoryApi,
    private repairItemCategoryApi: RepairItemCategoryApi,
    private optionApi: OptionApi
  ) { }

  /**
   * 緊急度のラベルを取得する
   *
   * @param number 緊急度の値
   */
  getOptionLabel(property: string, value: number): string {
    let items: OptionItem[];

    if (property.startsWith('buildingOptions.')) {
      items = this.buildingOptions[property.replace('buildingOptions.', '')];
    } else {
      items = this[property];
    }

    if (!Array.isArray(items)) {
      return null;
    }

    const found = items.find(status => status.value === value);

    return found ? found.label : null;
  }

  /**
   * チェック項目カテゴリーを種別ごとに分けて取得
   */
  getCheckItemCategoryTargets() {
    const targets: CheckItemCategoryTarget[] = [
      { number: 1, label: '巡回', categories: [] },
      { number: 2, label: '建物診断(木造)', categories: [] },
      { number: 4, label: '建物診断(RC)', categories: [] },
      { number: 3, label: '部屋診断', categories: [] },
    ];

    return this.checkItemCategoryApi.find({
      order: 'order ASC',
      include: {
        relation: 'checkItems',
        scope: { order: 'order ASC' }
      }
    }).pipe(
      map(rows => {
        for (const cat of rows) {
          const target = targets.find(t => t.number === cat.target) || targets[0];
          target.categories.push(cat);
        }
        return targets;
      })
    );
  }

  /**
   * チェック項目カテゴリーを種別ごとに分けて取得
   */
  getRepairItemCategoryTargets() {
    const targets: RepairItemCategoryTarget[] = [
      { number: 1, label: 'Ⅰ 仮設', categories: [] },
      { number: 2, label: 'Ⅱ 建物', categories: [] },
      { number: 3, label: 'Ⅲ 設備', categories: [] },
      { number: 4, label: 'Ⅳ 外構他', categories: [] },
    ];

    return this.repairItemCategoryApi.find({
      order: 'order ASC',
      include: {
        relation: 'repairItems',
        scope: {
          order: 'order ASC',
          include: {
            relation: 'works',
            scope: { include: 'linkage' },
          }
        }
      }
    }).pipe(
      map(rows => {
        for (const cat of rows) {
          const target = targets.find(t => t.number === cat.target) || targets[0];
          target.categories.push(cat);
        }
        return targets;
      })
    );
  }

  /**
   * 作業ステータスと承認ステータスから作業状況を取得する
   * unfinished ... 作業予定が作成され、まだアップロードされていない状態
   * unapproved ... アップロード後、承認されていない状態
   * approved   ... アップロード後、承認された状態
   *
   * @param row 作業データ
   */
  getTaskCondition(row: { status: number; approvalStatus: number }): string {
    if (row.status === 1) {
      return 'unfinished';
    } else if (row.status === 4) {
      return 'sendbacked';
    } else if (row.approvalStatus === 1) {
      return 'unapproved';
    } else if (row.approvalStatus === 2) {
      return 'approved';
    } else {
      return 'unknown';
    }
  }

  /**
   * 年間巡回報告書の作業状況を取得する
   * unfinished ... 作業予定が作成され、まだアップロードされていない状態
   * unapproved ... アップロード後、承認されていない状態
   * approved   ... アップロード後、承認された状態
   *
   * @param row 作業データ
   */
  getAReportCondition(row: { status: number; approvalStatus: number }): string {
    if (row.status === 1) {
      return 'untouched';
    } else if (row.status === 2) {
      return 'touched';
    } else if (row.approvalStatus === 1) {
      return 'unapproved';
    } else if (row.approvalStatus === 2) {
      return 'approved';
    } else {
      return 'unknown';
    }
  }

  /**
   * 作業状況での並べ替え用番号
   */
  getTaskConditionOrder(row: { status: number; approvalStatus: number }): number {
    switch (this.getTaskCondition(row)) {
      case 'unfinished':
        return 2;
      case 'sendbacked':
        return 1;
      case 'unapproved':
        return 0;
      case 'approved':
        return 3;
      default:
        return 4;
    }
  }

  /**
   * 複数選択タイプのフォーム値を正常化する
   * サーバ側の値がアプリ側の選択肢と合致しない場合フォームの値セット時に
   * エラーとなるためアプリ側に合わせるように変更する
   *
   * ex)チェック項目作業対象の場合1,2,3をキーにしたオブジェクト値を期待するが
   * `{2: false, 3: true, 4: true}`のように余分なキーや足りないキーがあった場合、
   * 削除・追加して`{1: false, 2: false, 3: true}`に修正する
   * （ないものが追加された場合は値をfalseとする）
   *
   * @param selector 複数選択タイプの項目のセレクター（multiselectableで指定したもの）
   * @param obj 正常化するオブジェクト
   */
  sanitizeMultiselectObject(selector: string, obj: MultiSelectObject): MultiSelectObject {
    const sanitized = {};
    const target = this.multiselectable[selector];

    obj = obj || {};

    for (const item of target) {
      sanitized[item.value] = !!obj[item.value];
    }

    return sanitized;
  }

  getRatingLabels(): Observable<any> {
    if (this.ratingLabels) {
      return of(this.ratingLabels);
    } else {
      return this.optionApi.findOne({ where: { name: 'patrolRatingLabel' } }).pipe(
        map(res => this.ratingLabels = res.value)
      );
    }
  }
}
