/* eslint-disable */
import {
  Company,
  EquipmentType,
  Room,
  Staff
} from '../index';

declare var Object: any;
export interface EquipmentInterface {
  "manufacturer"?: string;
  "modelNumber"?: string;
  "dateManufacturedYear"?: number;
  "dateManufacturedMonth"?: number;
  "dateManufacturedDay"?: number;
  "id"?: number;
  "companyId"?: number;
  "typeId"?: number;
  "roomId"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId"?: number;
  company?: Company;
  type?: EquipmentType;
  room?: Room;
  updater?: Staff;
}

export class Equipment implements EquipmentInterface {
  "manufacturer": string;
  "modelNumber": string;
  "dateManufacturedYear": number;
  "dateManufacturedMonth": number;
  "dateManufacturedDay": number;
  "id": number;
  "companyId": number;
  "typeId": number;
  "roomId": number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId": number;
  company?: Company;
  type?: EquipmentType;
  room?: Room;
  updater?: Staff;
  constructor(data?: Partial<EquipmentInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Equipment`.
   */
  public static getModelName() {
    return "Equipment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Equipment for dynamic purposes.
  **/
  public static factory(data: EquipmentInterface): Equipment{
    return new Equipment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Equipment',
      plural: 'Equipment',
      path: 'Equipment',
      idName: 'id',
      properties: {
        "manufacturer": {
          name: 'manufacturer',
          type: 'string'
        },
        "modelNumber": {
          name: 'modelNumber',
          type: 'string'
        },
        "dateManufacturedYear": {
          name: 'dateManufacturedYear',
          type: 'number'
        },
        "dateManufacturedMonth": {
          name: 'dateManufacturedMonth',
          type: 'number'
        },
        "dateManufacturedDay": {
          name: 'dateManufacturedDay',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "typeId": {
          name: 'typeId',
          type: 'number'
        },
        "roomId": {
          name: 'roomId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        type: {
          name: 'type',
          type: 'EquipmentType',
          model: 'EquipmentType',
          relationType: 'belongsTo',
                  keyFrom: 'typeId',
          keyTo: 'id'
        },
        room: {
          name: 'room',
          type: 'Room',
          model: 'Room',
          relationType: 'belongsTo',
                  keyFrom: 'roomId',
          keyTo: 'id'
        },
        updater: {
          name: 'updater',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'updaterId',
          keyTo: 'id'
        },
      }
    }
  }
}
