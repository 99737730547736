import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { RoleService } from './services/role.service';
import { menuConfig } from './menu';
import { environment } from '../environments/environment';

import { Staff, Company } from '../loopback';

@Component({
  selector: 'bi-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = environment.title;
  titleLogo = environment.titleLogo;
  menu = menuConfig;
  isFullscreen = true;

  get currentUser(): Staff {
    return this.role.currentUser;
  }

  get currentCompany(): Company {
    return this.role.currentCompany;
  }

  constructor(
    private router: Router,
    private role: RoleService,
    private iconRegistry: MatIconRegistry
  ) {
    if (this.router.url !== 'login' && !this.currentUser) {
      this.router.navigateByUrl('/login');
    }

    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        setTimeout(() => {
          this.isFullscreen = (e.urlAfterRedirects === '/login');
        });
      }
    });

    this.iconRegistry.registerFontClassAlias('icomoon').setDefaultFontSetClass('icomoon');
  }

  logout() {
    this.role.logout().pipe(
      catchError((err, caught) => of(null))
    ).subscribe(
      result => {
        this.router.navigateByUrl('/login');
      }
    );
  }
}
