import { Pipe, PipeTransform } from '@angular/core';

import { OptionsService } from '../services/options.service';
import { Task } from '../typeAliases';
import { PatrolAnnualReport } from '../../loopback';

@Pipe({
  name: 'taskConditionName'
})
export class TaskConditionNamePipe implements PipeTransform {

  constructor(private opt: OptionsService) {}

  transform(record: Task|PatrolAnnualReport): string {
    if (record instanceof PatrolAnnualReport) {
      return this.opt.getAReportCondition(record);
    } else {
      return this.opt.getTaskCondition(record);
    }
  }

}
