import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

/**
 * 汎用ダイアログ
 * alert, confirm, promptに対応
 */
@Component({
  selector: 'bi-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericDialogComponent implements OnInit {
  /**
   * プロンプト用FormControl
   */
  promptText = new FormControl('');

  /**
   * dataのデフォルト値
   */
  defaults = {
    type: 'alert',
    title: '',
    message: [],
    selectOptions: [],
    promptRequired: false,
    promptValue: '',
    cancelButtonText: 'キャンセル',
    okButtonText: 'OK',
    okButtonColor: 'primary'
  };

  get okDisabled() {
    return this.data.promptRequired && !this.promptText.valid;
  }

  constructor(
    private ref: MatDialogRef<GenericDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.data = { ...this.defaults, ...this.data };

    if (!Array.isArray(this.data.message)) {
      this.data.message = [this.data.message];
    }

    if (this.data.promptRequired) {
      this.promptText.setValidators(Validators.required);
    }

    if (this.data.promptValue) {
      this.promptText.setValue(this.data.promptValue);
    }
  }

  /**
   * OKボタンクリックハンドラ
   * promptの場合は入力値、それ以外はtrueを渡す
   */
  onOkClick() {
    const value = this.data.type.startsWith('prompt') ? this.promptText.value : true;
    this.ref.close(value);
  }

}
