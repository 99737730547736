import { Component, OnInit, Inject, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ConditionTemplate, ConditionTemplateApi } from '../../../loopback';

@Component({
  selector: 'bi-condition-template-selector',
  templateUrl: './condition-template-selector.component.html',
  styleUrls: ['./condition-template-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConditionTemplateSelectorComponent implements OnInit {

  title: string;
  templates: ConditionTemplate[];

  constructor(
    private cdRef: ChangeDetectorRef,
    private ref: MatDialogRef<ConditionTemplateSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private conditionTempApi: ConditionTemplateApi
  ) {}

  ngOnInit() {
    this.conditionTempApi.find({where: {checkItemId: this.data.checkItemId}, order: 'order ASC', include: 'photo'}).subscribe(
      res => {
        this.templates = res;
        this.cdRef.markForCheck();
      }
    );
  }

  onItemClick(template: ConditionTemplate) {
    this.ref.close(template);
  }

}
