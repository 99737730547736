import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { RepairPlanCreatorComponent } from '../repair-plan-creator/repair-plan-creator.component';

@Component({
  selector: 'bi-task-report-downloader',
  templateUrl: './task-report-downloader.component.html',
  styleUrls: ['./task-report-downloader.component.scss']
})
export class TaskReportDownloaderComponent implements OnInit {

  form: FormGroup<{ reportDate: FormControl<moment.Moment>; execDate: FormControl<moment.Moment> }>;

  get okDisabled() {
    return !this.form.valid;
  }

  constructor(
    private formBuilder: FormBuilder,
    private ref: MatDialogRef<RepairPlanCreatorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      reportDate: [moment(), Validators.required],
      execDate: [null],
    });

    if (!Array.isArray(this.data.message)) {
      this.data.message = [this.data.message];
    }
  }

  /**
   * OKボタンクリックハンドラ
   */
  onOkClick() {
    this.ref.close(this.form.value);
  }

}
