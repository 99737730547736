import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoleGroup } from '../../../loopback';


@Component({
  selector: 'bi-role-group-settings',
  templateUrl: './role-group-settings.component.html',
  styleUrls: ['./role-group-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoleGroupSettingsComponent implements OnInit {

  /**
   * 編集用ReactiveForm
   */
  form: FormGroup<{
    id: FormControl<number>;
    name: FormControl<string>;
    roleBuildingRead: FormControl<boolean>;
    roleBuildingEdit: FormControl<boolean>;
    roleStaff: FormControl<boolean>;
    roleImport: FormControl<boolean>;
    roleSettings: FormControl<boolean>;
    rolePtrRead: FormControl<boolean>;
    rolePtrEdit: FormControl<boolean>;
    rolePtrApprove: FormControl<boolean>;
    rolePtrAReportRead: FormControl<boolean>;
    rolePtrAReportEdit: FormControl<boolean>;
    rolePtrAReportCreate: FormControl<boolean>;
    rolePtrAReportApprove: FormControl<boolean>;
    roleIspRead: FormControl<boolean>;
    roleIspEdit: FormControl<boolean>;
    roleIspApprove: FormControl<boolean>;
    roleIspReportRead: FormControl<boolean>;
    roleIspReportEdit: FormControl<boolean>;
    roleIspReportCreate: FormControl<boolean>;
    roleIspReportApprove: FormControl<boolean>;
    roleTablet: FormControl<boolean>;
  }>;

  /**
   * 権限の依存性設定
   * キーは依存される側の権限を指す
   * 値はその権限に依存している権限の配列
   *
   * ex)roleBuildingRead(建物閲覧)はroleBuildingEdit(建物編集)に必要とされている
   */
  private dependerMap: {[role: string]: string[]} = {
    roleBuildingRead: ['roleBuildingEdit'],
    rolePtrRead: ['rolePtrEdit', 'rolePtrApprove'],
    rolePtrEdit: ['rolePtrApprove'],
    rolePtrAReportRead: ['rolePtrAReportEdit', 'rolePtrAReportCreate', 'rolePtrAReportApprove'],
    rolePtrAReportEdit: ['rolePtrAReportCreate', 'rolePtrAReportApprove'],
    roleIspRead: ['roleIspEdit', 'roleIspApprove'],
    roleIspEdit: ['roleIspApprove'],
    roleIspReportRead: ['roleIspReportEdit', 'roleIspReportCreate', 'roleIspReportApprove'],
    roleIspReportEdit: ['roleIspReportCreate', 'roleIspReportApprove']
  };

  private subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private ref: MatDialogRef<RoleGroupSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {title: string; record: RoleGroup}
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      id: [null],
      name: ['', Validators.required],
      roleBuildingRead: [false],
      roleBuildingEdit: [false],
      roleStaff: [false],
      roleImport: [false],
      roleSettings: [false],
      rolePtrRead: [false],
      rolePtrEdit: [false],
      rolePtrApprove: [false],
      rolePtrAReportRead: [false],
      rolePtrAReportEdit: [false],
      rolePtrAReportCreate: [false],
      rolePtrAReportApprove: [false],
      roleIspRead: [false],
      roleIspEdit: [false],
      roleIspApprove: [false],
      roleIspReportRead: [false],
      roleIspReportEdit: [false],
      roleIspReportCreate: [false],
      roleIspReportApprove: [false],
      roleTablet: [false]
    });

    Object.keys(this.dependerMap).forEach(role => {
      const dependee = this.form.get(role);
      const dependerValueChanges = this.dependerMap[role].map(dep => {
        const depControl = this.form.get(dep);
        return depControl.valueChanges;
      });

      // この権限に依存している権限のチェック状態を確認して
      // いずれかでもチェックされていればこの権限のチェックを外せないようにする
      const subscription = combineLatest(
        dependerValueChanges,
      ).pipe(
        map(values => values.some(val => val))
      ).subscribe(
        checked => {
          if (checked) {
            dependee.setValue(true);
            dependee.disable();
          } else {
            dependee.enable();
          }
        }
      );

      this.subscriptions.push(subscription);
    });

    this.setData(this.data.record);
  }

  /**
   * OKボタンのクリックハンドラ
   */
  onOkClick() {
    // disableになっているコントロールの値が取得できないので全てenableにする
    // enableしたとき依存性の処理がされないようにobservableの購読を解除する
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.form.enable();
    this.ref.close(this.form.value);
  }

  /**
   * ReactiveFormに値をセットする
   *
   * @param data 権限グループのデータ
   */
  private setData(data: RoleGroup) {
    Object.keys(data).forEach(key => {
      const ctl = this.form.get(key);
      if (ctl) {
        ctl.setValue(data[key]);
      }
    });
  }
}
