import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'bi-inspection-csv-downloader',
  templateUrl: './inspection-csv-downloader.component.html',
  styleUrls: ['./inspection-csv-downloader.component.scss']
})
export class InspectionCsvDownloaderComponent implements OnInit {

  formatControl = new FormControl(0);
  defaultFormat: number;

  constructor(
    private ref: MatDialogRef<InspectionCsvDownloaderComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { defaultFormat: number}
  ) { }

  ngOnInit(): void {
    this.defaultFormat = this.data?.defaultFormat;

    if (this.defaultFormat != null) {
      this.formatControl.setValue(this.defaultFormat);
    }
  }

  onOkClick() {
    this.ref.close(this.formatControl.value);
  }
}
