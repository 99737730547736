import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalEventService {

  private subjects: {[name: string]: Subject<any> } = {};

  constructor() {}

  on(event: string): Observable<any> {
    if (this.subjects[event] === undefined) {
      this.subjects[event] = new Subject<any>();
    }

    return this.subjects[event].asObservable();
  }

  trigger(event: string, value: any) {
    const subject = this.subjects[event];

    if (!subject) {
      return;
    }

    subject.next(value);
  }

}
