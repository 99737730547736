/* eslint-disable */
import {
  Company,
  CheckItem,
  Staff
} from '../index';

declare var Object: any;
export interface RepairWorkInterface {
  "part": string;
  "order"?: number;
  "type"?: string;
  "unitCost"?: number;
  "multiplierType"?: number;
  "cycle"?: number;
  "id"?: number;
  "companyId"?: number;
  "_linkage"?: any;
  "relatedCheckItemIds"?: Array<any>;
  "repairItemId"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId"?: number;
  company?: Company;
  linkage?: RepairWork;
  relatedCheckItems?: CheckItem[];
  updater?: Staff;
}

export class RepairWork implements RepairWorkInterface {
  "part": string;
  "order": number;
  "type": string;
  "unitCost": number;
  "multiplierType": number;
  "cycle": number;
  "id": number;
  "companyId": number;
  "_linkage": any;
  "relatedCheckItemIds": Array<any>;
  "repairItemId": number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId": number;
  company?: Company;
  linkage?: RepairWork;
  relatedCheckItems?: CheckItem[];
  updater?: Staff;
  constructor(data?: Partial<RepairWorkInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `RepairWork`.
   */
  public static getModelName() {
    return "RepairWork";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of RepairWork for dynamic purposes.
  **/
  public static factory(data: RepairWorkInterface): RepairWork{
    return new RepairWork(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'RepairWork',
      plural: 'RepairWorks',
      path: 'RepairWorks',
      idName: 'id',
      properties: {
        "part": {
          name: 'part',
          type: 'string'
        },
        "order": {
          name: 'order',
          type: 'number'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "unitCost": {
          name: 'unitCost',
          type: 'number'
        },
        "multiplierType": {
          name: 'multiplierType',
          type: 'number'
        },
        "cycle": {
          name: 'cycle',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "_linkage": {
          name: '_linkage',
          type: 'any'
        },
        "relatedCheckItemIds": {
          name: 'relatedCheckItemIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "repairItemId": {
          name: 'repairItemId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        linkage: {
          name: 'linkage',
          type: 'RepairWork[]',
          model: 'RepairWork',
          relationType: 'embedsOne',
                  keyFrom: '_linkage',
          keyTo: 'id'
        },
        relatedCheckItems: {
          name: 'relatedCheckItems',
          type: 'CheckItem[]',
          model: 'CheckItem',
          relationType: 'referencesMany',
                  keyFrom: 'relatedCheckItemIds',
          keyTo: 'id'
        },
        updater: {
          name: 'updater',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'updaterId',
          keyTo: 'id'
        },
      }
    }
  }
}
