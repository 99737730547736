import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';

import { RoleService } from '../../services/role.service';
import { PageBase } from '../../classes/page-base';
import { environment } from '../../../environments/environment';

/**
 * ログインページ(url: /login)
 */
@Component({
  selector: 'bi-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends PageBase implements OnInit {

  title = 'ログイン';
  appTitle = environment.title;
  appTitleLogo = environment.titleLogo;

  /**
   * ログインフォームのReactiveForm
   */
  form: FormGroup<{ usernameOrEmail: FormControl<string>; password: FormControl<string> }>;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    protected titleService: Title,
    private snackBar: MatSnackBar,
    private role: RoleService
  ) {
    super();
  }

  ngOnInit() {
    this.init();

    this.form = this.formBuilder.group({
      usernameOrEmail: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  /**
   * フォーム送信イベントのハンドラ
   *
   * @param e
   */
  onSubmit(e: Event): void {
    const value = this.form.value;
    const credentials: {username?: string; email?: string; password: string} = {password: value.password};

    if (value.usernameOrEmail.indexOf('@') !== -1) {
      credentials.email = value.usernameOrEmail;
    } else {
      credentials.username = value.usernameOrEmail;
    }

    this.role.login(credentials).subscribe(
      result => {
        this.router.navigateByUrl('/dashboard');
      },
      err => {
        console.log(err);
        this.snackBar.open('ログインに失敗しました', 'OK', {duration: 3000});
      }
    );
  }

}
