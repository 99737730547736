import { Injectable, ComponentRef } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, TemplatePortal } from '@angular/cdk/portal';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {

  ref: OverlayRef;

  constructor(
    private overlay: Overlay
  ) {
    this.ref = this.overlay.create({
      width: '100%',
      height: '100%',
      hasBackdrop: true
    });
  }

  showComponent<T = any>(component: ComponentType<T>): ComponentRef<T> {
    const portal = new ComponentPortal(component);
    return this.ref.attach(portal);
  }

  showTemplate(template: TemplatePortal) {
    return this.ref.attach(template);
  }

  hide() {
    this.ref.detach();
  }
}
