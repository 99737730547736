/* eslint-disable */
/**
* @module SDKModule
* @author Jonathan Casarrubias <t:@johncasarrubias> <gh:jonathan-casarrubias>
* @license MIT 2016 Jonathan Casarrubias
* @version 2.1.0
* @description
* The SDKModule is a generated Software Development Kit automatically built by
* the LoopBack SDK Builder open source module.
*
* The SDKModule provides Angular 2 >= RC.5 support, which means that NgModules
* can import this Software Development Kit as follows:
*
*
* APP Route Module Context
* ============================================================================
* import { NgModule }       from '@angular/core';
* import { BrowserModule }  from '@angular/platform-browser';
* // App Root
* import { AppComponent }   from './app.component';
* // Feature Modules
* import { SDK[Browser|Node|Native]Module } from './shared/sdk/sdk.module';
* // Import Routing
* import { routing }        from './app.routing';
* @NgModule({
*  imports: [
*    BrowserModule,
*    routing,
*    SDK[Browser|Node|Native]Module.forRoot()
*  ],
*  declarations: [ AppComponent ],
*  bootstrap:    [ AppComponent ]
* })
* export class AppModule { }
*
**/
import { ErrorHandler } from './services/core/error.service';
import { LoopBackAuth } from './services/core/auth.service';
import { LoggerService } from './services/custom/logger.service';
import { SDKModels } from './services/custom/SDKModels';
import { InternalStorage, SDKStorage } from './storage/storage.swaps';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CookieBrowser } from './storage/cookie.browser';
import { StorageBrowser } from './storage/storage.browser';
import { MediaApi } from './services/custom/Media';
import { CompanyApi } from './services/custom/Company';
import { BranchApi } from './services/custom/Branch';
import { StaffApi } from './services/custom/Staff';
import { RoleGroupApi } from './services/custom/RoleGroup';
import { BuildingApi } from './services/custom/Building';
import { CheckItemApi } from './services/custom/CheckItem';
import { CheckItemCategoryApi } from './services/custom/CheckItemCategory';
import { ImportHistoryApi } from './services/custom/ImportHistory';
import { PatrolApi } from './services/custom/Patrol';
import { PatrolDetailApi } from './services/custom/PatrolDetail';
import { FileInfoApi } from './services/custom/FileInfo';
import { OptionApi } from './services/custom/Option';
import { RoomApi } from './services/custom/Room';
import { EquipmentTypeApi } from './services/custom/EquipmentType';
import { EquipmentApi } from './services/custom/Equipment';
import { ConditionTemplateApi } from './services/custom/ConditionTemplate';
import { InspectionApi } from './services/custom/Inspection';
import { InspectionDetailApi } from './services/custom/InspectionDetail';
import { PatrolAnnualReportApi } from './services/custom/PatrolAnnualReport';
import { RepairItemApi } from './services/custom/RepairItem';
import { RepairItemCategoryApi } from './services/custom/RepairItemCategory';
import { RepairWorkApi } from './services/custom/RepairWork';
import { RepairPlanApi } from './services/custom/RepairPlan';
import { RepairPlanWorkApi } from './services/custom/RepairPlanWork';
/**
* @module SDKBrowserModule
* @description
* This module should be imported when building a Web Application in the following scenarios:
*
*  1.- Regular web application
*  2.- Angular universal application (Browser Portion)
*  3.- Progressive applications (Angular Mobile, Ionic, WebViews, etc)
**/
@NgModule({
  imports:      [ CommonModule, HttpClientModule ],
  declarations: [ ],
  exports:      [ ],
  providers:    [
    ErrorHandler
  ]
})
export class SDKBrowserModule {
  static forRoot(internalStorageProvider: any = {
    provide: InternalStorage,
    useClass: CookieBrowser
  }): ModuleWithProviders<SDKBrowserModule> {
    return {
      ngModule  : SDKBrowserModule,
      providers : [
        LoopBackAuth,
        LoggerService,
        SDKModels,
        MediaApi,
        CompanyApi,
        BranchApi,
        StaffApi,
        RoleGroupApi,
        BuildingApi,
        CheckItemApi,
        CheckItemCategoryApi,
        ImportHistoryApi,
        PatrolApi,
        PatrolDetailApi,
        FileInfoApi,
        OptionApi,
        RoomApi,
        EquipmentTypeApi,
        EquipmentApi,
        ConditionTemplateApi,
        InspectionApi,
        InspectionDetailApi,
        PatrolAnnualReportApi,
        RepairItemApi,
        RepairItemCategoryApi,
        RepairWorkApi,
        RepairPlanApi,
        RepairPlanWorkApi,
        internalStorageProvider,
        { provide: SDKStorage, useClass: StorageBrowser }
      ]
    };
  }
}
/**
* Have Fun!!!
* - Jon
**/
export * from './models/index';
export * from './services/index';
export * from './lb.config';
export * from './storage/storage.swaps';
export { CookieBrowser } from './storage/cookie.browser';
export { StorageBrowser } from './storage/storage.browser';

