import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { GlobalMenuItem } from '../../interfaces/global-menu-item';
import { GlobalStateService } from '../../services/global-state.service';
import { RoleService } from '../../services/role.service';

/**
 * メニューコンポーネント
 */
@Component({
  selector: 'bi-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {
  /**
   * メニュー設定
   */
  @Input() menuConfig: GlobalMenuItem[] = [];

  /**
   * 表示用のメニューデータ
   */
  menuItems: GlobalMenuItem[];

  /**
   * 現在選択されているメニュー項目
   */
  get currentItem(): GlobalMenuItem {
 return this.currentMenuItem;
}
  private currentMenuItem: GlobalMenuItem;

  /**
   * subscriptions
   */
  private subscriptions: Subscription[];

  constructor(
    private router: Router,
    private role: RoleService,
    private state: GlobalStateService
  ) {
    this.subscriptions = [
      // ナビゲーション終了後に各メニュー項目の状態を更新する
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (this.menuItems) {
            this.updateStateAndNotify();
          }
        }
      }),
      // 権限が変更されたらメニューを作り直す
      this.role.roleChanged.subscribe(
        changed => {
          this.menuItems = this.createMenu(this.menuConfig);
        }
      )
    ];
  }

  ngOnInit() {
    this.menuItems = this.createMenu(this.menuConfig);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  /**
   * 選択・開閉状態などを更新して現在の選択メニューを通知する
   */
  private updateStateAndNotify() {
    if (this.menuItems) {
      this.updateItemsState(this.menuItems);
      this.state.set('menu.activeLink', this.currentItem);
    }
  }

  /**
   * 設定からメニューを作成
   *
   * @param config メニュー設定の配列
   * @param parent 親メニュー項目
   */
  private createMenu(config: GlobalMenuItem[], parent?: GlobalMenuItem): GlobalMenuItem[] {
    const items = [];

    config.forEach(itemConfig => {
      // ログインユーザに指定された権限がない場合は飛ばす
      if (itemConfig.caps && !this.role.canEvery(itemConfig.caps)) {
        return;
      }

      const item = Object.assign({}, itemConfig);

      item.paths = parent && parent.paths ? parent.paths.slice(0) : [];
      item.path.split('/').forEach(part => {
        if (part !== '') {
          item.paths.push(part);
        }
      });

      if (item.children && item.children.length > 0) {
        item.children = this.createMenu(item.children, item);
      }

      // 表示できる子メニューがなかったら親ごと飛ばす
      if (item.children && item.children.length === 0) {
        return;
      }

      items.push(item);
    });

    return items;
  }

  /**
   * 選択・開閉の状態を更新する
   *
   * @param menuItems メニュー項目の配列
   * @param parent 親メニュー
   */
  private updateItemsState(menuItems: GlobalMenuItem[], parent?: GlobalMenuItem): GlobalMenuItem[] {
    return menuItems.map(item => {
      this.checkSelected(item);

      if ((item.selected || item.expanded) && parent) {
        parent.expanded = true;
      }

      if (item.selected) {
        this.currentMenuItem = item;
      }

      if (item.children && item.children.length > 0) {
        item.children = this.updateItemsState(item.children, item);
      }

      return item;
    });
  }

  /**
   * 現在のURLを調べて項目を選択状態として良いかどうか判別する
   *
   * @param item メニュー項目
   */
  private checkSelected(item: GlobalMenuItem): GlobalMenuItem {
    const urlFragments = this.router.url.split('/').slice(1);

    item.selected = item.paths.length === urlFragments.length && urlFragments.every((fragment, index) => item.paths[index] === fragment);

    return item;
  }

}
