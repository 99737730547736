import { TemplatePortalDirective } from '@angular/cdk/portal';
import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CheckItem, FileInfo, FileInfoApi, InspectionDetailApi, PatrolDetail, PatrolDetailApi } from 'loopback';
import { catchError, mergeMap } from 'rxjs/operators';
import { ConditionTemplateSelectorComponent } from '../../dialogs/condition-template-selector/condition-template-selector.component';
import { GenericDialogComponent } from '../../dialogs/generic-dialog/generic-dialog.component';
import { FileInfoService } from '../../services/file-info.service';
import { ImageResizerService } from '../../services/image-resizer.service';
import { OptionsService } from '../../services/options.service';
import { OverlayService } from '../../services/overlay.service';
import { TaskDetail } from '../../typeAliases';
import { ImageEditorComponent } from '../image-editor/image-editor.component';

/**
 * 巡回/診断詳細の編集機能
 */
@Component({
  selector: 'bi-task-detail-editor',
  templateUrl: './task-detail-editor.component.html',
  styleUrls: ['./task-detail-editor.component.scss']
})
export class TaskDetailEditorComponent implements OnInit, OnChanges {
  /**
   * チェック項目
   */
  @Input() checkItem: CheckItem;

  /**
   * 巡回/診断詳細レコード
   */
  @Input() detail: TaskDetail;

  /**
   * 編集が可能かどうか
   */
  @Input() canEdit: boolean;

  /**
   * 画像遅延読み込みの判定用スクロール要素への参照
   */
  @Input() lazyloadScroller: ElementRef;

  /**
   * 作業タイプ(Patrol => 巡回, Inspection => 診断)
   */
  taskType: 'Patrol' | 'Inspection';

  /**
   * フォーム
   */
  form: FormGroup<{
    id: FormControl<number>;
    checkItemId: FormControl<number>;
    availability: FormControl<string>;
    photoIds: FormControl<number[]>;
    reportPhotoIds: FormArray<FormControl<number>>;
    patrolId?: FormControl<number>;
    rating?: FormControl<number>;
    issue?: FormControl<string>;
    proposal?: FormControl<string>;
    inspectionId?: FormControl<number>;
    conditionName?: FormControl<string>;
    deterioration?: FormControl<number>;
    urgency?: FormControl<number>;
    reason?: FormControl<string>;
    measure?: FormControl<string>;
  }>;

  /**
   * 写真
   */
  photos: FileInfo[];

  /**
   * 報告書写真
   */
  reportPhotos: FileInfo[];

  /**
   * 巡回/診断レコードのID
   */
  parentId: string | number;

  /**
   * 巡回の評価コメント
   */
  ratingLabels;

  /**
   * 詳細APIへの参照
   */
  detailApi: PatrolDetailApi | InspectionDetailApi;

  /**
   * 拡大中の写真
   */
  enlargedPhoto: FileInfo;

  /**
   * 写真拡大表示用テンプレート
   */
  @ViewChild('photoOverlayTmpl', { static: true }) photoOverlayTmpl: TemplatePortalDirective;

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private fileInfoApi: FileInfoApi,
    private pdetailApi: PatrolDetailApi,
    private idetailApi: InspectionDetailApi,
    private fileInfoService: FileInfoService,
    private imageResizer: ImageResizerService,
    private overlay: OverlayService,
    public opt: OptionsService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['detail']) {
      const detail = changes['detail'].currentValue as TaskDetail;

      if (detail) {
        this.photos = detail.photos || [];
        this.reportPhotos = (detail.reportPhotoIds || detail.photoIds || []).map(fileId => this.photos.find(p => p.id === fileId));

        const availability: string = detail['availability'] || (detail.isNotExist ? 'notExist' : detail.isUnable ? 'unable' : 'normal');

        this.form = this.formBuilder.group({
          id: [detail.id],
          checkItemId: [detail.checkItemId],
          availability: [availability],
          photoIds: [detail.photoIds],
          reportPhotoIds: this.formBuilder.array((detail.reportPhotoIds || detail.photoIds || []) as number[])
        });

        if (detail instanceof PatrolDetail) {
          this.taskType = 'Patrol';
          this.detailApi = this.pdetailApi;
          this.parentId = detail.patrolId;

          this.opt.getRatingLabels().subscribe(
            labels => this.ratingLabels = labels
          );

          if (this.checkItem.issueOptions.concat(['問題なし', '-1']).indexOf(detail.issue) === -1) {
            this.checkItem.issueOptions.push(detail.issue);
          }

          if (this.checkItem.proposalOptions.concat(['-', '-1']).indexOf(detail.proposal) === -1) {
            this.checkItem.proposalOptions.push(detail.proposal);
          }

          this.form.addControl('patrolId', this.formBuilder.control(detail.patrolId));
          this.form.addControl('rating', this.formBuilder.control(detail.rating));
          this.form.addControl('issue', this.formBuilder.control(detail.issue));
          this.form.addControl('proposal', this.formBuilder.control(detail.proposal));

          this.toggleProposalSelectState();
        } else {
          this.taskType = 'Inspection';
          this.detailApi = this.idetailApi;
          this.parentId = detail.inspectionId;

          this.form.addControl('inspectionId', this.formBuilder.control(detail.inspectionId));
          this.form.addControl('conditionName', this.formBuilder.control(detail.conditionName));
          this.form.addControl('deterioration', this.formBuilder.control(detail.deterioration));
          this.form.addControl('urgency', this.formBuilder.control(detail.urgency));
          this.form.addControl('reason', this.formBuilder.control(detail.reason));
          this.form.addControl('measure', this.formBuilder.control(detail.measure));
        }
      }
    }

    if (changes['canEdit']) {
      if (!changes['canEdit'].currentValue && this.form) {
        this.form.disable();
      }
    }
  }

  ngOnInit() {
    if (this.form) {
      this.form.valueChanges.subscribe(
        values => {
          values['__dirty'] = true;
          Object.assign(this.detail, values);
        }
      );
    }
  }

  onOpenIssueSelect(open: boolean) {
    if (open) {
      this.checkItem['__currentIssue'] = this.form.get('issue').value;
    }
  }

  onIssueChange(e: MatSelectChange) {
    if (e.value === -1) {
      this.dialog.open(GenericDialogComponent, {
        data: {
          type: 'prompt',
          message: '指摘・課題点を入力してください'
        }
      }).afterClosed().subscribe(
        res => {
          if (!res) {
            res = this.checkItem['__currentIssue'];
            delete this.checkItem['__currentIssue'];
          }

          if (this.checkItem.issueOptions.indexOf(res) === -1 && res !== '問題なし') {
            this.checkItem.issueOptions.push(res);
          }

          this.form.get('issue').setValue(res);
          this.toggleProposalSelectState();
        }
      );
    } else {
      this.toggleProposalSelectState();
      delete this.checkItem['__currentIssue'];
    }
  }

  onOpenProposalSelect(open: boolean) {
    if (open) {
      this.checkItem['__currentProposal'] = this.form.get('proposal').value;
    }
  }

  onProposalChange(e: MatSelectChange) {
    if (e.value === -1) {
      this.dialog.open(GenericDialogComponent, {
        data: {
          type: 'prompt',
          message: '提案・改善点を入力してください'
        }
      }).afterClosed().subscribe(
        res => {
          if (!res) {
            res = this.checkItem['__currentProposal'];
            delete this.checkItem['__currentProposal'];
          }

          if (this.checkItem.proposalOptions.indexOf(res) === -1 && res !== '-') {
            this.checkItem.proposalOptions.push(res);
          }

          this.form.get('proposal').setValue(res);
        }
      );
    }
  }

  toggleProposalSelectState() {
    const disabled = this.form.get('issue').value === '問題なし';
    const proposal = this.form.get('proposal');

    if (disabled) {
      proposal.setValue('-');
    }
  }

  openConditionTemplateSelector() {
    this.dialog.open(ConditionTemplateSelectorComponent, {
      data: {
        checkItemId: this.checkItem.id
      },
      width: '640px'
    }).afterClosed().subscribe(
      result => {
        if (!result) {
          return;
        }

        this.form.get('conditionName').setValue(result.name);
        this.form.get('deterioration').setValue(result.deterioration);
        this.form.get('urgency').setValue(result.urgency);
        this.form.get('reason').setValue(result.reason);
        this.form.get('measure').setValue(result.measure);
        this.form.markAsDirty();
        this.form.markAsTouched();
      }
    );
  }

  async deletePhoto(index: number) {
    const ok = await this.dialog.open(GenericDialogComponent, {
      data: {
        type: 'confirm',
        title: '写真の削除',
        message: 'この写真を削除します。この操作は取り消すことができません。続行してもよろしいですか?'
      }
    }).afterClosed().toPromise();

    if (!ok) {
      return;
    }

    const photoIds = this.form.get('photoIds');
    const photoId = photoIds.value[index];
    const reportPhotoIds = this.form.controls.reportPhotoIds;
    const reportPhotoIndex = reportPhotoIds.value.indexOf(photoId);
    const isWithDrawing = this.photos[index].name.endsWith('-D');

    if (isWithDrawing) {
      const originalName = this.photos[index].name.replace('-D', '');
      this.fileInfoApi.findOne({ where: { name: originalName } }).pipe(
        mergeMap(res => this.fileInfoApi.deleteById(res.id))
      ).subscribe();
    }

    this.detailApi.destroyByIdPhotos(this.detail.id, photoId).subscribe(
      () => {
        this.photos.splice(index, 1);
        photoIds.setValue(this.photos.map(p => p.id));
        if (reportPhotoIndex !== -1) {
          this.reportPhotos.splice(reportPhotoIndex, 1);
          reportPhotoIds.removeAt(reportPhotoIndex);
          this.detailApi.patchAttributes(this.detail.id, { reportPhotoIds: reportPhotoIds.value }).subscribe(null, null);
        }
      }
    );
  }

  async onPhotoFileSelect(file: File) {
    const formData = new FormData();
    const blob = await this.imageResizer.resizeToContainInSquare(file, 1536);

    formData.append('file', blob, file.name);

    this.fileInfoApi.upload(formData, `${this.taskType.toLowerCase()}-${this.parentId}`).pipe(
      mergeMap(res => {
        const photoIds = this.form.get('photoIds');
        const added = photoIds.value.concat(res.result.id);
        photoIds.setValue(added);
        this.photos.push(res.result);
        return this.detailApi.patchAttributes(this.detail.id, { photoIds: added });
      })
    ).subscribe();
  }

  addReportPhoto(photo: FileInfo) {
    const formArray = this.form.controls.reportPhotoIds;

    if (!this.reportPhotos.some(p => p.id === photo.id)) {
      this.reportPhotos.push(photo);
      formArray.push(this.formBuilder.control(photo.id));
      formArray.markAsTouched();
      formArray.markAsDirty();
    }
  }

  removeReportPhoto(index: number) {
    const formArray = this.form.controls.reportPhotoIds;

    this.reportPhotos.splice(index, 1);
    formArray.removeAt(index);
    formArray.markAsTouched();
    formArray.markAsDirty();
  }

  handleSort(e) {
    const formArray = this.form.controls.reportPhotoIds;

    formArray.setValue(this.reportPhotos.map(p => p.id));
    formArray.markAsTouched();
    formArray.markAsDirty();
  }

  showPhotoEditor(index: number, photo: FileInfo) {
    const cmpRef = this.overlay.showComponent(ImageEditorComponent);
    const editor = cmpRef.instance;

    editor.image = this.fileInfoService.getUrl(photo);
    editor.editorClose.subscribe(() => {
      this.overlay.hide();
    });
    editor.editorResult.subscribe(blob => {
      this.savePhotoWithDrawing(index, photo, blob);
    });
  }

  savePhotoWithDrawing(index: number, original: FileInfo, blob: Blob) {
    const formData = new FormData();

    formData.append('file', blob, `${original.name}-D.jpg`);

    this.fileInfoApi.upload(formData, `${this.taskType.toLowerCase()}-${this.parentId}`).pipe(
      mergeMap(res => {
        const photoIds = this.form.get('photoIds');
        const reportPhotoIds = this.form.controls.reportPhotoIds;
        const replaced = photoIds.value.map((id, i) => i === index ? res.result.id : id);

        photoIds.setValue(replaced);
        this.photos.splice(index, 1, res.result);

        const reportPhotoIndex = reportPhotoIds.value.indexOf(original.id);
        if (reportPhotoIndex !== -1) {
          reportPhotoIds.at(reportPhotoIndex).setValue(res.result.id);
          this.reportPhotos.splice(reportPhotoIndex, 1, res.result);
        }

        return this.detailApi.patchAttributes(this.detail.id, { photoIds: replaced, reportPhotoIds: reportPhotoIds.value });
      })
    ).subscribe();
  }

  revertDrawing(index: number, photoWithDrawing: FileInfo) {
    const originalName = photoWithDrawing.name.replace('-D', '');

    this.fileInfoApi.findOne({ where: { name: originalName } }).pipe(
      mergeMap(res => {
        const photoIds = this.form.get('photoIds');
        const reportPhotoIds = this.form.controls.reportPhotoIds;
        const replaced = photoIds.value.map((id, i) => i === index ? res.id : id);

        photoIds.setValue(replaced);
        this.photos.splice(index, 1, res);

        const reportPhotoIndex = reportPhotoIds.value.indexOf(photoWithDrawing.id);
        if (reportPhotoIndex !== -1) {
          reportPhotoIds.at(reportPhotoIndex).setValue(res.id);
          this.reportPhotos.splice(reportPhotoIndex, 1, res);
        }

        return this.detailApi.patchAttributes(this.detail.id, { photoIds: replaced, reportPhotoIds: reportPhotoIds.value });
      }),
      mergeMap(() => this.fileInfoApi.deleteById(photoWithDrawing.id)),
      catchError((err, caught) => this.dialog.open(GenericDialogComponent, {
          data: {
            type: 'alert',
            title: '写真を元に戻せません',
            message: 'タブレット側で書き込まれた写真はもとに戻すことができません'
          }
        }).afterClosed())
    ).subscribe();
  }

  showEnlargedPhoto(photo: FileInfo) {
    this.enlargedPhoto = photo;
    this.overlay.showTemplate(this.photoOverlayTmpl);
    this.snackBar.open('画面をクリックすると閉じます', undefined, { duration: 3000, verticalPosition: 'top', horizontalPosition: 'left' });
  }

  hideEnlargedPhoto() {
    this.overlay.hide();
  }

}
