import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'bi-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent {

  @Input() message = '';
  @Input() listItems: string[] = [];

  @HostBinding('style.display') get display() {
    return this.listItems.length ? 'block' : 'none';
  }

  constructor() { }

}
