/* eslint-disable */
import {
  Company,
  Inspection,
  CheckItem,
  FileInfo,
  Staff
} from '../index';

declare var Object: any;
export interface InspectionDetailInterface {
  "isNotExist"?: boolean;
  "isUnable"?: boolean;
  "conditionName"?: string;
  "deterioration"?: number;
  "urgency"?: number;
  "reason"?: string;
  "measure"?: string;
  "reportPhotoIds"?: Array<any>;
  "id"?: number;
  "companyId"?: number;
  "inspectionId"?: number;
  "checkItemId"?: number;
  "photoIds"?: Array<any>;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId"?: number;
  company?: Company;
  inspection?: Inspection;
  checkItem?: CheckItem;
  photos?: FileInfo[];
  updater?: Staff;
}

export class InspectionDetail implements InspectionDetailInterface {
  "isNotExist": boolean;
  "isUnable": boolean;
  "conditionName": string;
  "deterioration": number;
  "urgency": number;
  "reason": string;
  "measure": string;
  "reportPhotoIds": Array<any>;
  "id": number;
  "companyId": number;
  "inspectionId": number;
  "checkItemId": number;
  "photoIds": Array<any>;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId": number;
  company?: Company;
  inspection?: Inspection;
  checkItem?: CheckItem;
  photos?: FileInfo[];
  updater?: Staff;
  constructor(data?: Partial<InspectionDetailInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `InspectionDetail`.
   */
  public static getModelName() {
    return "InspectionDetail";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of InspectionDetail for dynamic purposes.
  **/
  public static factory(data: InspectionDetailInterface): InspectionDetail{
    return new InspectionDetail(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'InspectionDetail',
      plural: 'InspectionDetails',
      path: 'InspectionDetails',
      idName: 'id',
      properties: {
        "isNotExist": {
          name: 'isNotExist',
          type: 'boolean'
        },
        "isUnable": {
          name: 'isUnable',
          type: 'boolean'
        },
        "conditionName": {
          name: 'conditionName',
          type: 'string'
        },
        "deterioration": {
          name: 'deterioration',
          type: 'number'
        },
        "urgency": {
          name: 'urgency',
          type: 'number'
        },
        "reason": {
          name: 'reason',
          type: 'string'
        },
        "measure": {
          name: 'measure',
          type: 'string'
        },
        "reportPhotoIds": {
          name: 'reportPhotoIds',
          type: 'Array&lt;any&gt;'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "inspectionId": {
          name: 'inspectionId',
          type: 'number'
        },
        "checkItemId": {
          name: 'checkItemId',
          type: 'number'
        },
        "photoIds": {
          name: 'photoIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        inspection: {
          name: 'inspection',
          type: 'Inspection',
          model: 'Inspection',
          relationType: 'belongsTo',
                  keyFrom: 'inspectionId',
          keyTo: 'id'
        },
        checkItem: {
          name: 'checkItem',
          type: 'CheckItem',
          model: 'CheckItem',
          relationType: 'belongsTo',
                  keyFrom: 'checkItemId',
          keyTo: 'id'
        },
        photos: {
          name: 'photos',
          type: 'FileInfo[]',
          model: 'FileInfo',
          relationType: 'referencesMany',
                  keyFrom: 'photoIds',
          keyTo: 'id'
        },
        updater: {
          name: 'updater',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'updaterId',
          keyTo: 'id'
        },
      }
    }
  }
}
