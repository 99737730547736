import { Component, ViewChild, ElementRef, Renderer2, EventEmitter, OnDestroy, Input, Output } from '@angular/core';
import * as paper from 'paper';

/**
 * 画像上にフリーハンドで描画するツール
 */
@Component({
  selector: 'bi-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss']
})
export class ImageEditorComponent implements OnDestroy {
  /**
   * 現在選択中の色
   */
  currentColor = 'black';

  /**
   * 描画用カンバスを画像に合わせるためのパラメータ
   */
  viewRect: {width: string; height: string; top: string; left: string};

  /**
   * エディタを閉じたときのイベント
   */
  @Output() editorClose = new EventEmitter<void>();

  /**
   * 描画結果の通知用イベント
   * 描画と結合した画像のBlobを送信する
   */
  @Output() editorResult = new EventEmitter<Blob>();

  @ViewChild('mainContainer', { static: true }) mainContainer: ElementRef;
  @ViewChild('targetImage') targetImage: ElementRef;
  @ViewChild('editorCanvas', { static: true }) editorCanvas: ElementRef;

  /**
   * 元画像のURL
   */
  @Input()
  get image() {
 return this._image;
}
  set image(image: string) {
    this._image = image;
  }
  private _image: string;

  constructor(
    private renderer: Renderer2
  ) { }

  /**
   * クリーンアップ処理
   * 作成したPaper.jsのオブジェクトは明示的に破棄する必要がある
   */
  ngOnDestroy() {
    if (paper.tool) {
      paper.tool.remove();
    }
    if (paper.project) {
      paper.project.remove();
    }
  }

  /**
   * 元画像ロード完了イベントのハンドラ
   * 画面上の画像の大きさを計算して描画用カンバスをフィットさせる
   */
  onLoadImage() {
    const containerEl = this.mainContainer.nativeElement as HTMLDivElement;
    const imageEl = this.targetImage.nativeElement as HTMLImageElement;
    const imageAspectRatio = imageEl.naturalWidth / imageEl.naturalHeight;
    let viewWidth: number;
    let viewHeight: number;
    let viewTop: number;
    let viewLeft: number;

    if (containerEl.clientWidth / containerEl.clientHeight > imageAspectRatio) { // 画像が画面より縦長
      viewWidth = containerEl.clientHeight * imageAspectRatio;
      viewHeight = containerEl.clientHeight;
      viewTop = 0;
      viewLeft = (containerEl.clientWidth - viewWidth) / 2;
    } else { // 画像が画面より横長
      viewWidth = containerEl.clientWidth;
      viewHeight = containerEl.clientWidth / imageAspectRatio;
      viewTop = (containerEl.clientHeight - viewHeight) / 2;
      viewLeft = 0;
    }
    this.viewRect = {
      width: viewWidth + 'px',
      height: viewHeight + 'px',
      top: viewTop + 'px',
      left: viewLeft + 'px'
    };

    setTimeout(() => this.setup());
  }

  /**
   * 描画用カンバスをPaper.jsに紐付ける
   */
  setup() {
    const canvas = this.editorCanvas.nativeElement as HTMLCanvasElement;

    paper.setup(canvas);

    /** 描画ツール */
    const tool = new paper.Tool();
    let path: paper.Path;

    tool.onMouseDown = e => {
      path = new paper.Path({
        segments: [e.point],
        strokeColor: this.currentColor,
        strokeWidth: 5
      });
    };

    tool.onMouseDrag = e => {
      path.add(e.point);
    };

    tool.onMouseUp = e => {
      path.simplify(10);
    };
  }

  /**
   * カンバスをクリアして描画を全て消す
   */
  clear() {
    paper.project.clear();
  }

  /**
   * ペンの色を設定する
   *
   * @param color 色名または16進数(#RRGGBB)
   */
  setColor(color: string) {
    this.currentColor = color;
  }

  /**
   * 画像を結合して結果を通知する
   */
  async save(): Promise<void> {
    const blob = await this.combine();
    this.editorResult.emit(blob);
    this.editorClose.emit();
  }

  /**
   * 保存せずに閉じる
   */
  cancel() {
    this.editorClose.emit();
  }

  /**
   * 元画像と描画を結合する
   * 画質60のJPEGとして生成する
   */
  private combine(): Promise<Blob> {
    const combinationCanvas = this.renderer.createElement('canvas') as HTMLCanvasElement;
    const ctx = combinationCanvas.getContext('2d');
    const image = this.targetImage.nativeElement as HTMLImageElement;
    const drawing = this.editorCanvas.nativeElement as HTMLCanvasElement;
    const destWidth = image.naturalWidth;
    const destHeight = image.naturalHeight;

    combinationCanvas.width = destWidth;
    combinationCanvas.height = destHeight;
    ctx.drawImage(image, 0, 0, destWidth, destHeight, 0, 0, destWidth, destHeight);
    ctx.drawImage(drawing, 0, 0, drawing.width, drawing.height, 0, 0, destWidth, destHeight);

    return new Promise((resolve, reject) => {
      if (combinationCanvas.toBlob) { // Chrome, Firefox
        combinationCanvas.toBlob(result => resolve(result), 'image/jpeg', 0.6);
      } else { // IE等 mimeタイプや品質が指定できないのでmsToBlobは使わない
        const bin = atob(combinationCanvas.toDataURL('image/jpeg', 0.6).split(',')[1]);
        const arr: number[] = [];

        for (let i = 0; i < bin.length; i++) {
          arr.push(bin.charCodeAt(i));
        }

        const blob = new Blob([new Uint8Array(arr)], { type: 'image/jpeg' });

        resolve(blob);
      }
    });
  }
}
