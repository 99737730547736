import { Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';

/**
 * ページコンポーネントの共通ロジック
 */
export abstract class PageBase {

  /**
   * ページタイトル
   */
  title: string;

  /**
   * HTMLのtitle要素設定用
   * 継承ページクラスでinjectする
   */
  protected titleService: Title;

  init() {
    this.setTitle(this.title);
  }

  /**
   * ページタイトルを設定する
   * HTMLのtitle要素には後ろにアプリ名をつける
   *
   * @param title タイトル
   */
  setTitle(title: string) {
    this.title = title;

    const htmlTitle = (title ? `${title} | ` : '') + environment.title;

    this.titleService.setTitle(htmlTitle);
  }
}
