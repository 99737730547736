/* eslint-disable */
import { Injectable } from '@angular/core';
import { Media } from '../../models/Media';
import { Company } from '../../models/Company';
import { Branch } from '../../models/Branch';
import { Staff } from '../../models/Staff';
import { RoleGroup } from '../../models/RoleGroup';
import { Building } from '../../models/Building';
import { CheckItem } from '../../models/CheckItem';
import { CheckItemCategory } from '../../models/CheckItemCategory';
import { ImportHistory } from '../../models/ImportHistory';
import { Patrol } from '../../models/Patrol';
import { PatrolDetail } from '../../models/PatrolDetail';
import { FileInfo } from '../../models/FileInfo';
import { Option } from '../../models/Option';
import { Room } from '../../models/Room';
import { EquipmentType } from '../../models/EquipmentType';
import { Equipment } from '../../models/Equipment';
import { ConditionTemplate } from '../../models/ConditionTemplate';
import { Inspection } from '../../models/Inspection';
import { InspectionDetail } from '../../models/InspectionDetail';
import { PatrolAnnualReport } from '../../models/PatrolAnnualReport';
import { RepairItem } from '../../models/RepairItem';
import { RepairItemCategory } from '../../models/RepairItemCategory';
import { RepairWork } from '../../models/RepairWork';
import { RepairPlan } from '../../models/RepairPlan';
import { RepairPlanWork } from '../../models/RepairPlanWork';

export interface Models { [name: string]: any }

@Injectable()
export class SDKModels {

  private models: Models = {
    Media: Media,
    Company: Company,
    Branch: Branch,
    Staff: Staff,
    RoleGroup: RoleGroup,
    Building: Building,
    CheckItem: CheckItem,
    CheckItemCategory: CheckItemCategory,
    ImportHistory: ImportHistory,
    Patrol: Patrol,
    PatrolDetail: PatrolDetail,
    FileInfo: FileInfo,
    Option: Option,
    Room: Room,
    EquipmentType: EquipmentType,
    Equipment: Equipment,
    ConditionTemplate: ConditionTemplate,
    Inspection: Inspection,
    InspectionDetail: InspectionDetail,
    PatrolAnnualReport: PatrolAnnualReport,
    RepairItem: RepairItem,
    RepairItemCategory: RepairItemCategory,
    RepairWork: RepairWork,
    RepairPlan: RepairPlan,
    RepairPlanWork: RepairPlanWork,

  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
