import { Pipe, PipeTransform } from '@angular/core';

import { FileInfoService } from '../services/file-info.service';
import { FileInfo } from '../../loopback';

@Pipe({
  name: 'fileUrl'
})
export class FileUrlPipe implements PipeTransform {

  constructor(
    private fileInfo: FileInfoService
  ) {}

  transform(file: FileInfo, type: string = 'normal'): string {
    if (!file) {
      return '';
    }

    let url = this.fileInfo.getUrl(file);
    const preventCache = file.name.endsWith('-D');

    if (preventCache) {
      url += (url.indexOf('?') === -1 ? '?' : '&') + '_=' + Date.now();
    }

    if (type === 'css') {
      return `url(${url})`;
    }

    return url;
  }

}
