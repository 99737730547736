/* eslint-disable */
import {
  Company
} from '../index';

declare var Object: any;
export interface BranchInterface {
  "name"?: string;
  "abbr"?: string;
  "zipCode"?: string;
  "address1"?: string;
  "address2"?: string;
  "phone"?: string;
  "fax"?: string;
  "status": number;
  "id"?: number;
  "companyId"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId"?: number;
  company?: Company;
}

export class Branch implements BranchInterface {
  "name": string;
  "abbr": string;
  "zipCode": string;
  "address1": string;
  "address2": string;
  "phone": string;
  "fax": string;
  "status": number;
  "id": number;
  "companyId": number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId": number;
  company?: Company;
  constructor(data?: Partial<BranchInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Branch`.
   */
  public static getModelName() {
    return "Branch";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Branch for dynamic purposes.
  **/
  public static factory(data: BranchInterface): Branch{
    return new Branch(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Branch',
      plural: 'Branches',
      path: 'Branches',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "abbr": {
          name: 'abbr',
          type: 'string'
        },
        "zipCode": {
          name: 'zipCode',
          type: 'string'
        },
        "address1": {
          name: 'address1',
          type: 'string'
        },
        "address2": {
          name: 'address2',
          type: 'string'
        },
        "phone": {
          name: 'phone',
          type: 'string'
        },
        "fax": {
          name: 'fax',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 1
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
      }
    }
  }
}
