import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

// モジュールとしてインポートできないのでexports-loaderで読み込む
const yubinBangoModule = require('exports-loader?YubinBango!yubinbango-core');

type AddressField = 'region'|'locality'|'street'|'extended';

/**
 * 郵便番号関連サービス
 */
@Injectable({
  providedIn: 'root'
})
export class YubinBangoService {
  /**
   * 郵便番号から住所を取得する
   *
   * @param yubinBango 郵便番号
   */
  autoAddress(yubinBango: string): Promise<{[field in AddressField]: string}> {
    return new Promise(resolve => {
      new yubinBangoModule.Core(yubinBango, addr => resolve(addr));
    });
  }

  /**
   * ハイフン無しの郵便番号を有りに置き換える
   *
   * @param control ReactiveFormのコントロール
   */
  normalize(control: AbstractControl) {
    if (control && control.value.match(/^[0-9]{3}[0-9]{4}$/)) {
      control.setValue(control.value.replace(/([0-9]{3})([0-9]{4})/, '$1-$2'));
    }
  }

}
