import { Component, HostBinding } from '@angular/core';
import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { IntervalInputBase } from '../../classes/interval-input-base';

export const BI_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'YYYY-MM-DD'
  },
  display: {
    dateInput: 'YYYY年M月D日',
    monthYearLabel: 'YYYY年M月',
    dateA11yLabel: 'YYYY-MM-DD',
    monthYearA11yLabel: 'YYYY-MM'
  }
};

@Component({
  selector: 'bi-date-interval-input',
  templateUrl: './date-interval-input.component.html',
  styleUrls: ['./date-interval-input.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: DateIntervalInputComponent },
    { provide: MAT_DATE_FORMATS, useValue: BI_DATE_FORMATS }
  ]
})
export class DateIntervalInputComponent extends IntervalInputBase {
  static idSeq = 0;
  controlType = 'date-interval-input';

  @HostBinding() id = `bi-month-interval-input-${DateIntervalInputComponent.idSeq++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';
}
