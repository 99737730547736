import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { BuildingApi, PatrolApi, InspectionApi } from 'loopback';

@Component({
  selector: 'bi-alert-list-widget',
  templateUrl: './alert-list-widget.component.html',
  styleUrls: ['./alert-list-widget.component.scss']
})
export class AlertListWidgetComponent implements OnInit {

  patrolUndecidedCount: number;
  inspectionUndecidedCount: number;
  patrolUnapprovedCount: number;
  inspectionUnapprovedCount: number;

  constructor(
    private router: Router,
    private buildingApi: BuildingApi,
    private patrolApi: PatrolApi,
    private inspectionApi: InspectionApi
  ) { }

  ngOnInit() {
    forkJoin([
      this.buildingApi.find({
        where: {
          or: [
            {dateNextPatrol: null},
            {dateNextInspection: null}
          ]
        }
      }),
      this.patrolApi.count({status: 3, approvalStatus: 1}),
      this.inspectionApi.count({status: 3, approvalStatus: 1, roomId: null})
    ]).subscribe(
      res => {
        const buildings = res[0];
        this.patrolUndecidedCount = buildings.filter(b => !b.dateNextPatrol).length;
        this.inspectionUndecidedCount = buildings.filter(b => !b.dateNextInspection).length;
        this.patrolUnapprovedCount = res[1].count;
        this.inspectionUnapprovedCount = res[2].count;
      }
    );
  }

  navigateToNextPatrolUndecidedList() {
    localStorage.setItem('bi.searchCond.BuildingList', JSON.stringify({
      address1: '',
      address2: '',
      branchId: null,
      code: '',
      name: '',
      nextInspectionUndecided: false,
      nextPatrolUndecided: true,
      ownerName: '',
      staffId: null,
      status: null
    }));

    this.router.navigate(['/building']);
  }

  navigateToNextInspectionUndecidedList() {
    localStorage.setItem('bi.searchCond.BuildingList', JSON.stringify({
      address1: '',
      address2: '',
      branchId: null,
      code: '',
      name: '',
      nextInspectionUndecided: true,
      nextPatrolUndecided: false,
      ownerName: '',
      staffId: null,
      status: null
    }));

    this.router.navigate(['/building']);
  }

  navigateToPatrolUnapprovedList() {
    localStorage.setItem('bi.searchCond.InspectionList', JSON.stringify({
      approvalStatus: 1,
      buildingAddress1: '',
      buildingAddress2: '',
      buildingCode: '',
      buildingName: '',
      buildingOwnerName: '',
      finishedAt: { start: null, end: null },
      staffId: null
    }));

    this.router.navigate(['/task/patrol']);
  }

  navigateToInspectionUnapprovedList() {
    localStorage.setItem('bi.searchCond.InspectionList', JSON.stringify({
      approvalStatus: 1,
      buildingAddress1: '',
      buildingAddress2: '',
      buildingCode: '',
      buildingName: '',
      buildingOwnerName: '',
      finishedAt: { start: null, end: null },
      staffId: null
    }));

    this.router.navigate(['/task/inspection']);
  }
}
