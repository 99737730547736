import { Injectable } from '@angular/core';
import { Observable, Subject, of, throwError } from 'rxjs';
import { debounce, mergeMap, map, tap, catchError } from 'rxjs/operators';

import { GlobalStateService } from './global-state.service';

import { Staff, StaffApi, RoleGroup, RoleGroupApi, Company } from '../../loopback';

/**
 * 権限関連サービス
 */
@Injectable({
  providedIn: 'root'
})
export class RoleService {
  /**
   * ログインユーザー
   */
  private user: Staff;

  /**
   * ログインユーザーの権限グループ
   */
  private role: RoleGroup;

  /**
   * ログインユーザーの所属会社
   */
  private company: Company;

  /**
   * 管理側アクセスに必要な権限
   * この中の何れかがあればアクセス可
   */
  private adminCaps: string[] = [];

  /**
   * 権限変更の通知
   */
  private roleChangeSubject = new Subject<RoleGroup>();

  get currentUser() {
    return this.user;
  }

  get currentCompany(): Company {
    return this.company;
  }

  get roleChanged() {
    return this.roleChangeSubject.asObservable();
  }

  constructor(
    private state: GlobalStateService,
    private staffApi: StaffApi,
    private roleGroupApi: RoleGroupApi
  ) {
    // 管理側アクセス権限はroleTablet以外
    const modelDef = RoleGroup.getModelDefinition();
    this.adminCaps = Object.keys(modelDef.properties).filter(p => /^role(?!Tablet)/.test(p));
  }

  init(): Observable<Staff> {
    return this.resolve();
  }

  /**
   * ログイン処理
   * 管理側アクセス権限がなければ失敗する
   *
   * @param credentials ユーザー名/パスワード
   */
  login(credentials: any): Observable<Staff> {
    return this.staffApi.login(credentials).pipe(
      mergeMap(result => this.resolve()),
      mergeMap(result => {
        if (!result) {
          return throwError('Cannot read user data');
        }

        if (this.hasAdminAccess()) {
          return of(result);
        } else {
          return this.logout().pipe(
            mergeMap(_ => throwError('The user has no capability for admin access.'))
          );
        }
      })
    );
  }

  /**
   * ログアウト処理
   */
  logout(): Observable<any> {
    return this.staffApi.logout().pipe(
      catchError((_err, _caught) => of(null)),
      tap(_result => this.clear())
    );
  }

  /**
   * ログイン中のユーザー情報から権限グループと会社を取得する
   */
  resolve(): Observable<Staff> {
    return this.staffApi.me([
      'roleGroup',
      {
        relation: 'company',
        scope: {
          include: 'logoImage'
        }
      }
    ]).pipe(
      tap(res => {
        if (!this.user || this.user.id !== res.id) {
          this.user = res;
          this.state.set('currentUser', res);
        }

        if (!this.role || this.role.id !== res.roleGroup.id) {
          this.role = res.roleGroup;
          this.roleChangeSubject.next(res.roleGroup);
        }

        this.company = res.company;
      }),
      catchError((err, caught) => {
        this.clear();
        return of(null);
      })
    );
  }

  clear() {
    this.user = undefined;
    this.role = undefined;
    this.company = undefined;
    this.roleChangeSubject.next(undefined);
    this.state.set('currentUser', undefined);
  }

  /**
   * ログインユーザーが指定した権限を持っているか調べる
   *
   * @param cap 権限
   */
  can(cap: string): boolean {
    if (!this.role) {
      return false;
    }

    const prop: string = 'role' + cap.charAt(0).toUpperCase() + cap.substr(1);
    return this.role.hasOwnProperty(prop) && this.role[prop];
  }

  /**
   * ログインユーザーが指定した権限を全て持っているか調べる
   *
   * @param caps 権限の配列
   */
  canEvery(caps: string[]): boolean {
    if (!this.role) {
      return false;
    }

    return caps.every(cap => this.can(cap));
  }

  /**
   * ログインユーザーが指定した権限の何れかを持っているか調べる
   *
   * @param caps 権限の配列
   */
  canSome(caps: string[]): boolean {
    return caps.some(cap => this.can(cap));
  }

  /**
   * ログインユーザーが管理側アクセス可能か調べる
   */
  hasAdminAccess(): boolean {
    return this.adminCaps.some(cap => this.role.hasOwnProperty(cap) && this.role[cap]);
  }

}
