import { Directive, Input, ElementRef, HostListener } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { YubinBangoService } from '../services/yubin-bango.service';

type AddressField = 'region'|'locality'|'street'|'extended';

@Directive({
  selector: '[biAutoAddress]',
  exportAs: 'biAutoAddress'
})
export class AutoAddressDirective {

  @Input('biAutoAddress') fields: {[field in AddressField]: AbstractControl};

  constructor(
    private el: ElementRef,
    private yubinBango: YubinBangoService
  ) { }

  execute() {
    return this.yubinBango.autoAddress(this.el.nativeElement.value).then(addr => {
      // 複数のフィールドが同じコントロールに割り当てられた場合を想定して
      // 入力は必ず以下の順番で行われるようにする
      const addressFields: AddressField[] = ['region', 'locality', 'street', 'extended'];

      // 住所コントロールを一旦空にする
      addressFields.forEach(fieldName => {
        const field = this.fields[fieldName];

        if (field && field.value !== '') {
          field.setValue('');
        }
      });
      // 住所検索結果を入力
      // 同じコントロールだった場合は後ろに追加する
      addressFields.forEach(fieldName => {
        const field = this.fields[fieldName];

        if (field) {
          field.setValue(field.value + addr[fieldName]);
        }
      });
    });
  }

}

@Directive({
  selector: 'button[biAutoAddressButton]'
})
export class AutoAddressButtonDirective {

  @Input('biAutoAddressButton') autoAddress: AutoAddressDirective;

  constructor() { }

  @HostListener('click') onclick() {
    if (this.autoAddress) {
      this.autoAddress.execute();
    }
  }
}
