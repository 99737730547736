/* eslint-disable */
import {
  Company,
  Branch,
  Building,
  Room,
  Staff,
  FileInfo,
  InspectionDetail,
  RepairPlan
} from '../index';

declare var Object: any;
export interface InspectionInterface {
  "status": number;
  "finishedAt"?: Date|string;
  "approvalStatus": number;
  "approvedAt"?: Date|string;
  "summary"?: string;
  "sendbackMessage"?: string;
  "isUnable"?: boolean;
  "uploadedAt"?: Date|string;
  "reportCoverPhotoId"?: number;
  "id"?: number;
  "companyId"?: number;
  "branchId"?: number;
  "buildingId"?: number;
  "roomId"?: number;
  "staffId"?: number;
  "managerId"?: number;
  "summarizerId"?: number;
  "buildingInspectionId"?: number;
  "coverPhotoIds"?: Array<any>;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId"?: number;
  company?: Company;
  branch?: Branch;
  building?: Building;
  room?: Room;
  staff?: Staff;
  manager?: Staff;
  summarizer?: Staff;
  buildingInspection?: Inspection;
  roomInspections?: Inspection[];
  coverPhotos?: FileInfo[];
  updater?: Staff;
  details?: InspectionDetail[];
  repairPlan?: RepairPlan;
}

export class Inspection implements InspectionInterface {
  "status": number;
  "finishedAt": Date|string;
  "approvalStatus": number;
  "approvedAt": Date|string;
  "summary": string;
  "sendbackMessage": string;
  "isUnable": boolean;
  "uploadedAt": Date|string;
  "reportCoverPhotoId": number;
  "id": number;
  "companyId": number;
  "branchId": number;
  "buildingId": number;
  "roomId": number;
  "staffId": number;
  "managerId": number;
  "summarizerId": number;
  "buildingInspectionId": number;
  "coverPhotoIds": Array<any>;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId": number;
  company?: Company;
  branch?: Branch;
  building?: Building;
  room?: Room;
  staff?: Staff;
  manager?: Staff;
  summarizer?: Staff;
  buildingInspection?: Inspection;
  roomInspections?: Inspection[];
  coverPhotos?: FileInfo[];
  updater?: Staff;
  details?: InspectionDetail[];
  repairPlan?: RepairPlan;
  constructor(data?: Partial<InspectionInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Inspection`.
   */
  public static getModelName() {
    return "Inspection";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Inspection for dynamic purposes.
  **/
  public static factory(data: InspectionInterface): Inspection{
    return new Inspection(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Inspection',
      plural: 'Inspections',
      path: 'Inspections',
      idName: 'id',
      properties: {
        "status": {
          name: 'status',
          type: 'number',
          default: 1
        },
        "finishedAt": {
          name: 'finishedAt',
          type: 'Date|string'
        },
        "approvalStatus": {
          name: 'approvalStatus',
          type: 'number',
          default: 1
        },
        "approvedAt": {
          name: 'approvedAt',
          type: 'Date|string'
        },
        "summary": {
          name: 'summary',
          type: 'string'
        },
        "sendbackMessage": {
          name: 'sendbackMessage',
          type: 'string'
        },
        "isUnable": {
          name: 'isUnable',
          type: 'boolean'
        },
        "uploadedAt": {
          name: 'uploadedAt',
          type: 'Date|string'
        },
        "reportCoverPhotoId": {
          name: 'reportCoverPhotoId',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "branchId": {
          name: 'branchId',
          type: 'number'
        },
        "buildingId": {
          name: 'buildingId',
          type: 'number'
        },
        "roomId": {
          name: 'roomId',
          type: 'number'
        },
        "staffId": {
          name: 'staffId',
          type: 'number'
        },
        "managerId": {
          name: 'managerId',
          type: 'number'
        },
        "summarizerId": {
          name: 'summarizerId',
          type: 'number'
        },
        "buildingInspectionId": {
          name: 'buildingInspectionId',
          type: 'number'
        },
        "coverPhotoIds": {
          name: 'coverPhotoIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        branch: {
          name: 'branch',
          type: 'Branch',
          model: 'Branch',
          relationType: 'belongsTo',
                  keyFrom: 'branchId',
          keyTo: 'id'
        },
        building: {
          name: 'building',
          type: 'Building',
          model: 'Building',
          relationType: 'belongsTo',
                  keyFrom: 'buildingId',
          keyTo: 'id'
        },
        room: {
          name: 'room',
          type: 'Room',
          model: 'Room',
          relationType: 'belongsTo',
                  keyFrom: 'roomId',
          keyTo: 'id'
        },
        staff: {
          name: 'staff',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'staffId',
          keyTo: 'id'
        },
        manager: {
          name: 'manager',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'managerId',
          keyTo: 'id'
        },
        summarizer: {
          name: 'summarizer',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'summarizerId',
          keyTo: 'id'
        },
        buildingInspection: {
          name: 'buildingInspection',
          type: 'Inspection',
          model: 'Inspection',
          relationType: 'belongsTo',
                  keyFrom: 'buildingInspectionId',
          keyTo: 'id'
        },
        roomInspections: {
          name: 'roomInspections',
          type: 'Inspection[]',
          model: 'Inspection',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'buildingInspectionId'
        },
        coverPhotos: {
          name: 'coverPhotos',
          type: 'FileInfo[]',
          model: 'FileInfo',
          relationType: 'referencesMany',
                  keyFrom: 'coverPhotoIds',
          keyTo: 'id'
        },
        updater: {
          name: 'updater',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'updaterId',
          keyTo: 'id'
        },
        details: {
          name: 'details',
          type: 'InspectionDetail[]',
          model: 'InspectionDetail',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'inspectionId'
        },
        repairPlan: {
          name: 'repairPlan',
          type: 'RepairPlan',
          model: 'RepairPlan',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'inspectionId'
        },
      }
    }
  }
}
