import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageResizerService {

  private renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  async resizeToContainInSquare(file: File, maxSize: number): Promise<Blob> {
    const image = await this.loadImage(file);
    const srcWidth = image.naturalWidth;
    const srcHeight = image.naturalHeight;
    let destWidth = srcWidth;
    let destHeight = srcHeight;

    if (srcWidth > maxSize && srcWidth > srcHeight) {
      destWidth = maxSize;
      destHeight = maxSize * srcHeight / srcWidth;
    } else if (srcHeight > maxSize && srcHeight > srcWidth) {
      destWidth = maxSize * srcWidth / srcHeight;
      destHeight = maxSize;
    }

    return this.resize(image, destWidth, destHeight);
  }

  private loadImage(file: File): Promise<HTMLImageElement> {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const url = URL.createObjectURL(file);
      const tmpImage = this.renderer.createElement('img') as HTMLImageElement;

      this.renderer.setProperty(tmpImage, 'src', url);
      this.renderer.listen(tmpImage, 'load', () => resolve(tmpImage));
    });
  }

  private resize(image: HTMLImageElement, destWidth: number, destHeight: number): Promise<Blob> {
    const canvas = this.renderer.createElement('canvas') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    const srcWidth = image.naturalWidth;
    const srcHeight = image.naturalHeight;

    canvas.width = destWidth;
    canvas.height = destHeight;
    ctx.drawImage(image, 0, 0, srcWidth, srcHeight, 0, 0, destWidth, destHeight);

    return new Promise((resolve, reject) => {
      if (canvas.toBlob) { // Chrome, Firefox
        canvas.toBlob(result => resolve(result), 'image/jpeg', 0.6);
      } else {
        const bin = atob(canvas.toDataURL('image/jpeg', 0.6).split(',')[1]);
        const arr: number[] = [];

        for (let i = 0; i < bin.length; i++) {
          arr.push(bin.charCodeAt(i));
        }

        const blob = new Blob([new Uint8Array(arr)], { type: 'image/jpeg' });

        resolve(blob);
      }
    });
  }
}
