import { Pipe, PipeTransform } from '@angular/core';
import { OptionsService } from 'app/services/options.service';

@Pipe({
  name: 'optionLabel'
})
export class OptionLabelPipe implements PipeTransform {

  constructor(private opt: OptionsService) { }

  transform(value: number, property: string): any {
    return this.opt.getOptionLabel(property, value);
  }

}
