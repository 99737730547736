import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ImageResizerService } from 'app/services/image-resizer.service';

export interface ImageSelectorSelectEvent {
  name: string;
  blob: Blob;
}

@Component({
  selector: 'bi-image-selector',
  templateUrl: './image-selector.component.html',
  styleUrls: ['./image-selector.component.scss']
})
export class ImageSelectorComponent implements OnInit {

  previewUrl: SafeUrl;
  showDefault = false;
  isInvalidPreviewFile = false;

  @Input() default: string;
  @Output('imageSelect') imageSelect = new EventEmitter<ImageSelectorSelectEvent>();
  @Output('imageReset') imageReset = new EventEmitter<void>();

  constructor(
    private cdRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private imageResizer: ImageResizerService
  ) { }

  ngOnInit() {
    if (this.default) {
      this.showDefault = true;
    }
  }

  onDragOver(e: DragEvent) {
    e.preventDefault();
  }

  onDrop(e: DragEvent) {
    e.preventDefault();
    const file = e.dataTransfer.files.item(0);

    this.setImage(file);
  }

  onFileSelect(file: File) {
    this.setImage(file);
  }

  async setImage(file: File) {
    this.isInvalidPreviewFile = file.type.split('/')[0] !== 'image';

    if (this.isInvalidPreviewFile) {
      return;
    }

    const resizedFile = await this.imageResizer.resizeToContainInSquare(file, 768);
    const image = {
      name: file.name,
      blob: resizedFile,
    };

    this.previewUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(resizedFile));
    this.cdRef.markForCheck();
    this.imageSelect.emit(image);
  }

  unsetImage() {
    this.previewUrl = undefined;
    this.showDefault = false;
    this.imageSelect.emit(null);
  }

  resetImage() {
    if (this.default) {
      this.showDefault = true;
    }
    this.imageReset.emit();
  }

}
