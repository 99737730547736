import { Component, OnInit, Inject, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { OptionApi } from '../../../loopback';

@Component({
  selector: 'bi-par-equipment-selector',
  templateUrl: './par-equipment-selector.component.html',
  styleUrls: ['./par-equipment-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PAREquipmentSelectorComponent implements OnInit {

  title = '設備を選択';
  form: FormGroup<{ options: FormArray<FormControl<boolean>> }>;
  options: FormArray<FormControl<boolean>>;
  optionValues: string[] = [];

  constructor(
    private cdRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private ref: MatDialogRef<PAREquipmentSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private optionApi: OptionApi
  ) {}

  ngOnInit() {
    if (this.data.type !== 'room' && this.data.type !== 'common') {
      throw new Error('Invalid dialog data: type value must be "room" or "common"');
    }

    this.data.selected = this.data.selected || [];

    const ucFirst = this.data.type.charAt(0).toUpperCase() + this.data.type.slice(1);
    const optionName = `par${ucFirst}EquipmentList`;

    this.options = this.formBuilder.array([] as boolean[]);
    this.form = this.formBuilder.group({options: this.options});

    this.optionApi.findOne({where: {name: optionName}}).subscribe(
      option => {
        for (const val of option.value) {
          const isSelected = this.data.selected.indexOf(val) !== -1;

          this.optionValues.push(val);
          this.options.push(this.formBuilder.control(isSelected));
        }
        this.cdRef.markForCheck();
      }
    );
  }

  onOkClick() {
    const result = this.optionValues.filter((val, i) => this.options.at(i).value);
    this.ref.close(result);
  }

}
