import { Component, AfterViewInit, Input, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { AnimationBuilder, AnimationPlayer, style, animate } from '@angular/animations';

import { GlobalEventService } from '../../services/global-event.service';

/**
 * 先頭に戻る・無限スクロール機能付きのコンテナ
 */
@Component({
  selector: 'bi-scroll-content',
  templateUrl: 'scroll-content.component.html',
  styleUrls: ['scroll-content.component.scss']
})
export class ScrollContentComponent implements AfterViewInit {

  /**
   * 先頭に戻るボタンが表示されているかどうかのフラグ
   */
  backTopVisible = false;

  /**
   * 無限スクロールイベントを起こすかどうかのフラグ
   */
  infiniteObserved = true;

  /**
   * 無限スクロールのページカウント
   */
  infinitePageCount = 1;

  /**
   * 先頭に戻るボタンの表示を開始するスクロール位置
   * この値以上スクロールするとボタンが表示する
   */
  @Input() backTopThreshold = 200;

  /**
   * 先頭に戻るボタンの表示アニメーションのスピード
   */
  @Input() backTopShowSpeed = 500;

  /**
   * 先頭に戻るスクロールアニメーションのスピード
   */
  @Input() backTopMoveSpeed = 500;

  /**
   * 無限スクロールイベントを起こす下からのスクロール位置
   * 一番下までの距離がこの値を下回るとイベントを起こす
   */
  @Input() infiniteThreshold = 200;

  /**
   * 無限スクロールイベント通知用EventEmitter
   * page -> 現在のページ数
   * callback -> イベント処理後に無限スクロールを継続可否を引数にして呼ぶ
   */
  @Output() infinite = new EventEmitter<{page: number; callback: (continued: boolean) => void}>();

  /**
   * コンテナへの参照
   */
  @ViewChild('scroller', { static: true }) scroller: ElementRef;

  /**
   * スクロールアニメーション用ヘルパー要素
   */
  @ViewChild('scrollHelper', { static: true }) scrollHelper: ElementRef;

  /**
   * 内容への参照
   */
  @ViewChild('scrollContent', { static: true }) scrollContent: ElementRef;

  constructor(
    private animBuilder: AnimationBuilder,
    private globalEvent: GlobalEventService
  ) {
    this.globalEvent.on('scrollToTop').subscribe(() => this.scrollToTop());
  }

  /**
   * DOMのスクロールイベントハンドラ
   *
   * @param scrollTop スクロール位置
   */
  onScroll(e: Event) {
    const scrollTop = (e.target as HTMLElement).scrollTop;
    this.updateBackTopAndInfiniteState(scrollTop);
  }

  updateBackTopAndInfiniteState(scrollTop: number) {
    const scrollerEl = this.scroller.nativeElement as HTMLElement;
    const scrollContentEl = this.scrollContent.nativeElement as HTMLElement;
    const distance = scrollContentEl.clientHeight - (scrollerEl.clientHeight + scrollTop);

    this.backTopVisible = scrollTop > this.backTopThreshold;

    if (this.infiniteObserved && distance <= this.infiniteThreshold) {
      this.infiniteObserved = false;
      this.infinite.emit({
        page: ++this.infinitePageCount,
        callback: (continued: boolean) => {
          if (continued) {
            this.infiniteObserved = true;
          }
        }
      });
    }
  }

  /**
   * 先頭に戻るスクロールアニメーションの作成
   *
   * @param offset 現在のスクロール位置
   */
  createScrollHelperAnimation(offset: number): AnimationPlayer {
    const helperEl = this.scrollHelper.nativeElement as HTMLElement;
    const factory = this.animBuilder.build([
      style({marginTop: `-${offset}px`}),
      animate(`${this.backTopMoveSpeed}ms ease`, style({marginTop: 0}))
    ]);

    return factory.create(helperEl);
  }

  /**
   * ページの先頭に戻る
   */
  scrollToTop() {
    const el = this.scroller.nativeElement as HTMLElement;
    const offset = el.scrollTop;
    const animation = this.createScrollHelperAnimation(offset);

    el.scrollTop = 0;
    animation.play();
  }

  resetInfiniteState() {
    this.infiniteObserved = true;
    this.infinitePageCount = 1;
  }

  ngAfterViewInit() {
    this.updateBackTopAndInfiniteState(0);
  }

  onBackTopClick(e: MouseEvent) {
    e.preventDefault();
    this.scrollToTop();
  }
}
