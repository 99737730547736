import { AbstractControl, FormGroup, ValidationErrors, Validators, ValidatorFn } from '@angular/forms';

export function patternValidatorFactory(name: string, pattern: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    const validator = Validators.pattern(pattern);
    const result = validator(control);

    return result ? {[name]: result['pattern']} : null;
  };
}

export const emailValidator: ValidatorFn = patternValidatorFactory(
  'email', /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i
);

export const phoneJpValidator: ValidatorFn = patternValidatorFactory(
  'phone', /^0[1-9][0-9]{0,3}-[1-9][0-9]{0,3}-[0-9]{4}$/i
);

export const postalJpValidator: ValidatorFn = patternValidatorFactory(
  'postal', /^[0-9]{3}-?[0-9]{4}$/i
);

export function groupEqualityValidator(group: FormGroup) {
  let value;
  let isValid = true;

  for (const field in group.value) {
    if (!group.value.hasOwnProperty(field)) {
      continue;
    }

    if (!value || value === group.value[field]) {
      value = group.value[field];
      continue;
    }

    isValid = false;
  }

  return isValid ? null : {equality: true};
}
