import { Component, Input, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';

export const BI_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY年M月',
    monthYearLabel: 'YYYY年M月',
    dateA11yLabel: 'YYYY-MM-DD',
    monthYearA11yLabel: 'YYYY-MM',
  },
};

@Component({
  selector: 'bi-month-selector',
  templateUrl: './month-selector.component.html',
  styleUrls: ['./month-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MAT_DATE_FORMATS, useValue: BI_DATE_FORMATS }],
})
export class MonthSelectorComponent {
  @Input() dateControl = new FormControl(moment());
  @Input() min: moment.Moment;
  @Input() max: moment.Moment;
  @Output() dateChange = new EventEmitter<moment.Moment>();

  constructor() {}

  onMonthSelected(date: moment.Moment) {
    this.dateControl.setValue(date);
  }
}
