/* eslint-disable */
import {
  Company,
  CheckItem,
  Staff
} from '../index';

declare var Object: any;
export interface CheckItemCategoryInterface {
  "target"?: number;
  "name"?: string;
  "order"?: number;
  "id"?: number;
  "companyId"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId"?: number;
  company?: Company;
  checkItems?: CheckItem[];
  updater?: Staff;
}

export class CheckItemCategory implements CheckItemCategoryInterface {
  "target": number;
  "name": string;
  "order": number;
  "id": number;
  "companyId": number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId": number;
  company?: Company;
  checkItems?: CheckItem[];
  updater?: Staff;
  constructor(data?: Partial<CheckItemCategoryInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CheckItemCategory`.
   */
  public static getModelName() {
    return "CheckItemCategory";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CheckItemCategory for dynamic purposes.
  **/
  public static factory(data: CheckItemCategoryInterface): CheckItemCategory{
    return new CheckItemCategory(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CheckItemCategory',
      plural: 'CheckItemCategories',
      path: 'CheckItemCategories',
      idName: 'id',
      properties: {
        "target": {
          name: 'target',
          type: 'number'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "order": {
          name: 'order',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        checkItems: {
          name: 'checkItems',
          type: 'CheckItem[]',
          model: 'CheckItem',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'categoryId'
        },
        updater: {
          name: 'updater',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'updaterId',
          keyTo: 'id'
        },
      }
    }
  }
}
