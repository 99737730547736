import { ViewChild, Directive } from '@angular/core';
import { AbstractControl, FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of } from 'rxjs';
import { mergeMap, tap, map } from 'rxjs/operators';

import { ScrollContentComponent } from '../components/scroll-content/scroll-content.component';
import { GenericDialogComponent } from '../dialogs/generic-dialog/generic-dialog.component';
import { PageBase } from './page-base';

interface BasicMasterModel {
  id?: number;
  name?: string;
  createdAt?: Date|string;
  updatedAt?: Date|string;
  updaterId?: number;
}

/**
 * 詳細ページの共通ロジック
 */
@Directive()
export abstract class DetailPageBase<T extends BasicMasterModel> extends PageBase {

  /**
   * スクローラーのコンポーネント
   */
  @ViewChild(ScrollContentComponent, /* TODO: add static flag */ {}) content: ScrollContentComponent;

  /**
   * 表示中のデータのID
   * 新規の場合はundefinedのまま
   */
  id: number;

  /**
   * 前のページに戻ることが出来るかどうか
   * できない場合はヘッダに戻るボタンが出ない
   */
  canGoBack = false;

  /**
   * 更新/作成用フォームの設定
   */
  formConfig: any;

  /**
   * 更新/作成用フォームのReactiveForm
   */
  form: FormGroup;

  /**
   * エラーメッセージの表示用
   */
  errorMessages: string[] = [];

  /**
   * バリデーションメッセージの設定
   */
  validationMessages: {[field: string]: {[errorName: string]: string}} = {};

  /**
   * データ作成日の表示用（今のところ使っていない）
   */
  createdAt: Date|string;

  /**
   * データ更新日の表示用
   */
  updatedAt: Date|string;

  /**
   * タイトルに使用するデータのプロパティ
   */
  propertyForTitle = 'name';

  // 以下は継承コンポーネントでInjectする
  protected formBuilder: FormBuilder;
  protected route: ActivatedRoute;
  protected router: Router;
  protected snackBar: MatSnackBar;
  protected dialog: MatDialog;

  get isDirty(): boolean {
    return this.form.dirty;
  }

  get canDeactivate(): Observable<boolean> | boolean {
    if (!this.isDirty) {
      return true;
    }

    return this.dialog.open(GenericDialogComponent, {
      data: {
        type: 'confirm',
        title: '編集内容が保存されていません',
        message: '保存せずにページを移動しようとしています。保存していない編集内容は失われますがよろしいですか?'
      },
      width: '360px'
    }).afterClosed().pipe(map(result => !!result));
  }

  /**
   * 初期化処理
   * 継承コンポーネントのコンストラクタで呼び出す
   * ルートパラメータのIDがある(=既存データの編集)の場合は
   * APIからデータを取得してReactiveFormの設定まで行う
   */
  init(): Observable<T> {
    this.form = this.formBuilder.group(this.formConfig);

    return this.route.params.pipe(
      mergeMap(params => {
        this.beforeFetch(params);

        if (params['id']) {
          const id = this.id = params['id'];
          return this.fetch(id).pipe(
            tap(res => {
              this.setTitle(res[this.propertyForTitle]);
              this.canGoBack = true;
              this.createdAt = res.createdAt;
              this.updatedAt = res.updatedAt;

              this.setFormValue(res);
            })
          );
        } else {
          return of(null);
        }
      })
    );
  }

  /**
   * form設定・ルートパラメータ取得後、メインデータ取得前に実行
   * formやルートパラメータに関して処理を行う場合に使用
   *
   * @param params ルートパラメータ
   */
  beforeFetch(params: {[key: string]: any}) {}

  /**
   * ReactiveFormに値を設定
   *
   * @param value データ
   */
  setFormValue(value: T) {
    this.form.reset(value);
  }

  /**
   * 各項目のエラーをチェックしてエラーメッセージを設定する
   * validかどうかを真偽値で返す
   */
  checkValidity(): boolean {
    this.errorMessages = [];

    Object.keys(this.validationMessages).forEach(field => {
      const fieldArray = field.split('.');
      const messages = this.validationMessages[field];

      let controls: AbstractControl[] = [this.form];

      for (const f of fieldArray) {
        const c = controls[0];

        if (f === '*') {
          if (c instanceof FormGroup) {
            controls = [];

            Object.keys(c.controls).forEach(key => {
              controls.push(c.controls[key]);
            });
          } else if (c instanceof FormArray) {
            controls = c.controls;
          }

          if (!controls.length) {
            break;
          }
        } else {
          const next = c.get(f);

          if (!next) {
            controls = [];
            break;
          }

          controls = [next];
        }
      }

      for (const control of controls) {
        if (control && !control.valid && control.errors) {
          Object.keys(control.errors).forEach(key => {
            this.errorMessages.push(messages[key]);
          });
        }
      }
    });

    return this.errorMessages.length === 0;
  }

  /**
   * 保存ボタンクリックイベントで呼び出されることを想定
   * 処理終了後にSnackBarでメッセージを表示する
   *
   * @param e
   */
  onSubmit(e) {
    if (!this.checkValidity()) {
      this.content.scrollToTop();
      return;
    }

    const data = Object.assign({}, this.form.value);

    this.save(data).subscribe(
      res => {
        this.setFormValue(res);
        this.content.scrollToTop();
        this.snackBar.open('正常に保存されました', 'OK', {duration: 2000});
        if (this.router && !this.id) {
          this.router.navigate(['..', res.id], {relativeTo: this.route});
        }
      },
      err => {
        this.snackBar.open('保存時にエラーが発生しました', 'OK', {duration: 2000});
      }
    );
  }

  /**
   * データの取得
   *
   * @param id データのID
   */
  abstract fetch(id: number): Observable<T>;

  /**
   * データの保存
   *
   * @param data データ
   */
  abstract save(data: T): Observable<T | any>;
}
