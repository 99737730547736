import { GlobalMenuItem } from './interfaces/global-menu-item';

export const menuConfig: GlobalMenuItem[] = [
  { path: 'dashboard', title: 'ダッシュボード', icon: 'dashboard', iconColor: '#303f9f' },
  {
    path: 'building', title: '建物', icon: 'home', iconColor: '#ef5350',
    children: [
      { path: '', title: '建物一覧', caps: ['buildingRead'] },
      { path: 'new', title: '新規建物', caps: ['buildingEdit'] },
      { path: 'import', title: 'CSVインポート', caps: ['import'] }
    ]
  },
  {
    path: 'task/patrol', title: '巡回', icon: 'fa_eye', iconColor: '#9c27b0',
    children: [
      { path: '', title: '巡回実績一覧', caps: ['ptrRead'] },
      { path: 'annual-report', title: '年間巡回報告書一覧', caps: ['ptrAReportRead'] },
      { path: 'annual-report/new', title: '年間巡回報告書作成', caps: ['ptrAReportEdit'] }
    ]
  },
  {
    path: 'task/inspection', title: '診断', icon: 'fa_stethoscope', iconColor: '#e91e63',
    children: [
      { path: '', title: '診断実績一覧', caps: ['ispRead'] },
      { path: 'repair-plan', title: '長期修繕計画一覧', caps: ['ptrAReportEdit'] },
    ]
  },
  {
    path: 'branch', title: '店舗', caps: ['settings'], icon: 'business', iconColor: '#26a69a',
    children: [
      { path: '', title: '店舗一覧' },
      { path: 'new', title: '新規店舗' }
    ]
  },
  {
    path: 'user', title: 'ユーザー', caps: ['staff'], icon: 'person', iconColor: '#f9a825',
    children: [
      { path: '', title: 'ユーザー一覧' },
      { path: 'new', title: '新規ユーザー' },
      { path: 'group', title: '権限グループ' }
    ]
  },
  {
    path: 'settings', title: '設定', caps: ['settings'], icon: 'settings', iconColor: '#795548',
    children: [
      { path: 'check-item', title: 'チェック項目設定' },
      { path: 'repair-item', title: '修繕項目設定' },
      { path: 'equipment-type', title: '設備タイプ設定' },
      { path: 'operation', title: '運用設定' },
      { path: 'company-detail', title: '自社情報設定' }
    ]
  },
];
