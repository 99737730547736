import { Component, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { debounceTime, map, mergeMap, startWith, tap } from 'rxjs/operators';
import { Branch, BranchApi, Building, BuildingApi, FileInfo, FileInfoApi, InspectionApi, Room, RoomApi } from '../../../../loopback';
import { DetailPageBase } from '../../../classes/detail-page-base';
import { GenericDialogComponent } from '../../../dialogs/generic-dialog/generic-dialog.component';
import { OptionsService } from '../../../services/options.service';
import { RoleService } from '../../../services/role.service';
import { YubinBangoService } from '../../../services/yubin-bango.service';
import { romaToKatakana } from '../../../utils';
import { emailValidator, phoneJpValidator, postalJpValidator } from '../../../validators';

export const MONTH_PICKER_FORMATS = { display: { dateInput: 'YYYY年M月' } };

@Component({
  selector: 'bi-building-detail',
  templateUrl: './building-detail.component.html',
  styleUrls: ['./building-detail.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MONTH_PICKER_FORMATS }
  ]
})
export class BuildingDetailComponent extends DetailPageBase<Building> implements OnInit {

  backLink = '..';
  branches: Branch[] = [];
  files: FileInfo[] = [];
  checkedFiles: FileInfo[] = [];
  rooms: Room[] = [];
  checkedRooms: Room[] = [];

  formConfig = {
    id: [null],
    branchId: [null],
    staffId: [null],
    code: ['', Validators.required],
    name: ['', Validators.required],
    kana: [''],
    description: [''],
    zipCode: ['', postalJpValidator],
    address1: ['', Validators.required],
    address2: ['', Validators.required],
    builder: [''],
    dateBuilt: [null],
    dealingType: [null],
    ownerName: [''],
    ownerZipCode: ['', postalJpValidator],
    ownerAddress1: [''],
    ownerAddress2: [''],
    ownerPhone: ['', phoneJpValidator],
    ownerFax: ['', phoneJpValidator],
    ownerEmail: ['', emailValidator],
    status: [1],
    contactRoadE: [],
    contactRoadW: [],
    contactRoadS: [],
    contactRoadN: [],
    heightDiffE: [],
    heightDiffW: [],
    heightDiffS: [],
    heightDiffN: [],
    otherConditions: this.formBuilder.group({}),
    regionCharacteristic: this.formBuilder.group({}),
    buildingLandRatio: [],
    floorAreaRatio: [],
    otherRegurations: [],
    numUnits: [],
    lotArea: [],
    lotAreaRoadRatio: [],
    buildingArea: [],
    outerWallArea: [],
    totalArea: [],
    structure: [],
    format: [],
    seismicRetrofit: [],
    purpose: [],
    foundation: [],
    pile: [],
    pileOther: [],
    outerWall1: [],
    outerWall1Other: [],
    outerWall2: [],
    outerWall2Other: [],
    eavesSoffit: [],
    eavesSoffitOther: [],
    roof: [],
    roofOther: [],
    waterSystem: [],
    waterPipe: [],
    drainSystem: [],
    drainPipe: [],
    hotWaterSystem: [],
    hotWaterPipe: [],
    gasType: [],
    powerReceiving: [],
    airConditioner: [],
    fireAlarm: [],
    fireExtinguisher: [],
    fireExtinguisherDetail: [],
    broadcastEqp: [],
    broadcastEqpDetail: [],
    bikePark: [],
    bikeCapWithRoof: [],
    bikeCapNoRoof: [],
    carPark: [],
    carCapWithRoof: [],
    carCapNoRoof: [],
    motoPark: [],
    motoCapWithRoof: [],
    motoCapNoRoof: [],
    dateLastPatrol: [null],
    dateLastInspection: [null],
    dateNextPatrol: [null],
    dateNextInspection: [null]
  };

  validationMessages = {
    code: {
      required: '建物番号を入力してください。',
      unique: '建物番号が重複しています。'
    },
    name: {
      required: '名称を入力してください。'
    },
    zipCode: {
      postal: '建物の郵便番号が不正です。'
    },
    address1: {
      required: '所在地1を入力してください。'
    },
    address2: {
      required: '所在地2を入力してください。'
    },
    ownerZipCode: {
      postal: '所有者の郵便番号が不正です。'
    },
    ownerPhone: {
      phone: '所有者の電話番号が不正です。'
    },
    ownerFax: {
      phone: '所有者のファックス番号が不正です。'
    },
    ownerEmail: {
      email: '所有者のメールアドレスが不正です。'
    }
  };

  safeMapUrl: SafeResourceUrl;

  dateLastPatrol: Date|string;
  dateLastInspection: Date|string;

  nameInputBuffer: string[] = [];

  get isDirty() {
    return this.form.dirty && this.canEdit;
  }
  get canEdit() {
    return this.role.can('buildingEdit');
  }

  constructor(
    protected formBuilder: FormBuilder,
    protected route: ActivatedRoute,
    protected router: Router,
    protected titleService: Title,
    private sanitizer: DomSanitizer,
    protected snackBar: MatSnackBar,
    protected dialog: MatDialog,
    public opt: OptionsService,
    public yubinBango: YubinBangoService,
    private role: RoleService,
    private buildingApi: BuildingApi,
    private branchApi: BranchApi,
    private fileInfoApi: FileInfoApi,
    private roomApi: RoomApi,
    private inspectionApi: InspectionApi
  ) {
    super();
  }

  ngOnInit() {
    this.branchApi.find().subscribe(
      branches => this.branches = branches
    );

    this.init().subscribe();
  }

  beforeFetch(params: {[key: string]: any}) {
    const props = ['otherConditions', 'regionCharacteristic'] as const;
    props.forEach(prop => {
      this.opt.buildingOptions[prop].forEach(option => {
        (this.form.get(prop) as FormGroup).setControl(option.value.toString(), this.formBuilder.control(''));
      });
    });

    this.initMapChanger();

    if (!params['id']) {
      this.setTitle('建物新規登録');
    }

    if (params['from']) {
      this.backLink = params['from'];
    }

    const uniqueCodeValidator: AsyncValidatorFn = (control: AbstractControl) => {
      const cond = {code: control.value};

      if (params['id']) {
        cond['id'] = {neq: +params['id']};
      }

      return this.buildingApi.count(cond).pipe(
        map(res => res.count > 0 ? {unique: true} : null)
      );
    };

    this.form.get('code').setAsyncValidators(uniqueCodeValidator);
  }

  fetch(id: number) {
    return this.buildingApi.findById(id, {include: ['rooms', 'files']});
  }

  save(data: Building): Observable<Building> {
    if (data.id) {
      return this.buildingApi.patchAttributes(data.id, data);
    } else {
      return this.buildingApi.create(data);
    }
  }

  setFormValue(value: Building) {
    if (value.dateLastPatrol) {
      this.dateLastPatrol = value.dateLastPatrol;
    }

    if (value.dateLastInspection) {
      this.dateLastInspection = value.dateLastInspection;
    }

    if (value.rooms) {
      this.rooms = value.rooms;
    }
    if (value.files) {
      this.files = value.files.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1);
    }

    for (const prop of ['dateNextPatrol', 'dateNextInspection']) {
      if (value[prop] instanceof Date) {
        value[prop] = value[prop].toISOString();
      }
    }
    value.otherConditions = this.opt.sanitizeMultiselectObject('buildingOptions.otherConditions', value.otherConditions);
    value.regionCharacteristic = this.opt.sanitizeMultiselectObject('buildingOptions.regionCharacteristic', value.regionCharacteristic);

    super.setFormValue(value);
  }

  initMapChanger() {
    const adr1 = this.form.get('address1');
    const adr2 = this.form.get('address2');

    combineLatest([
      adr1.valueChanges.pipe(startWith(adr1.value)),
      adr2.valueChanges.pipe(startWith(adr2.value)),
    ]).pipe(
      map(val => val[0] + val[1]),
      debounceTime(500)
    ).subscribe(val => this.setMapUrl(val));
  }

  setMapUrl(address: string) {
    const mapUrl = 'https://maps.google.co.jp/maps?q=' + address + '&iwloc=B&t=m&output=embed';

    this.safeMapUrl = this.sanitizer.bypassSecurityTrustResourceUrl(mapUrl);
  }

  onFileSelect(e: Event) {
    const input = e.target as HTMLInputElement;
    const file = input.files.item(0);

    this.uploadAttachment(file).subscribe();
  }

  onChangeFileCheckbox(fileInfo: FileInfo, e: MatCheckboxChange) {
    if (e.checked) {
      this.checkedFiles.push(fileInfo);
    } else {
      const index = this.checkedFiles.indexOf(fileInfo);

      if (index !== -1) {
        this.checkedFiles.splice(index, 1);
      }
    }
  }

  onChangeRoomCheckbox(room: Room, e: MatCheckboxChange) {
    if (e.checked) {
      this.checkedRooms.push(room);
    } else {
      const index = this.checkedRooms.indexOf(room);

      if (index !== -1) {
        this.checkedRooms.splice(index, 1);
      }
    }
  }

  onDeleteFilesClick() {
    this.dialog.open(GenericDialogComponent, {
      data: {
        type: 'confirm',
        title: '添付資料の削除',
        message: 'チェックした添付資料を削除します。この操作はもとに戻すことができません。続行してよろしいですか?',
        okButtonColor: 'warn'
      },
      width: '480px'
    }).afterClosed().pipe(
      mergeMap(ok => ok ? this.deleteAttachments() : of(null))
    ).subscribe();
  }

  onDeleteRoomsClick() {
    this.dialog.open(GenericDialogComponent, {
      data: {
        type: 'confirm',
        title: '部屋の削除',
        message: 'チェックした部屋を削除します。この操作はもとに戻すことができません。続行してよろしいですか?',
        okButtonColor: 'warn'
      },
      width: '480px'
    }).afterClosed().pipe(
      mergeMap(ok => ok ? this.deleteRooms() : of(null))
    ).subscribe();
  }

  onDateSelected(date: moment.Moment, controlName: string, picker: MatDatepicker<moment.Moment>) {
    const control = this.form.get(controlName);
    control.setValue(date);
    control.markAsTouched();
    control.markAsDirty();
    picker.close();
  }

  uploadAttachment(file: File) {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('description', file.name);

    return this.fileInfoApi.upload(formData, `building-${this.id}`).pipe(
      mergeMap(res => this.buildingApi.linkFiles(this.id, res.result.id)),
      tap(fileInfo => this.files.unshift(fileInfo))
    );
  }

  deleteAttachments() {
    return forkJoin(
      this.checkedFiles.map(fileInfo => this.buildingApi.destroyByIdFiles(this.id, fileInfo.id).pipe(
          tap(res => {
            const index = this.files.indexOf(fileInfo);
            const sindex = this.checkedFiles.indexOf(fileInfo);

            if (index !== -1) {
              this.files.splice(index, 1);
            }
            if (sindex !== -1) {
              this.checkedFiles.splice(sindex, 1);
            }
          })
        ))
    );
  }

  deleteRooms() {
    return forkJoin(
      this.checkedRooms.map(room => this.inspectionApi.count({roomId: room.id}).pipe(
          map(res => res.count === 0),
          mergeMap(deletable => {
            if (!deletable) {
              return of(null);
            }

            return this.roomApi.deleteEquipments(room.id).pipe(
              mergeMap(del => this.buildingApi.destroyByIdRooms(this.id, room.id)),
              tap(res => {
                const index = this.rooms.indexOf(room);
                const sindex = this.checkedRooms.indexOf(room);

                if (index !== -1) {
                  this.rooms.splice(index, 1);
                }
                if (sindex !== -1) {
                  this.checkedRooms.splice(sindex, 1);
                }
              })
            );
          })
        ))
    );
  }

  updateKana() {
    const kana = this.form.get('kana');
    const allowedControlKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
    let result = '';
    let pointer = 0;

    for (const input of this.nameInputBuffer) {
      if (allowedControlKeys.indexOf(input) === -1) {
        result += romaToKatakana(input);
        pointer = result.length;
      } else if (input === 'Backspace' && pointer !== 0) {
        result = result.slice(0, pointer - 1) + result.slice(pointer);
        pointer -= 1;
      } else if (input === 'Delete' && pointer !== result.length) {
        result = result.slice(0, pointer) + result.slice(pointer + 1);
      } else if (input === 'ArrowLeft' && pointer !== 0) {
        pointer -= 1;
      } else if (input === 'ArrowRight' && pointer !== result.length) {
        pointer += 1;
      }
    }

    this.nameInputBuffer = [];
    kana.setValue(kana.value + result);
  }

  onInputName(e: KeyboardEvent) {
    const allowedControlKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];

    if (e.isComposing) {
      if (e.key === ' ') {
        // this.updateKana();
      } else if (e.key.length === 1) {
        if (this.nameInputBuffer.length === 0 || allowedControlKeys.indexOf(this.nameInputBuffer[this.nameInputBuffer.length - 1]) !== -1) {
          this.nameInputBuffer.push(e.key);
        } else {
          this.nameInputBuffer[this.nameInputBuffer.length - 1] += e.key;
        }
      } else if (allowedControlKeys.indexOf(e.key) !== -1) {
        this.nameInputBuffer.push(e.key);
      }
    }
  }

  onNameCompositionEnd(e: Event) {
    if (e['data'] !== '') {
      this.updateKana();
    } else {
      this.nameInputBuffer = [];
    }
  }
}
