import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { RoleService } from '../services/role.service';

@Injectable()
export class AuthRequiredGuard implements CanActivate, CanActivateChild {

  constructor(
    private role: RoleService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.roleHasCaps(route.data['caps']);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.roleHasCaps(route.data['caps']);
  }

  roleHasCaps(caps: string[] = []): boolean {
    return this.role.canEvery(caps);
  }
}
