/* eslint-disable */
import {
  Company,
  Patrol,
  CheckItem,
  Staff,
  FileInfo
} from '../index';

declare var Object: any;
export interface PatrolDetailInterface {
  "isNotExist"?: boolean;
  "isUnable"?: boolean;
  "rating"?: number;
  "issue"?: string;
  "issueText"?: string;
  "proposal"?: string;
  "proposalText"?: string;
  "reportPhotoIds"?: Array<any>;
  "id"?: number;
  "companyId"?: number;
  "patrolId"?: number;
  "checkItemId"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId"?: number;
  "photoIds"?: Array<any>;
  company?: Company;
  patrol?: Patrol;
  checkItem?: CheckItem;
  updater?: Staff;
  photos?: FileInfo[];
}

export class PatrolDetail implements PatrolDetailInterface {
  "isNotExist": boolean;
  "isUnable": boolean;
  "rating": number;
  "issue": string;
  "issueText": string;
  "proposal": string;
  "proposalText": string;
  "reportPhotoIds": Array<any>;
  "id": number;
  "companyId": number;
  "patrolId": number;
  "checkItemId": number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId": number;
  "photoIds": Array<any>;
  company?: Company;
  patrol?: Patrol;
  checkItem?: CheckItem;
  updater?: Staff;
  photos?: FileInfo[];
  constructor(data?: Partial<PatrolDetailInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `PatrolDetail`.
   */
  public static getModelName() {
    return "PatrolDetail";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of PatrolDetail for dynamic purposes.
  **/
  public static factory(data: PatrolDetailInterface): PatrolDetail{
    return new PatrolDetail(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'PatrolDetail',
      plural: 'PatrolDetails',
      path: 'PatrolDetails',
      idName: 'id',
      properties: {
        "isNotExist": {
          name: 'isNotExist',
          type: 'boolean'
        },
        "isUnable": {
          name: 'isUnable',
          type: 'boolean'
        },
        "rating": {
          name: 'rating',
          type: 'number'
        },
        "issue": {
          name: 'issue',
          type: 'string'
        },
        "issueText": {
          name: 'issueText',
          type: 'string'
        },
        "proposal": {
          name: 'proposal',
          type: 'string'
        },
        "proposalText": {
          name: 'proposalText',
          type: 'string'
        },
        "reportPhotoIds": {
          name: 'reportPhotoIds',
          type: 'Array&lt;any&gt;'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "patrolId": {
          name: 'patrolId',
          type: 'number'
        },
        "checkItemId": {
          name: 'checkItemId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
        "photoIds": {
          name: 'photoIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        patrol: {
          name: 'patrol',
          type: 'Patrol',
          model: 'Patrol',
          relationType: 'belongsTo',
                  keyFrom: 'patrolId',
          keyTo: 'id'
        },
        checkItem: {
          name: 'checkItem',
          type: 'CheckItem',
          model: 'CheckItem',
          relationType: 'belongsTo',
                  keyFrom: 'checkItemId',
          keyTo: 'id'
        },
        updater: {
          name: 'updater',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'updaterId',
          keyTo: 'id'
        },
        photos: {
          name: 'photos',
          type: 'FileInfo[]',
          model: 'FileInfo',
          relationType: 'referencesMany',
                  keyFrom: 'photoIds',
          keyTo: 'id'
        },
      }
    }
  }
}
