/* eslint-disable */
import {
  Company,
  Building,
  Staff,
  Equipment
} from '../index';

declare var Object: any;
export interface RoomInterface {
  "name": string;
  "status": number;
  "id"?: number;
  "companyId"?: number;
  "buildingId"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId"?: number;
  company?: Company;
  building?: Building;
  updater?: Staff;
  equipments?: Equipment[];
}

export class Room implements RoomInterface {
  "name": string;
  "status": number;
  "id": number;
  "companyId": number;
  "buildingId": number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId": number;
  company?: Company;
  building?: Building;
  updater?: Staff;
  equipments?: Equipment[];
  constructor(data?: Partial<RoomInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Room`.
   */
  public static getModelName() {
    return "Room";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Room for dynamic purposes.
  **/
  public static factory(data: RoomInterface): Room{
    return new Room(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Room',
      plural: 'Rooms',
      path: 'Rooms',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 1
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "buildingId": {
          name: 'buildingId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        building: {
          name: 'building',
          type: 'Building',
          model: 'Building',
          relationType: 'belongsTo',
                  keyFrom: 'buildingId',
          keyTo: 'id'
        },
        updater: {
          name: 'updater',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'updaterId',
          keyTo: 'id'
        },
        equipments: {
          name: 'equipments',
          type: 'Equipment[]',
          model: 'Equipment',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'roomId'
        },
      }
    }
  }
}
