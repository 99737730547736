/* eslint-disable */
import {
  Company,
  CheckItem,
  FileInfo,
  Staff
} from '../index';

declare var Object: any;
export interface ConditionTemplateInterface {
  "name": string;
  "order"?: number;
  "deterioration"?: number;
  "urgency"?: number;
  "reason"?: string;
  "measure"?: string;
  "id"?: number;
  "companyId"?: number;
  "checkItemId"?: number;
  "photoId"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId"?: number;
  company?: Company;
  checkItem?: CheckItem;
  photo?: FileInfo;
  updater?: Staff;
}

export class ConditionTemplate implements ConditionTemplateInterface {
  "name": string;
  "order": number;
  "deterioration": number;
  "urgency": number;
  "reason": string;
  "measure": string;
  "id": number;
  "companyId": number;
  "checkItemId": number;
  "photoId": number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId": number;
  company?: Company;
  checkItem?: CheckItem;
  photo?: FileInfo;
  updater?: Staff;
  constructor(data?: Partial<ConditionTemplateInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ConditionTemplate`.
   */
  public static getModelName() {
    return "ConditionTemplate";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ConditionTemplate for dynamic purposes.
  **/
  public static factory(data: ConditionTemplateInterface): ConditionTemplate{
    return new ConditionTemplate(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ConditionTemplate',
      plural: 'ConditionTemplates',
      path: 'ConditionTemplates',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "order": {
          name: 'order',
          type: 'number'
        },
        "deterioration": {
          name: 'deterioration',
          type: 'number'
        },
        "urgency": {
          name: 'urgency',
          type: 'number'
        },
        "reason": {
          name: 'reason',
          type: 'string'
        },
        "measure": {
          name: 'measure',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "checkItemId": {
          name: 'checkItemId',
          type: 'number'
        },
        "photoId": {
          name: 'photoId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        checkItem: {
          name: 'checkItem',
          type: 'CheckItem',
          model: 'CheckItem',
          relationType: 'belongsTo',
                  keyFrom: 'checkItemId',
          keyTo: 'id'
        },
        photo: {
          name: 'photo',
          type: 'FileInfo',
          model: 'FileInfo',
          relationType: 'belongsTo',
                  keyFrom: 'photoId',
          keyTo: 'id'
        },
        updater: {
          name: 'updater',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'updaterId',
          keyTo: 'id'
        },
      }
    }
  }
}
