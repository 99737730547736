import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { PageBase } from '../../classes/page-base';

@Component({
  selector: 'bi-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends PageBase {

  title = 'ダッシュボード';

  constructor(
    protected titleService: Title
  ) {
    super();
  }

}
