import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { FileInfoApi, SDKModels, LoopBackAuth, ErrorHandler, LoopBackConfig } from '../../loopback';
import { Observable } from 'rxjs';
import { map, catchError, filter } from 'rxjs/operators';

/**
 * ファイル情報
 * uploadメソッド置き換え用クラス
 */
@Injectable()
export class FileInfoApiOverrideService extends FileInfoApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http, models, auth, errorHandler);
  }
  /**
   * ファイルアップロード
   *
   * @param data Request data.
   *
   *  - `form` – `{FormData}` -
   *
   * @returns An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ImportHistory` object.)
   * </em>
   */
  public upload(form: FormData, container?: string): Observable<any> {
    const _method = 'POST';
    let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
    '/FileInfos/upload';
    if (container) {
      _url += `/${container}`;
    }
    let headers = new HttpHeaders();
    headers = this.authenticate(_url, headers);
    const request = new HttpRequest(_method, _url, form, {headers});
    return this.http.request(request).pipe(
      filter(event => event instanceof HttpResponse),
      map((res: HttpResponse<any>) => res.body),
      catchError((e) => this.errorHandler.handleError(e))
    );
  }
}
