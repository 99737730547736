/* eslint-disable */
import {
  Company,
  Building,
  Staff,
  Inspection,
  FileInfo,
  RepairPlanWork
} from '../index';

declare var Object: any;
export interface RepairPlanInterface {
  "status": number;
  "approvalStatus": number;
  "approvedAt"?: Date|string;
  "ageCountType"?: number;
  "period"?: number;
  "startYear"?: number;
  "initialDebt"?: number;
  "initialFund"?: number;
  "depositPerMonth"?: number;
  "transferPerMonthExclusive"?: number;
  "transferPerMonthParking"?: number;
  "transferPerMonthOther"?: number;
  "coverPhotoId"?: number;
  "id"?: number;
  "companyId"?: number;
  "buildingId"?: number;
  "staffId"?: number;
  "inspectionId"?: number;
  "coverPhotoIds"?: Array<any>;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId"?: number;
  company?: Company;
  building?: Building;
  staff?: Staff;
  inspection?: Inspection;
  coverPhotos?: FileInfo[];
  updater?: Staff;
  works?: RepairPlanWork[];
}

export class RepairPlan implements RepairPlanInterface {
  "status": number;
  "approvalStatus": number;
  "approvedAt": Date|string;
  "ageCountType": number;
  "period": number;
  "startYear": number;
  "initialDebt": number;
  "initialFund": number;
  "depositPerMonth": number;
  "transferPerMonthExclusive": number;
  "transferPerMonthParking": number;
  "transferPerMonthOther": number;
  "coverPhotoId": number;
  "id": number;
  "companyId": number;
  "buildingId": number;
  "staffId": number;
  "inspectionId": number;
  "coverPhotoIds": Array<any>;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId": number;
  company?: Company;
  building?: Building;
  staff?: Staff;
  inspection?: Inspection;
  coverPhotos?: FileInfo[];
  updater?: Staff;
  works?: RepairPlanWork[];
  constructor(data?: Partial<RepairPlanInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `RepairPlan`.
   */
  public static getModelName() {
    return "RepairPlan";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of RepairPlan for dynamic purposes.
  **/
  public static factory(data: RepairPlanInterface): RepairPlan{
    return new RepairPlan(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'RepairPlan',
      plural: 'RepairPlans',
      path: 'RepairPlans',
      idName: 'id',
      properties: {
        "status": {
          name: 'status',
          type: 'number',
          default: 1
        },
        "approvalStatus": {
          name: 'approvalStatus',
          type: 'number',
          default: 1
        },
        "approvedAt": {
          name: 'approvedAt',
          type: 'Date|string'
        },
        "ageCountType": {
          name: 'ageCountType',
          type: 'number'
        },
        "period": {
          name: 'period',
          type: 'number'
        },
        "startYear": {
          name: 'startYear',
          type: 'number'
        },
        "initialDebt": {
          name: 'initialDebt',
          type: 'number'
        },
        "initialFund": {
          name: 'initialFund',
          type: 'number'
        },
        "depositPerMonth": {
          name: 'depositPerMonth',
          type: 'number'
        },
        "transferPerMonthExclusive": {
          name: 'transferPerMonthExclusive',
          type: 'number'
        },
        "transferPerMonthParking": {
          name: 'transferPerMonthParking',
          type: 'number'
        },
        "transferPerMonthOther": {
          name: 'transferPerMonthOther',
          type: 'number'
        },
        "coverPhotoId": {
          name: 'coverPhotoId',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "buildingId": {
          name: 'buildingId',
          type: 'number'
        },
        "staffId": {
          name: 'staffId',
          type: 'number'
        },
        "inspectionId": {
          name: 'inspectionId',
          type: 'number'
        },
        "coverPhotoIds": {
          name: 'coverPhotoIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        building: {
          name: 'building',
          type: 'Building',
          model: 'Building',
          relationType: 'belongsTo',
                  keyFrom: 'buildingId',
          keyTo: 'id'
        },
        staff: {
          name: 'staff',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'staffId',
          keyTo: 'id'
        },
        inspection: {
          name: 'inspection',
          type: 'Inspection',
          model: 'Inspection',
          relationType: 'belongsTo',
                  keyFrom: 'inspectionId',
          keyTo: 'id'
        },
        coverPhotos: {
          name: 'coverPhotos',
          type: 'FileInfo[]',
          model: 'FileInfo',
          relationType: 'referencesMany',
                  keyFrom: 'coverPhotoIds',
          keyTo: 'id'
        },
        updater: {
          name: 'updater',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'updaterId',
          keyTo: 'id'
        },
        works: {
          name: 'works',
          type: 'RepairPlanWork[]',
          model: 'RepairPlanWork',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'planId'
        },
      }
    }
  }
}
