import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InspectionDetail } from 'loopback';

@Component({
  selector: 'bi-related-inspection-view',
  templateUrl: './related-inspection-view.component.html',
  styleUrls: ['./related-inspection-view.component.scss']
})
export class RelatedInspectionViewComponent implements OnInit {

  details: InspectionDetail[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    for (const id of this.data.checkItemIds) {
      const detail = this.data.details.find(d => d.checkItemId === id);
      if (detail && !detail.isNotExist && !detail.isUnable) {
        this.details.push(detail);
      }
    }
  }

}
