import { Component, Input, Output, HostBinding, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { GlobalMenuItem } from '../../interfaces/global-menu-item';

/**
 * メニュー項目
 */
@Component({
  selector: 'bi-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent {
  /**
   * メニュー項目
   */
  @Input() menuItem: GlobalMenuItem;

  /**
   * 子アイテムフラグ
   */
  @Input() child = false;

  @HostBinding('class.selected') get cSelected() {
 return this.menuItem.selected && !this.menuItem.expanded;
}
  @HostBinding('class.expanded') get cExpanded() {
 return this.menuItem.expanded;
}
  @HostBinding('class.subitem') get cSubitem() {
 return this.child;
}
  @HostBinding('class.haschildren') get cHaschildren() {
 return this.menuItem.children;
}

  constructor(private router: Router) {}

  /**
   * メニューアイテムクリックハンドラ
   *
   * @param event
   */
  onItemClick(event: MouseEvent): boolean {
    this.menuItem.children ? this.toggleSubMenu() : this.navigate();

    return false;
  }

  /**
   * 子メニューの開閉
   */
  toggleSubMenu() {
    this.menuItem.expanded = !this.menuItem.expanded;
  }

  /**
   * メニューリンクの処理
   * urlプロパティがある場合は新しいウィンドウでURLを開く
   */
  private navigate() {
    if (this.menuItem.url) {
      window.open(this.menuItem.url, this.menuItem.target);
    } else {
      this.router.navigate(this.menuItem.paths);
    }
  }

}
