import { Component, OnInit, Inject, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { OptionApi } from '../../../loopback';

@Component({
  selector: 'bi-par-detail-view',
  templateUrl: './par-detail-view.component.html',
  styleUrls: ['./par-detail-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PARDetailViewComponent implements OnInit {

  ratingLabels: any = {};

  constructor(
    private cdRef: ChangeDetectorRef,
    private ref: MatDialogRef<PARDetailViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private optionApi: OptionApi
  ) {}

  ngOnInit() {
    this.optionApi.findOne({where: {name: 'patrolRatingLabel'}}).subscribe(
      res => {
        this.ratingLabels = res.value;
        this.cdRef.markForCheck();
      }
    );
  }
}
