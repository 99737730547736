/* eslint-disable */
import {
  Company
} from '../index';

declare var Object: any;
export interface FileInfoInterface {
  "name"?: string;
  "description"?: string;
  "type"?: string;
  "subtype"?: string;
  "size"?: number;
  "container": string;
  "filename": string;
  "id"?: number;
  "companyId"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  company?: Company;
}

export class FileInfo implements FileInfoInterface {
  "name": string;
  "description": string;
  "type": string;
  "subtype": string;
  "size": number;
  "container": string;
  "filename": string;
  "id": number;
  "companyId": number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  company?: Company;
  constructor(data?: Partial<FileInfoInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `FileInfo`.
   */
  public static getModelName() {
    return "FileInfo";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of FileInfo for dynamic purposes.
  **/
  public static factory(data: FileInfoInterface): FileInfo{
    return new FileInfo(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'FileInfo',
      plural: 'FileInfos',
      path: 'FileInfos',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "subtype": {
          name: 'subtype',
          type: 'string'
        },
        "size": {
          name: 'size',
          type: 'number'
        },
        "container": {
          name: 'container',
          type: 'string'
        },
        "filename": {
          name: 'filename',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
      }
    }
  }
}
