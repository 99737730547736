/* eslint-disable */
import {
  Company,
  Staff
} from '../index';

declare var Object: any;
export interface RoleGroupInterface {
  "name"?: string;
  "roleBuildingRead"?: boolean;
  "roleBuildingEdit"?: boolean;
  "roleStaff"?: boolean;
  "roleImport"?: boolean;
  "roleSettings"?: boolean;
  "rolePtrRead"?: boolean;
  "rolePtrEdit"?: boolean;
  "rolePtrApprove"?: boolean;
  "rolePtrAReportRead"?: boolean;
  "rolePtrAReportEdit"?: boolean;
  "rolePtrAReportCreate"?: boolean;
  "rolePtrAReportApprove"?: boolean;
  "roleIspRead"?: boolean;
  "roleIspEdit"?: boolean;
  "roleIspApprove"?: boolean;
  "roleIspReportRead"?: boolean;
  "roleIspReportEdit"?: boolean;
  "roleIspReportCreate"?: boolean;
  "roleIspReportApprove"?: boolean;
  "roleTablet"?: boolean;
  "id"?: number;
  "companyId"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId"?: number;
  company?: Company;
  updater?: Staff;
}

export class RoleGroup implements RoleGroupInterface {
  "name": string;
  "roleBuildingRead": boolean;
  "roleBuildingEdit": boolean;
  "roleStaff": boolean;
  "roleImport": boolean;
  "roleSettings": boolean;
  "rolePtrRead": boolean;
  "rolePtrEdit": boolean;
  "rolePtrApprove": boolean;
  "rolePtrAReportRead": boolean;
  "rolePtrAReportEdit": boolean;
  "rolePtrAReportCreate": boolean;
  "rolePtrAReportApprove": boolean;
  "roleIspRead": boolean;
  "roleIspEdit": boolean;
  "roleIspApprove": boolean;
  "roleIspReportRead": boolean;
  "roleIspReportEdit": boolean;
  "roleIspReportCreate": boolean;
  "roleIspReportApprove": boolean;
  "roleTablet": boolean;
  "id": number;
  "companyId": number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId": number;
  company?: Company;
  updater?: Staff;
  constructor(data?: Partial<RoleGroupInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `RoleGroup`.
   */
  public static getModelName() {
    return "RoleGroup";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of RoleGroup for dynamic purposes.
  **/
  public static factory(data: RoleGroupInterface): RoleGroup{
    return new RoleGroup(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'RoleGroup',
      plural: 'RoleGroups',
      path: 'RoleGroups',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "roleBuildingRead": {
          name: 'roleBuildingRead',
          type: 'boolean'
        },
        "roleBuildingEdit": {
          name: 'roleBuildingEdit',
          type: 'boolean'
        },
        "roleStaff": {
          name: 'roleStaff',
          type: 'boolean'
        },
        "roleImport": {
          name: 'roleImport',
          type: 'boolean'
        },
        "roleSettings": {
          name: 'roleSettings',
          type: 'boolean'
        },
        "rolePtrRead": {
          name: 'rolePtrRead',
          type: 'boolean'
        },
        "rolePtrEdit": {
          name: 'rolePtrEdit',
          type: 'boolean'
        },
        "rolePtrApprove": {
          name: 'rolePtrApprove',
          type: 'boolean'
        },
        "rolePtrAReportRead": {
          name: 'rolePtrAReportRead',
          type: 'boolean'
        },
        "rolePtrAReportEdit": {
          name: 'rolePtrAReportEdit',
          type: 'boolean'
        },
        "rolePtrAReportCreate": {
          name: 'rolePtrAReportCreate',
          type: 'boolean'
        },
        "rolePtrAReportApprove": {
          name: 'rolePtrAReportApprove',
          type: 'boolean'
        },
        "roleIspRead": {
          name: 'roleIspRead',
          type: 'boolean'
        },
        "roleIspEdit": {
          name: 'roleIspEdit',
          type: 'boolean'
        },
        "roleIspApprove": {
          name: 'roleIspApprove',
          type: 'boolean'
        },
        "roleIspReportRead": {
          name: 'roleIspReportRead',
          type: 'boolean'
        },
        "roleIspReportEdit": {
          name: 'roleIspReportEdit',
          type: 'boolean'
        },
        "roleIspReportCreate": {
          name: 'roleIspReportCreate',
          type: 'boolean'
        },
        "roleIspReportApprove": {
          name: 'roleIspReportApprove',
          type: 'boolean'
        },
        "roleTablet": {
          name: 'roleTablet',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        updater: {
          name: 'updater',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'updaterId',
          keyTo: 'id'
        },
      }
    }
  }
}
