/* eslint-disable */
import {
  Company,
  Branch,
  RoleGroup
} from '../index';

declare var Object: any;
export interface StaffInterface {
  "username": string;
  "displayName"?: string;
  "email"?: string;
  "dateRetired"?: Date|string;
  "position"?: string;
  "license"?: string;
  "status": number;
  "realm"?: string;
  "emailVerified"?: boolean;
  "id"?: number;
  "companyId"?: number;
  "branchId"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId"?: number;
  "roleGroupId"?: number;
  "password"?: string;
  accessTokens?: any[];
  company?: Company;
  branch?: Branch;
  updater?: Staff;
  roleGroup?: RoleGroup;
}

export class Staff implements StaffInterface {
  "username": string;
  "displayName": string;
  "email": string;
  "dateRetired": Date|string;
  "position": string;
  "license": string;
  "status": number;
  "realm": string;
  "emailVerified": boolean;
  "id": number;
  "companyId": number;
  "branchId": number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId": number;
  "roleGroupId": number;
  "password": string;
  accessTokens?: any[];
  company?: Company;
  branch?: Branch;
  updater?: Staff;
  roleGroup?: RoleGroup;
  constructor(data?: Partial<StaffInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Staff`.
   */
  public static getModelName() {
    return "Staff";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Staff for dynamic purposes.
  **/
  public static factory(data: StaffInterface): Staff{
    return new Staff(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Staff',
      plural: 'Staffs',
      path: 'Staffs',
      idName: 'id',
      properties: {
        "username": {
          name: 'username',
          type: 'string'
        },
        "displayName": {
          name: 'displayName',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "dateRetired": {
          name: 'dateRetired',
          type: 'Date|string'
        },
        "position": {
          name: 'position',
          type: 'string'
        },
        "license": {
          name: 'license',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 1
        },
        "realm": {
          name: 'realm',
          type: 'string'
        },
        "emailVerified": {
          name: 'emailVerified',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "branchId": {
          name: 'branchId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
        "roleGroupId": {
          name: 'roleGroupId',
          type: 'number'
        },
        "password": {
          name: 'password',
          type: 'string'
        },
      },
      relations: {
        accessTokens: {
          name: 'accessTokens',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        branch: {
          name: 'branch',
          type: 'Branch',
          model: 'Branch',
          relationType: 'belongsTo',
                  keyFrom: 'branchId',
          keyTo: 'id'
        },
        updater: {
          name: 'updater',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'updaterId',
          keyTo: 'id'
        },
        roleGroup: {
          name: 'roleGroup',
          type: 'RoleGroup',
          model: 'RoleGroup',
          relationType: 'belongsTo',
                  keyFrom: 'roleGroupId',
          keyTo: 'id'
        },
      }
    }
  }
}
