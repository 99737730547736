/* eslint-disable */
import {
  Company,
  Staff
} from '../index';

declare var Object: any;
export interface EquipmentTypeInterface {
  "name": string;
  "order"?: number;
  "manufacturers"?: Array<any>;
  "id"?: number;
  "companyId"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId"?: number;
  company?: Company;
  updater?: Staff;
}

export class EquipmentType implements EquipmentTypeInterface {
  "name": string;
  "order": number;
  "manufacturers": Array<any>;
  "id": number;
  "companyId": number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId": number;
  company?: Company;
  updater?: Staff;
  constructor(data?: Partial<EquipmentTypeInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `EquipmentType`.
   */
  public static getModelName() {
    return "EquipmentType";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of EquipmentType for dynamic purposes.
  **/
  public static factory(data: EquipmentTypeInterface): EquipmentType{
    return new EquipmentType(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'EquipmentType',
      plural: 'EquipmentTypes',
      path: 'EquipmentTypes',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "order": {
          name: 'order',
          type: 'number'
        },
        "manufacturers": {
          name: 'manufacturers',
          type: 'Array&lt;any&gt;'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        updater: {
          name: 'updater',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'updaterId',
          keyTo: 'id'
        },
      }
    }
  }
}
