import { Component, OnInit, Inject, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { ImportHistoryApi } from '../../../loopback';

/**
 * CSVインポートの確認用ダイアログ
 */
@Component({
  selector: 'bi-building-import-confirm',
  templateUrl: './building-import-confirm.component.html',
  styleUrls: ['./building-import-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuildingImportConfirmComponent implements OnInit {

  result: any;

  private checkRequest: Subscription;

  constructor(
    private cdRef: ChangeDetectorRef,
    private ref: MatDialogRef<BuildingImportConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private importApi: ImportHistoryApi
  ) {}

  ngOnInit() {
    const file = this.data.file;
    const checkForm = new FormData();
    checkForm.append('file', file);
    checkForm.append('check', 'true');

    this.checkRequest = this.importApi.importCsv(checkForm).subscribe(
      res => {
        this.result = res;
        this.cdRef.markForCheck();
      }
    );
  }

  onCancelClick() {
    this.checkRequest.unsubscribe();
    this.ref.close(false);
  }

  onOkClick() {
    this.ref.close(true);
  }

}
