import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { OptionsService, OptionItem } from '../../services/options.service';
import { FileInfoService } from '../../services/file-info.service';
import { ConditionTemplate } from '../../../loopback';
import { ImageSelectorSelectEvent } from 'app/components/image-selector/image-selector.component';

@Component({
  selector: 'bi-condition-template-editor',
  templateUrl: './condition-template-editor.component.html',
  styleUrls: ['./condition-template-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConditionTemplateEditorComponent implements OnInit {

  title = '';
  deteriorations = [1, 2, 3, 4, 5];
  urgencies: OptionItem[];
  form: FormGroup<{
    id: FormControl<number>;
    name: FormControl<string>;
    order: FormControl<number>;
    deterioration: FormControl<number>;
    urgency: FormControl<number>;
    reason: FormControl<string>;
    measure: FormControl<string>;
    photoId: FormControl<number>;
  }>;

  file: ImageSelectorSelectEvent;
  photoUrl: string;
  willDeleteFile = false;

  constructor(
    private formBuilder: FormBuilder,
    private ref: MatDialogRef<ConditionTemplateEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConditionTemplate,
    private opt: OptionsService,
    private fileInfo: FileInfoService
  ) {}

  ngOnInit() {
    this.urgencies = this.opt.inspectionUrgency;
    this.form = this.formBuilder.group({
      id: [this.data.id],
      name: [this.data.name, Validators.required],
      order: [this.data.order],
      deterioration: [this.data.deterioration],
      urgency: [this.data.urgency],
      reason: [this.data.reason],
      measure: [this.data.measure],
      photoId: [this.data.photoId]
    });

    this.title = this.data.id ? this.data.name : '新規作成';

    if (this.data.photo) {
      this.photoUrl = this.fileInfo.getUrl(this.data.photo);
    }
  }

  onSelectImage(e: ImageSelectorSelectEvent) {
    this.file = e;
    if (this.data.photo) {
      this.willDeleteFile = true;
    }
  }

  onResetImage() {
    this.willDeleteFile = false;
  }

  onOkClick() {
    const template = new ConditionTemplate(this.form.value);
    const willDeleteFile = this.willDeleteFile;
    const file = this.file;

    this.ref.close({
      template,
      file,
      willDeleteFile
    });
  }
}
