/* eslint-disable */
import {
  Company,
  Branch,
  Staff,
  CheckItem,
  Patrol,
  FileInfo,
  Room,
  Inspection
} from '../index';

declare var Object: any;
export interface BuildingInterface {
  "code"?: string;
  "name"?: string;
  "kana"?: string;
  "description"?: string;
  "zipCode"?: string;
  "address1"?: string;
  "address2"?: string;
  "dealingType"?: number;
  "builder"?: string;
  "dateBuilt"?: Date|string;
  "ownerName"?: string;
  "ownerZipCode"?: string;
  "ownerAddress1"?: string;
  "ownerAddress2"?: string;
  "ownerPhone"?: string;
  "ownerFax"?: string;
  "ownerEmail"?: string;
  "contactRoadE"?: number;
  "contactRoadW"?: number;
  "contactRoadS"?: number;
  "contactRoadN"?: number;
  "heightDiffE"?: number;
  "heightDiffW"?: number;
  "heightDiffS"?: number;
  "heightDiffN"?: number;
  "otherConditions"?: any;
  "regionCharacteristic"?: any;
  "buildingLandRatio"?: number;
  "floorAreaRatio"?: number;
  "otherRegurations"?: string;
  "numUnits"?: number;
  "lotArea"?: number;
  "lotAreaRoadRatio"?: number;
  "buildingArea"?: number;
  "outerWallArea"?: number;
  "totalArea"?: number;
  "structure"?: number;
  "format"?: number;
  "purpose"?: string;
  "seismicRetrofit"?: number;
  "foundation"?: number;
  "pile"?: number;
  "pileOther"?: string;
  "outerWall1"?: number;
  "outerWall1Other"?: string;
  "outerWall2"?: number;
  "outerWall2Other"?: string;
  "eavesSoffit"?: number;
  "eavesSoffitOther"?: string;
  "roof"?: number;
  "roofOther"?: string;
  "waterSystem"?: number;
  "waterPipe"?: number;
  "drainSystem"?: number;
  "drainPipe"?: number;
  "hotWaterSystem"?: number;
  "hotWaterPipe"?: number;
  "gasType"?: number;
  "powerReceiving"?: number;
  "airConditioner"?: number;
  "fireAlarm"?: number;
  "fireExtinguisher"?: number;
  "fireExtinguisherDetail"?: string;
  "broadcastEqp"?: number;
  "broadcastEqpDetail"?: string;
  "bikePark"?: number;
  "bikeCapWithRoof"?: number;
  "bikeCapNoRoof"?: number;
  "carPark"?: number;
  "carCapWithRoof"?: number;
  "carCapNoRoof"?: number;
  "motoPark"?: number;
  "motoCapWithRoof"?: number;
  "motoCapNoRoof"?: number;
  "dateLastPatrol"?: Date|string;
  "dateNextPatrol"?: Date|string;
  "dateLastInspection"?: Date|string;
  "dateNextInspection"?: Date|string;
  "status": number;
  "id"?: number;
  "companyId"?: number;
  "branchId"?: number;
  "staffId"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId"?: number;
  "checkItemIds"?: Array<any>;
  "fileInfoIds"?: Array<any>;
  company?: Company;
  branch?: Branch;
  staff?: Staff;
  updater?: Staff;
  checkItems?: CheckItem[];
  patrols?: Patrol[];
  files?: FileInfo[];
  rooms?: Room[];
  inspections?: Inspection[];
}

export class Building implements BuildingInterface {
  "code": string;
  "name": string;
  "kana": string;
  "description": string;
  "zipCode": string;
  "address1": string;
  "address2": string;
  "dealingType": number;
  "builder": string;
  "dateBuilt": Date|string;
  "ownerName": string;
  "ownerZipCode": string;
  "ownerAddress1": string;
  "ownerAddress2": string;
  "ownerPhone": string;
  "ownerFax": string;
  "ownerEmail": string;
  "contactRoadE": number;
  "contactRoadW": number;
  "contactRoadS": number;
  "contactRoadN": number;
  "heightDiffE": number;
  "heightDiffW": number;
  "heightDiffS": number;
  "heightDiffN": number;
  "otherConditions": any;
  "regionCharacteristic": any;
  "buildingLandRatio": number;
  "floorAreaRatio": number;
  "otherRegurations": string;
  "numUnits": number;
  "lotArea": number;
  "lotAreaRoadRatio": number;
  "buildingArea": number;
  "outerWallArea": number;
  "totalArea": number;
  "structure": number;
  "format": number;
  "purpose": string;
  "seismicRetrofit": number;
  "foundation": number;
  "pile": number;
  "pileOther": string;
  "outerWall1": number;
  "outerWall1Other": string;
  "outerWall2": number;
  "outerWall2Other": string;
  "eavesSoffit": number;
  "eavesSoffitOther": string;
  "roof": number;
  "roofOther": string;
  "waterSystem": number;
  "waterPipe": number;
  "drainSystem": number;
  "drainPipe": number;
  "hotWaterSystem": number;
  "hotWaterPipe": number;
  "gasType": number;
  "powerReceiving": number;
  "airConditioner": number;
  "fireAlarm": number;
  "fireExtinguisher": number;
  "fireExtinguisherDetail": string;
  "broadcastEqp": number;
  "broadcastEqpDetail": string;
  "bikePark": number;
  "bikeCapWithRoof": number;
  "bikeCapNoRoof": number;
  "carPark": number;
  "carCapWithRoof": number;
  "carCapNoRoof": number;
  "motoPark": number;
  "motoCapWithRoof": number;
  "motoCapNoRoof": number;
  "dateLastPatrol": Date|string;
  "dateNextPatrol": Date|string;
  "dateLastInspection": Date|string;
  "dateNextInspection": Date|string;
  "status": number;
  "id": number;
  "companyId": number;
  "branchId": number;
  "staffId": number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId": number;
  "checkItemIds": Array<any>;
  "fileInfoIds": Array<any>;
  company?: Company;
  branch?: Branch;
  staff?: Staff;
  updater?: Staff;
  checkItems?: CheckItem[];
  patrols?: Patrol[];
  files?: FileInfo[];
  rooms?: Room[];
  inspections?: Inspection[];
  constructor(data?: Partial<BuildingInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Building`.
   */
  public static getModelName() {
    return "Building";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Building for dynamic purposes.
  **/
  public static factory(data: BuildingInterface): Building{
    return new Building(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Building',
      plural: 'Buildings',
      path: 'Buildings',
      idName: 'id',
      properties: {
        "code": {
          name: 'code',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "kana": {
          name: 'kana',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "zipCode": {
          name: 'zipCode',
          type: 'string'
        },
        "address1": {
          name: 'address1',
          type: 'string'
        },
        "address2": {
          name: 'address2',
          type: 'string'
        },
        "dealingType": {
          name: 'dealingType',
          type: 'number'
        },
        "builder": {
          name: 'builder',
          type: 'string'
        },
        "dateBuilt": {
          name: 'dateBuilt',
          type: 'Date|string'
        },
        "ownerName": {
          name: 'ownerName',
          type: 'string'
        },
        "ownerZipCode": {
          name: 'ownerZipCode',
          type: 'string'
        },
        "ownerAddress1": {
          name: 'ownerAddress1',
          type: 'string'
        },
        "ownerAddress2": {
          name: 'ownerAddress2',
          type: 'string'
        },
        "ownerPhone": {
          name: 'ownerPhone',
          type: 'string'
        },
        "ownerFax": {
          name: 'ownerFax',
          type: 'string'
        },
        "ownerEmail": {
          name: 'ownerEmail',
          type: 'string'
        },
        "contactRoadE": {
          name: 'contactRoadE',
          type: 'number'
        },
        "contactRoadW": {
          name: 'contactRoadW',
          type: 'number'
        },
        "contactRoadS": {
          name: 'contactRoadS',
          type: 'number'
        },
        "contactRoadN": {
          name: 'contactRoadN',
          type: 'number'
        },
        "heightDiffE": {
          name: 'heightDiffE',
          type: 'number'
        },
        "heightDiffW": {
          name: 'heightDiffW',
          type: 'number'
        },
        "heightDiffS": {
          name: 'heightDiffS',
          type: 'number'
        },
        "heightDiffN": {
          name: 'heightDiffN',
          type: 'number'
        },
        "otherConditions": {
          name: 'otherConditions',
          type: 'any'
        },
        "regionCharacteristic": {
          name: 'regionCharacteristic',
          type: 'any'
        },
        "buildingLandRatio": {
          name: 'buildingLandRatio',
          type: 'number'
        },
        "floorAreaRatio": {
          name: 'floorAreaRatio',
          type: 'number'
        },
        "otherRegurations": {
          name: 'otherRegurations',
          type: 'string'
        },
        "numUnits": {
          name: 'numUnits',
          type: 'number'
        },
        "lotArea": {
          name: 'lotArea',
          type: 'number'
        },
        "lotAreaRoadRatio": {
          name: 'lotAreaRoadRatio',
          type: 'number'
        },
        "buildingArea": {
          name: 'buildingArea',
          type: 'number'
        },
        "outerWallArea": {
          name: 'outerWallArea',
          type: 'number'
        },
        "totalArea": {
          name: 'totalArea',
          type: 'number'
        },
        "structure": {
          name: 'structure',
          type: 'number'
        },
        "format": {
          name: 'format',
          type: 'number'
        },
        "purpose": {
          name: 'purpose',
          type: 'string'
        },
        "seismicRetrofit": {
          name: 'seismicRetrofit',
          type: 'number'
        },
        "foundation": {
          name: 'foundation',
          type: 'number'
        },
        "pile": {
          name: 'pile',
          type: 'number'
        },
        "pileOther": {
          name: 'pileOther',
          type: 'string'
        },
        "outerWall1": {
          name: 'outerWall1',
          type: 'number'
        },
        "outerWall1Other": {
          name: 'outerWall1Other',
          type: 'string'
        },
        "outerWall2": {
          name: 'outerWall2',
          type: 'number'
        },
        "outerWall2Other": {
          name: 'outerWall2Other',
          type: 'string'
        },
        "eavesSoffit": {
          name: 'eavesSoffit',
          type: 'number'
        },
        "eavesSoffitOther": {
          name: 'eavesSoffitOther',
          type: 'string'
        },
        "roof": {
          name: 'roof',
          type: 'number'
        },
        "roofOther": {
          name: 'roofOther',
          type: 'string'
        },
        "waterSystem": {
          name: 'waterSystem',
          type: 'number'
        },
        "waterPipe": {
          name: 'waterPipe',
          type: 'number'
        },
        "drainSystem": {
          name: 'drainSystem',
          type: 'number'
        },
        "drainPipe": {
          name: 'drainPipe',
          type: 'number'
        },
        "hotWaterSystem": {
          name: 'hotWaterSystem',
          type: 'number'
        },
        "hotWaterPipe": {
          name: 'hotWaterPipe',
          type: 'number'
        },
        "gasType": {
          name: 'gasType',
          type: 'number'
        },
        "powerReceiving": {
          name: 'powerReceiving',
          type: 'number'
        },
        "airConditioner": {
          name: 'airConditioner',
          type: 'number'
        },
        "fireAlarm": {
          name: 'fireAlarm',
          type: 'number'
        },
        "fireExtinguisher": {
          name: 'fireExtinguisher',
          type: 'number'
        },
        "fireExtinguisherDetail": {
          name: 'fireExtinguisherDetail',
          type: 'string'
        },
        "broadcastEqp": {
          name: 'broadcastEqp',
          type: 'number'
        },
        "broadcastEqpDetail": {
          name: 'broadcastEqpDetail',
          type: 'string'
        },
        "bikePark": {
          name: 'bikePark',
          type: 'number'
        },
        "bikeCapWithRoof": {
          name: 'bikeCapWithRoof',
          type: 'number'
        },
        "bikeCapNoRoof": {
          name: 'bikeCapNoRoof',
          type: 'number'
        },
        "carPark": {
          name: 'carPark',
          type: 'number'
        },
        "carCapWithRoof": {
          name: 'carCapWithRoof',
          type: 'number'
        },
        "carCapNoRoof": {
          name: 'carCapNoRoof',
          type: 'number'
        },
        "motoPark": {
          name: 'motoPark',
          type: 'number'
        },
        "motoCapWithRoof": {
          name: 'motoCapWithRoof',
          type: 'number'
        },
        "motoCapNoRoof": {
          name: 'motoCapNoRoof',
          type: 'number'
        },
        "dateLastPatrol": {
          name: 'dateLastPatrol',
          type: 'Date|string'
        },
        "dateNextPatrol": {
          name: 'dateNextPatrol',
          type: 'Date|string'
        },
        "dateLastInspection": {
          name: 'dateLastInspection',
          type: 'Date|string'
        },
        "dateNextInspection": {
          name: 'dateNextInspection',
          type: 'Date|string'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 1
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "branchId": {
          name: 'branchId',
          type: 'number'
        },
        "staffId": {
          name: 'staffId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
        "checkItemIds": {
          name: 'checkItemIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "fileInfoIds": {
          name: 'fileInfoIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        branch: {
          name: 'branch',
          type: 'Branch',
          model: 'Branch',
          relationType: 'belongsTo',
                  keyFrom: 'branchId',
          keyTo: 'id'
        },
        staff: {
          name: 'staff',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'staffId',
          keyTo: 'id'
        },
        updater: {
          name: 'updater',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'updaterId',
          keyTo: 'id'
        },
        checkItems: {
          name: 'checkItems',
          type: 'CheckItem[]',
          model: 'CheckItem',
          relationType: 'referencesMany',
                  keyFrom: 'checkItemIds',
          keyTo: 'id'
        },
        patrols: {
          name: 'patrols',
          type: 'Patrol[]',
          model: 'Patrol',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'buildingId'
        },
        files: {
          name: 'files',
          type: 'FileInfo[]',
          model: 'FileInfo',
          relationType: 'referencesMany',
                  keyFrom: 'fileInfoIds',
          keyTo: 'id'
        },
        rooms: {
          name: 'rooms',
          type: 'Room[]',
          model: 'Room',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'buildingId'
        },
        inspections: {
          name: 'inspections',
          type: 'Inspection[]',
          model: 'Inspection',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'buildingId'
        },
      }
    }
  }
}
