import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, registerLocaleData } from '@angular/common';
import localeJa from '@angular/common/locales/ja';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SDKBrowserModule, LoopBackConfig } from '../loopback';

import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { SharedModule } from './shared.module';

import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';

import { RoleService } from './services/role.service';
import { ArcElement, BarController, BarElement, CategoryScale, Chart, DoughnutController, Filler, Legend, LinearScale, LineController, LineElement, PointElement, RadarController, RadialLinearScale, TimeScale, Tooltip } from 'chart.js';
import 'chartjs-adapter-moment';

localeJa[10][1] = 'y年M月d日';
registerLocaleData(localeJa);

Chart.register(
  RadarController, DoughnutController, BarController, LineController,
  LinearScale, RadialLinearScale, TimeScale, CategoryScale,
  PointElement, LineElement, BarElement, ArcElement,
  Tooltip, Filler, Legend
);

export function appInitializer(roleService: RoleService) {
  return () => roleService.init().toPromise();
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SDKBrowserModule.forRoot(),
    SharedModule.forRoot(),
    AppRoutingModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: LOCALE_ID, useValue: 'ja' },
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [RoleService] },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    LoopBackConfig.setBaseURL(environment.apiUrl);
    LoopBackConfig.filterOnUrl();
    LoopBackConfig.whereOnUrl();
  }
}
