import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { FlexLayoutModule } from '@angular/flex-layout';

import { ScrollContentComponent } from './components/scroll-content/scroll-content.component';
import { MessageBoxComponent } from './components/message-box/message-box.component';
import { BadgeComponent } from './components/badge/badge.component';
import { DateIntervalInputComponent } from './components/date-interval-input/date-interval-input.component';
import { MonthIntervalInputComponent } from './components/month-interval-input/month-interval-input.component';
import { FileSelectorComponent } from './components/file-selector/file-selector.component';
import { IdenticonComponent } from './components/identicon/identicon.component';
import { MenuComponent } from './components/menu/menu.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { ImageEditorComponent } from './components/image-editor/image-editor.component';
import { TaskDetailEditorComponent } from './components/task-detail-editor/task-detail-editor.component';
import { TaskChartWidgetComponent } from './components/task-chart-widget/task-chart-widget.component';
import { ProgressChartWidgetComponent } from './components/progress-chart-widget/progress-chart-widget.component';
import { AlertListWidgetComponent } from './components/alert-list-widget/alert-list-widget.component';

import { GenericDialogComponent } from './dialogs/generic-dialog/generic-dialog.component';
import { RoleGroupSettingsComponent } from './dialogs/role-group-settings/role-group-settings.component';
import { BuildingImportConfirmComponent } from './dialogs/building-import-confirm/building-import-confirm.component';
import { ConditionTemplateEditorComponent } from './dialogs/condition-template-editor/condition-template-editor.component';
import { ConditionTemplateSelectorComponent } from './dialogs/condition-template-selector/condition-template-selector.component';
import { PARDetailViewComponent } from './dialogs/par-detail-view/par-detail-view.component';
import { PAREquipmentSelectorComponent } from './dialogs/par-equipment-selector/par-equipment-selector.component';
import { RepairWorkEditorComponent } from './dialogs/repair-work-editor/repair-work-editor.component';
import { RelatedInspectionViewComponent } from './dialogs/related-inspection-view/related-inspection-view.component';

import { AutoAddressDirective, AutoAddressButtonDirective } from './directives/auto-address.directive';

import { FileUrlPipe } from './pipes/file-url.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { TaskConditionNamePipe } from './pipes/task-condition-name.pipe';
import { OptionLabelPipe } from './pipes/option-label.pipe';
import { JoinPipe } from './pipes/join.pipe';

import { FileInfoApiOverrideService } from './services/file-info-api-override.service';
import { ImportHistoryApiOverrideService } from './services/import-history-api-override.service';

import { AuthRequiredGuard } from './guards/auth-required.guard';
import { DetailDeactivateGuard } from './guards/detail-deactivate.guard';

import { SDKBrowserModule, FileInfoApi, ImportHistoryApi } from '../loopback';
import { MonthSelectorComponent } from './components/month-selector/month-selector.component';
import { RepairPlanCreatorComponent } from './dialogs/repair-plan-creator/repair-plan-creator.component';
import { TaskReportDownloaderComponent } from './dialogs/task-report-downloader/task-report-downloader.component';
import { ImageSelectorComponent } from './components/image-selector/image-selector.component';
import { InspectionCsvDownloaderComponent } from './dialogs/inspection-csv-downloader/inspection-csv-downloader.component';

const COMPONENTS = [
  ScrollContentComponent, MessageBoxComponent, BadgeComponent,
  DateIntervalInputComponent, MonthIntervalInputComponent,
  FileSelectorComponent, IdenticonComponent,
  MenuComponent, MenuItemComponent, TaskDetailEditorComponent,
  TaskChartWidgetComponent, ProgressChartWidgetComponent, AlertListWidgetComponent,
  MonthSelectorComponent, ImageSelectorComponent, InspectionCsvDownloaderComponent,
];

const DIALOGS = [
  GenericDialogComponent, RoleGroupSettingsComponent,
  BuildingImportConfirmComponent,
  ConditionTemplateEditorComponent, ConditionTemplateSelectorComponent,
  PARDetailViewComponent, PAREquipmentSelectorComponent,
  ImageEditorComponent, RepairWorkEditorComponent, RelatedInspectionViewComponent,
  RepairPlanCreatorComponent, TaskReportDownloaderComponent,
];

const DIRECTIVES = [
  AutoAddressDirective, AutoAddressButtonDirective
];

const PIPES = [
  FileUrlPipe, FileSizePipe, TaskConditionNamePipe, OptionLabelPipe, JoinPipe
];

const SERVICES = [
  { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'standard' } },
  { provide: FileInfoApi, useClass: FileInfoApiOverrideService },
  { provide: ImportHistoryApi, useClass: ImportHistoryApiOverrideService },
];

const GUARDS = [
  AuthRequiredGuard, DetailDeactivateGuard
];

const EXTERNAL_MODULES = [
  PortalModule, OverlayModule,
  MatCheckboxModule, MatSelectModule, MatRadioModule, MatInputModule, MatDatepickerModule,
  MatToolbarModule, MatSidenavModule, MatMenuModule,
  MatListModule, MatTabsModule, MatCardModule, MatGridListModule,
  MatButtonModule, MatButtonToggleModule, MatIconModule, MatProgressSpinnerModule,
  MatDialogModule, MatTooltipModule, MatSnackBarModule,
  MatMomentDateModule,
  FlexLayoutModule,
  NgxDatatableModule, NgxDnDModule, LazyLoadImageModule
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...DIALOGS,
    ...DIRECTIVES,
    ...PIPES,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ...EXTERNAL_MODULES,
    SDKBrowserModule
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    ...EXTERNAL_MODULES,
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ...SERVICES,
        ...GUARDS
      ]
    };
  }
}
