import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { ImportHistoryApi, SDKModels, LoopBackAuth, ErrorHandler, LoopBackConfig } from '../../loopback';
import { Observable } from 'rxjs';
import { filter, map, catchError } from 'rxjs/operators';

/**
 * CSVインポート履歴API
 * importCsvメソッド置き換え用クラス
 */
@Injectable()
export class ImportHistoryApiOverrideService extends ImportHistoryApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http, models, auth, errorHandler);
  }
  /**
   * CSVアップロード
   *
   * @param form フォームデータ
   */
  public importCsv(form: FormData): Observable<any> {
    const _method = 'POST';
    const _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
    '/ImportHistories/importCsv';
    let headers = new HttpHeaders();
    headers = this.authenticate(_url, headers);
    const request = new HttpRequest(_method, _url, form, {headers});
    return this.http.request(request).pipe(
      filter(event => event instanceof HttpResponse),
      map((res: HttpResponse<any>) => res.body),
      catchError((e) => this.errorHandler.handleError(e))
    );
  }
}
