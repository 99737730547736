import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

  transform(byte: number, unit: 'B'|'K'|'M'|'G'|'auto' = 'auto'): string {
    if ((unit === 'auto' && byte < 1000) || unit === 'B') {
      return byte + 'B';
    }

    const kb = byte / 1000;

    if ((unit === 'auto' && kb < 1000) || unit === 'K') {
      return Math.round(kb) + 'KB';
    }

    const mb = kb / 1000;

    if ((unit === 'auto' && mb < 1000) || unit === 'M') {
      return Math.round(mb) + 'MB';
    }

    const gb = mb / 1000;

    return Math.round(gb) + 'GB';
  }

}
