import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join'
})
export class JoinPipe implements PipeTransform {

  transform(value: any[], sep: string): string {
    if (!value) {
      value = [];
    }

    return value.join(sep);
  }

}
