/* eslint-disable */
import {
  Company,
  Staff
} from '../index';

declare var Object: any;
export interface ImportHistoryInterface {
  "created"?: number;
  "updated"?: number;
  "failed"?: number;
  "uploadedAt"?: Date|string;
  "id"?: number;
  "companyId"?: number;
  "staffId"?: number;
  company?: Company;
  staff?: Staff;
}

export class ImportHistory implements ImportHistoryInterface {
  "created": number;
  "updated": number;
  "failed": number;
  "uploadedAt": Date|string;
  "id": number;
  "companyId": number;
  "staffId": number;
  company?: Company;
  staff?: Staff;
  constructor(data?: Partial<ImportHistoryInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ImportHistory`.
   */
  public static getModelName() {
    return "ImportHistory";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ImportHistory for dynamic purposes.
  **/
  public static factory(data: ImportHistoryInterface): ImportHistory{
    return new ImportHistory(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ImportHistory',
      plural: 'ImportHistories',
      path: 'ImportHistories',
      idName: 'id',
      properties: {
        "created": {
          name: 'created',
          type: 'number'
        },
        "updated": {
          name: 'updated',
          type: 'number'
        },
        "failed": {
          name: 'failed',
          type: 'number'
        },
        "uploadedAt": {
          name: 'uploadedAt',
          type: 'Date|string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "staffId": {
          name: 'staffId',
          type: 'number'
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        staff: {
          name: 'staff',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'staffId',
          keyTo: 'id'
        },
      }
    }
  }
}
