import { Injectable, SimpleChange } from '@angular/core';
import { Observable, Subject } from 'rxjs';

interface State {
  value: any;
  subject: Subject<SimpleChange>;
  changed: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class GlobalStateService {

  private states: {[name: string]: State} = {};

  onChanged(name: string): Observable<SimpleChange> {
    const state = this.getOrCreateState(name);

    return state.subject.asObservable();
  }

  get(name: string): any {
    return this.states[name] ? this.states[name].value : undefined;
  }

  set(name: string, value: any) {
    const state = this.getOrCreateState(name);

    if (state.value !== value) {
      const change = new SimpleChange(state.value, value, !state.changed);
      state.subject.next(change);
      state.value = value;
      state.changed = true;
    }
  }

  private getOrCreateState(name: string): State {
    let state = this.states[name];

    if (state === undefined) {
      this.states[name] = state = {
        value: undefined,
        subject: new Subject<SimpleChange>(),
        changed: false
      };
    }

    return state;
  }

}
