/* eslint-disable */
import {
  Company,
  Building,
  Staff,
  Patrol
} from '../index';

declare var Object: any;
export interface PatrolAnnualReportInterface {
  "status": number;
  "approvalStatus": number;
  "approvedAt"?: Date|string;
  "patrolSummary"?: string;
  "balanceSummary"?: string;
  "occupancyRating"?: number;
  "contractRating"?: number;
  "pageViewRating"?: number;
  "rentRating"?: number;
  "roomEquipmentRating"?: number;
  "roomEquipmentList"?: Array<any>;
  "commonEquipmentRating"?: number;
  "commonEquipmentList"?: Array<any>;
  "currentRatio"?: number;
  "currentRatioRating"?: number;
  "id"?: number;
  "companyId"?: number;
  "buildingId"?: number;
  "staffId"?: number;
  "patrolIds"?: Array<any>;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId"?: number;
  company?: Company;
  building?: Building;
  staff?: Staff;
  patrols?: Patrol[];
  updater?: Staff;
}

export class PatrolAnnualReport implements PatrolAnnualReportInterface {
  "status": number;
  "approvalStatus": number;
  "approvedAt": Date|string;
  "patrolSummary": string;
  "balanceSummary": string;
  "occupancyRating": number;
  "contractRating": number;
  "pageViewRating": number;
  "rentRating": number;
  "roomEquipmentRating": number;
  "roomEquipmentList": Array<any>;
  "commonEquipmentRating": number;
  "commonEquipmentList": Array<any>;
  "currentRatio": number;
  "currentRatioRating": number;
  "id": number;
  "companyId": number;
  "buildingId": number;
  "staffId": number;
  "patrolIds": Array<any>;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId": number;
  company?: Company;
  building?: Building;
  staff?: Staff;
  patrols?: Patrol[];
  updater?: Staff;
  constructor(data?: Partial<PatrolAnnualReportInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `PatrolAnnualReport`.
   */
  public static getModelName() {
    return "PatrolAnnualReport";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of PatrolAnnualReport for dynamic purposes.
  **/
  public static factory(data: PatrolAnnualReportInterface): PatrolAnnualReport{
    return new PatrolAnnualReport(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'PatrolAnnualReport',
      plural: 'PatrolAnnualReports',
      path: 'PatrolAnnualReports',
      idName: 'id',
      properties: {
        "status": {
          name: 'status',
          type: 'number',
          default: 1
        },
        "approvalStatus": {
          name: 'approvalStatus',
          type: 'number',
          default: 1
        },
        "approvedAt": {
          name: 'approvedAt',
          type: 'Date|string'
        },
        "patrolSummary": {
          name: 'patrolSummary',
          type: 'string'
        },
        "balanceSummary": {
          name: 'balanceSummary',
          type: 'string'
        },
        "occupancyRating": {
          name: 'occupancyRating',
          type: 'number'
        },
        "contractRating": {
          name: 'contractRating',
          type: 'number'
        },
        "pageViewRating": {
          name: 'pageViewRating',
          type: 'number'
        },
        "rentRating": {
          name: 'rentRating',
          type: 'number'
        },
        "roomEquipmentRating": {
          name: 'roomEquipmentRating',
          type: 'number'
        },
        "roomEquipmentList": {
          name: 'roomEquipmentList',
          type: 'Array&lt;any&gt;'
        },
        "commonEquipmentRating": {
          name: 'commonEquipmentRating',
          type: 'number'
        },
        "commonEquipmentList": {
          name: 'commonEquipmentList',
          type: 'Array&lt;any&gt;'
        },
        "currentRatio": {
          name: 'currentRatio',
          type: 'number'
        },
        "currentRatioRating": {
          name: 'currentRatioRating',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "buildingId": {
          name: 'buildingId',
          type: 'number'
        },
        "staffId": {
          name: 'staffId',
          type: 'number'
        },
        "patrolIds": {
          name: 'patrolIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        building: {
          name: 'building',
          type: 'Building',
          model: 'Building',
          relationType: 'belongsTo',
                  keyFrom: 'buildingId',
          keyTo: 'id'
        },
        staff: {
          name: 'staff',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'staffId',
          keyTo: 'id'
        },
        patrols: {
          name: 'patrols',
          type: 'Patrol[]',
          model: 'Patrol',
          relationType: 'referencesMany',
                  keyFrom: 'patrolIds',
          keyTo: 'id'
        },
        updater: {
          name: 'updater',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'updaterId',
          keyTo: 'id'
        },
      }
    }
  }
}
