import { Component, OnInit, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { Chart, ChartConfiguration } from 'chart.js';
import { forkJoin } from 'rxjs';
import { BuildingApi, PatrolApi, InspectionApi } from 'loopback';
import * as moment from 'moment';
import { ucFirst } from '../../utils';

@Component({
  selector: 'bi-progress-chart-widget',
  templateUrl: './progress-chart-widget.component.html',
  styleUrls: ['./progress-chart-widget.component.scss']
})
export class ProgressChartWidgetComponent implements OnInit {

  stats = {
    patrol: {
      remains: 0,
      expired: 0,
      inProgress: 0,
      unapproved: 0,
      approved: 0,
      sendbacked: 0
    },
    inspection: {
      remains: 0,
      expired: 0,
      inProgress: 0,
      unapproved: 0,
      approved: 0,
      sendbacked: 0
    }
  };

  chart: {
    patrol: Chart;
    inspection: Chart;
  } = {
    patrol: null,
    inspection: null
  };

  @ViewChildren('chart') canvases: QueryList<ElementRef>;

  constructor(
    private buildingApi: BuildingApi,
    private patrolApi: PatrolApi,
    private inspectionApi: InspectionApi
  ) { }

  ngOnInit() {
    const thisMonthStart = moment().startOf('month');
    const thisMonthEnd = moment().endOf('month');

    forkJoin([
      this.buildingApi.find({
        where: {
          or: [
            {dateNextPatrol: {lte: thisMonthStart}},
            {dateNextInspection: {lte: thisMonthStart}}
          ]
        }
      }),
      this.patrolApi.find({
        where: {
          finishedAt: {between: [thisMonthStart, thisMonthEnd]},
          approvalStatus: {neq: 3}
        }
      }),
      this.inspectionApi.find({
        where: {
          finishedAt: { between: [thisMonthStart, thisMonthEnd] },
          approvalStatus: { neq: 3 }
        }
      })
    ]).subscribe(
      res => {
        const buildings = res[0];
        const tasks = {patrol: res[1], inspection: res[2]};
        const config: ChartConfiguration<'doughnut'> = {
          type: 'doughnut',
          options: {
            plugins: {
              legend: {
                display: false
              },
            },
            maintainAspectRatio: false
          },
          data: null,
        };
        const labels = ['承認済み', '未承認', '修正中', '実施予定', '残り'];
        const backgroundColor = ['#43a047', '#f44336', '#ef6c00', '#2196f3', '#ccc'];

        for (const type of ['patrol', 'inspection']) {
          const canvas = this.canvases.find(c => c.nativeElement.classList.contains(type)).nativeElement;

          this.stats[type] = {
            remains: buildings.filter(b => moment(b['dateNext' + ucFirst(type)]).isSame(thisMonthStart)).length,
            expired: buildings.filter(b => moment(b['dateNext' + ucFirst(type)]).isBefore(thisMonthStart)).length,
            inProgress: tasks[type].filter(t => t.status === 1).length,
            unapproved: tasks[type].filter(t => t.status === 3 && t.approvalStatus === 1).length,
            approved: tasks[type].filter(t => t.status === 3 && t.approvalStatus === 2).length,
            sendbacked: tasks[type].filter(t => t.status === 4).length
          };
          this.chart[type] = new Chart(canvas, Object.assign({}, config, {
            data: {
              datasets: [{
                data: [
                  this.stats[type].approved, this.stats[type].unapproved,
                  this.stats[type].sendbacked, this.stats[type].inProgress,
                  this.stats[type].remains + this.stats[type].expired
                  - this.stats[type].approved - this.stats[type].unapproved
                  - this.stats[type].sendbacked - this.stats[type].inProgress
                ],
                backgroundColor
              }],
              labels
            }
          }));
        }
      }
    );
  }
}
