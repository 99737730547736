import { AfterViewInit, Directive, HostBinding, Input, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NgControl } from '@angular/forms';
import { MatDatepickerInput } from '@angular/material/datepicker';
import { MatFormFieldControl } from '@angular/material/form-field';
import * as moment from 'moment';
import { Subject } from 'rxjs';

export interface DateInterval {
  start: moment.Moment;
  end: moment.Moment;
}

@Directive()
export class IntervalInputBase implements MatFormFieldControl<DateInterval>, ControlValueAccessor, AfterViewInit {
  static idSeq = 0;
  autofilled?: boolean;

  form: FormGroup<{ start: FormControl<moment.Moment>; end: FormControl<moment.Moment> }>;
  stateChanges = new Subject<void>();
  focused = false;
  errorState = false;
  controlType = 'date-interval-input';

  @ViewChild('startInput', { static: true }) startInput: MatDatepickerInput<moment.Moment>;
  @ViewChild('endInput', { static: true }) endInput: MatDatepickerInput<moment.Moment>;

  @Input()
  get value(): DateInterval {
    return { start: this.form.value.start, end: this.form.value.end };
  }
  set value(value: DateInterval) {
    if (!value) {
      value = { start: null, end: null };
    }
    this.form.setValue(value);
    this.stateChanges.next();
  }

  id: string;
  describedBy: string;

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }
  private _placeholder: string;

  get empty() {
    return !this.form.value.start && !this.form.value.end;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(required: boolean) {
    this._required = !!required;
    this.stateChanges.next();
  }
  private _required: boolean;

  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(disabled: boolean) {
    console.log(disabled);
    this._disabled = !!disabled;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get readonly() {
    return this._readonly;
  }
  set readonly(readonly: boolean) {
    this._readonly = !!readonly;
    this.stateChanges.next();
  }
  private _readonly: boolean;

  private _onChange;
  private _onTouched;

  constructor(
    protected formBuilder: FormBuilder,
    @Optional() @Self() public ngControl: NgControl
  ) {
    this.form = this.formBuilder.group({
      start: [null],
      end: [null]
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngAfterViewInit() {
    // form fieldに内包する場合、どちらのpickerも同じ位置に出てしまうので
    // それぞれのinputの下に出るようにするためのハック
    this.startInput.getConnectedOverlayOrigin = () => this.startInput['_elementRef'];
    this.endInput.getConnectedOverlayOrigin = () => this.endInput['_elementRef'];
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(e: MouseEvent) {

  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  onChange(value: any) {
    this._onChange(value);
  }

  setDisabledState(disabled: boolean) {
    this.form[disabled ? 'disable' : 'enable']();
    this.disabled = disabled;
  }
}
