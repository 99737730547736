import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { DetailPageBase } from '../classes/detail-page-base';

@Injectable()
export class DetailDeactivateGuard implements CanDeactivate<DetailPageBase<any>> {
  canDeactivate(
    component: DetailPageBase<any>,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return component.canDeactivate;
  }
}
