/* eslint-disable */
import {
  Company,
  Branch,
  Building,
  Staff,
  PatrolDetail,
  FileInfo
} from '../index';

declare var Object: any;
export interface PatrolInterface {
  "status": number;
  "finishedAt"?: Date|string;
  "approvalStatus": number;
  "approvedAt"?: Date|string;
  "summary"?: string;
  "balance"?: string;
  "sendbackMessage"?: string;
  "uploadedAt"?: Date|string;
  "reportCoverPhotoId"?: number;
  "id"?: number;
  "companyId"?: number;
  "branchId"?: number;
  "buildingId"?: number;
  "staffId"?: number;
  "managerId"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId"?: number;
  "coverPhotoIds"?: Array<any>;
  company?: Company;
  branch?: Branch;
  building?: Building;
  staff?: Staff;
  manager?: Staff;
  updater?: Staff;
  details?: PatrolDetail[];
  coverPhotos?: FileInfo[];
}

export class Patrol implements PatrolInterface {
  "status": number;
  "finishedAt": Date|string;
  "approvalStatus": number;
  "approvedAt": Date|string;
  "summary": string;
  "balance": string;
  "sendbackMessage": string;
  "uploadedAt": Date|string;
  "reportCoverPhotoId": number;
  "id": number;
  "companyId": number;
  "branchId": number;
  "buildingId": number;
  "staffId": number;
  "managerId": number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId": number;
  "coverPhotoIds": Array<any>;
  company?: Company;
  branch?: Branch;
  building?: Building;
  staff?: Staff;
  manager?: Staff;
  updater?: Staff;
  details?: PatrolDetail[];
  coverPhotos?: FileInfo[];
  constructor(data?: Partial<PatrolInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Patrol`.
   */
  public static getModelName() {
    return "Patrol";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Patrol for dynamic purposes.
  **/
  public static factory(data: PatrolInterface): Patrol{
    return new Patrol(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Patrol',
      plural: 'Patrols',
      path: 'Patrols',
      idName: 'id',
      properties: {
        "status": {
          name: 'status',
          type: 'number',
          default: 1
        },
        "finishedAt": {
          name: 'finishedAt',
          type: 'Date|string'
        },
        "approvalStatus": {
          name: 'approvalStatus',
          type: 'number',
          default: 1
        },
        "approvedAt": {
          name: 'approvedAt',
          type: 'Date|string'
        },
        "summary": {
          name: 'summary',
          type: 'string'
        },
        "balance": {
          name: 'balance',
          type: 'string'
        },
        "sendbackMessage": {
          name: 'sendbackMessage',
          type: 'string'
        },
        "uploadedAt": {
          name: 'uploadedAt',
          type: 'Date|string'
        },
        "reportCoverPhotoId": {
          name: 'reportCoverPhotoId',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "branchId": {
          name: 'branchId',
          type: 'number'
        },
        "buildingId": {
          name: 'buildingId',
          type: 'number'
        },
        "staffId": {
          name: 'staffId',
          type: 'number'
        },
        "managerId": {
          name: 'managerId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
        "coverPhotoIds": {
          name: 'coverPhotoIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        branch: {
          name: 'branch',
          type: 'Branch',
          model: 'Branch',
          relationType: 'belongsTo',
                  keyFrom: 'branchId',
          keyTo: 'id'
        },
        building: {
          name: 'building',
          type: 'Building',
          model: 'Building',
          relationType: 'belongsTo',
                  keyFrom: 'buildingId',
          keyTo: 'id'
        },
        staff: {
          name: 'staff',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'staffId',
          keyTo: 'id'
        },
        manager: {
          name: 'manager',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'managerId',
          keyTo: 'id'
        },
        updater: {
          name: 'updater',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'updaterId',
          keyTo: 'id'
        },
        details: {
          name: 'details',
          type: 'PatrolDetail[]',
          model: 'PatrolDetail',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'patrolId'
        },
        coverPhotos: {
          name: 'coverPhotos',
          type: 'FileInfo[]',
          model: 'FileInfo',
          relationType: 'referencesMany',
                  keyFrom: 'coverPhotoIds',
          keyTo: 'id'
        },
      }
    }
  }
}
