import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';

import { AuthRequiredGuard } from './guards/auth-required.guard';
import { DetailDeactivateGuard } from './guards/detail-deactivate.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'dashboard'},
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthRequiredGuard] },
  { path: 'building', canActivateChild: [AuthRequiredGuard],
    loadChildren: () => import('app/pages/building/building.module').then(m => m.BuildingModule) },
  { path: 'task', canActivateChild: [AuthRequiredGuard],
    loadChildren: () => import('app/pages/task/task.module').then(m => m.TaskModule) },
  { path: 'branch', canActivate: [AuthRequiredGuard], data: {caps: ['settings']},
    loadChildren: () => import('app/pages/branch/branch.module').then(m => m.BranchModule) },
  { path: 'user', canActivateChild: [AuthRequiredGuard],
    loadChildren: () => import('app/pages/user/user.module').then(m => m.UserModule) },
  { path: 'settings', canActivate: [AuthRequiredGuard], data: {caps: ['settings']},
    loadChildren: () => import('app/pages/settings/settings.module').then(m => m.SettingsModule) },
  { path: '**', redirectTo: 'dashboard' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
