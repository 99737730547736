import { Component, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { CheckItemCategoryTarget, OptionItem, OptionsService } from 'app/services/options.service';
import { RepairWork } from 'loopback';
import { GenericDialogComponent } from '../generic-dialog/generic-dialog.component';

type MainFormGroup = FormGroup<{
  id: FormControl<number>;
  part: FormControl<string>;
  order: FormControl<number>;
  type: FormControl<string>;
  unitCost: FormControl<number>;
  multiplierType: FormControl<number>;
  cycle: FormControl<number>;
  _linkage?: LinkageFormGroup;
}>;

type LinkageFormGroup = FormGroup<{
  part: FormControl<string>;
  order: FormControl<number>;
  type: FormControl<string>;
  unitCost: FormControl<number>;
  multiplierType: FormControl<number>;
  cycle: FormControl<number>;
}>;


@Component({
  selector: 'bi-repair-work-editor',
  templateUrl: './repair-work-editor.component.html',
  styleUrls: ['./repair-work-editor.component.scss']
})
export class RepairWorkEditorComponent implements OnInit {

  title = '';
  form: MainFormGroup;
  linkageForm: LinkageFormGroup;
  linkageEnabled = false;

  typeOptions: string[] = ['取替', '点検', '撤去・新設', '補修', '修繕', '塗替'];
  multiplierTypeOptions: OptionItem[] = [];
  typeTemp: string;

  checkItemCategoryTargets: CheckItemCategoryTarget[];

  @ViewChildren('relatedCheckItem') relatedCheckItems: QueryList<MatCheckbox>;

  constructor(
    private formBuilder: FormBuilder,
    private ref: MatDialogRef<RepairWorkEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RepairWork,
    private opt: OptionsService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.multiplierTypeOptions = this.opt.repairWorkMultiplierType;
    this.opt.getCheckItemCategoryTargets().subscribe(
      res => {
        res.shift(); // 巡回の項目は除外
        this.checkItemCategoryTargets = res;
      }
    );

    if (this.typeOptions.concat(['', '-1']).indexOf(this.data.type) === -1) {
      this.typeOptions.push(this.data.type);
    }

    this.form = this.formBuilder.group({
      id: [this.data.id],
      part: [this.data.part, Validators.required],
      order: [this.data.order],
      type: [this.data.type],
      unitCost: [this.data.unitCost],
      multiplierType: [this.data.multiplierType],
      cycle: [this.data.cycle],
    });

    this.linkageForm = this.formBuilder.group({
      part: [this.data.linkage?.part, Validators.required],
      order: [this.data.linkage?.order],
      type: [this.data.linkage?.type],
      unitCost: [this.data.linkage?.unitCost],
      multiplierType: [this.data.linkage?.multiplierType],
      cycle: [this.data.linkage?.cycle],
    });

    this.form.addControl('_linkage', this.linkageForm);
    this.toggleLinkage(this.data.linkage != null);

    this.title = this.data.id ? `${this.data.part} (${this.data.type})` : '新規作成';
  }

  onOpenTypeSelect(open: boolean, control: AbstractControl) {
    if (open) {
      this.typeTemp = control.value;
    }
  }

  onTypeChange(e: MatSelectChange, control: AbstractControl) {
    if (e.value === -1) {
      this.dialog.open(GenericDialogComponent, {
        data: {
          type: 'prompt',
          message: '工事区分を入力してください'
        }
      }).afterClosed().subscribe(
        res => {
          if (!res) {
            res = this.typeTemp;
          }

          if (this.typeOptions.indexOf(res) === -1 && res !== '') {
            this.typeOptions.push(res);
          }

          control.setValue(res);
          this.typeTemp = null;
        }
      );
    }
  }

  toggleLinkage(enable: boolean) {
    if (enable) {
      this.linkageForm.enable();
    } else {
      this.linkageForm.disable();
    }
    this.linkageEnabled = enable;
  }

  onOkClick() {
    const work = new RepairWork(this.form.value);

    work.relatedCheckItemIds = this.relatedCheckItems.filter(item => item.checked).map(item => item.value);

    if (work._linkage) {
      work._linkage.createdAt = new Date();
      work._linkage.updatedAt = new Date();
    }

    this.ref.close(work);
  }

}
