/* eslint-disable */
import {
  Company,
  Staff,
  CheckItemCategory,
  ConditionTemplate
} from '../index';

declare var Object: any;
export interface CheckItemInterface {
  "name"?: string;
  "target"?: any;
  "issueOptions"?: Array<any>;
  "proposalOptions"?: Array<any>;
  "status": number;
  "order"?: number;
  "id"?: number;
  "companyId"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId"?: number;
  "categoryId"?: number;
  company?: Company;
  updater?: Staff;
  category?: CheckItemCategory;
  conditionTemplates?: ConditionTemplate[];
}

export class CheckItem implements CheckItemInterface {
  "name": string;
  "target": any;
  "issueOptions": Array<any>;
  "proposalOptions": Array<any>;
  "status": number;
  "order": number;
  "id": number;
  "companyId": number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId": number;
  "categoryId": number;
  company?: Company;
  updater?: Staff;
  category?: CheckItemCategory;
  conditionTemplates?: ConditionTemplate[];
  constructor(data?: Partial<CheckItemInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CheckItem`.
   */
  public static getModelName() {
    return "CheckItem";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CheckItem for dynamic purposes.
  **/
  public static factory(data: CheckItemInterface): CheckItem{
    return new CheckItem(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CheckItem',
      plural: 'CheckItems',
      path: 'CheckItems',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "target": {
          name: 'target',
          type: 'any'
        },
        "issueOptions": {
          name: 'issueOptions',
          type: 'Array&lt;any&gt;'
        },
        "proposalOptions": {
          name: 'proposalOptions',
          type: 'Array&lt;any&gt;'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 1
        },
        "order": {
          name: 'order',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
        "categoryId": {
          name: 'categoryId',
          type: 'number'
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        updater: {
          name: 'updater',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'updaterId',
          keyTo: 'id'
        },
        category: {
          name: 'category',
          type: 'CheckItemCategory',
          model: 'CheckItemCategory',
          relationType: 'belongsTo',
                  keyFrom: 'categoryId',
          keyTo: 'id'
        },
        conditionTemplates: {
          name: 'conditionTemplates',
          type: 'ConditionTemplate[]',
          model: 'ConditionTemplate',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'checkItemId'
        },
      }
    }
  }
}
