import { Component, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import * as jdenticon from 'jdenticon';
import * as md5 from 'blueimp-md5';

/**
 * 自動生成ユーザーアイコン付きのユーザー名表示
 */
@Component({
  selector: 'bi-identicon',
  templateUrl: './identicon.component.html',
  styleUrls: ['./identicon.component.scss']
})
export class IdenticonComponent implements AfterViewInit {
  /**
   * ユーザーID
   */
  @Input() userName: string;

  /**
   * 表示名
   */
  @Input() displayName: string;

  /**
   * 会社名
   */
  @Input() companyName: string;

  /**
   * 幅と高さ
   */
  @Input() size = 48;

  /**
   * アイコンへの参照
   */
  @ViewChild('icon', { static: true }) icon: ElementRef;

  ngAfterViewInit() {
    const hash = md5(this.userName);
    jdenticon.update(this.icon.nativeElement, hash);
  }

}
