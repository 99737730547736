/* eslint-disable */
import {
  Company,
  Staff,
  RepairItemCategory,
  RepairWork
} from '../index';

declare var Object: any;
export interface RepairItemInterface {
  "name"?: string;
  "status": number;
  "order"?: number;
  "id"?: number;
  "companyId"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId"?: number;
  "categoryId"?: number;
  company?: Company;
  updater?: Staff;
  category?: RepairItemCategory;
  works?: RepairWork[];
}

export class RepairItem implements RepairItemInterface {
  "name": string;
  "status": number;
  "order": number;
  "id": number;
  "companyId": number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId": number;
  "categoryId": number;
  company?: Company;
  updater?: Staff;
  category?: RepairItemCategory;
  works?: RepairWork[];
  constructor(data?: Partial<RepairItemInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `RepairItem`.
   */
  public static getModelName() {
    return "RepairItem";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of RepairItem for dynamic purposes.
  **/
  public static factory(data: RepairItemInterface): RepairItem{
    return new RepairItem(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'RepairItem',
      plural: 'RepairItems',
      path: 'RepairItems',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 1
        },
        "order": {
          name: 'order',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
        "categoryId": {
          name: 'categoryId',
          type: 'number'
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        updater: {
          name: 'updater',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'updaterId',
          keyTo: 'id'
        },
        category: {
          name: 'category',
          type: 'RepairItemCategory',
          model: 'RepairItemCategory',
          relationType: 'belongsTo',
                  keyFrom: 'categoryId',
          keyTo: 'id'
        },
        works: {
          name: 'works',
          type: 'RepairWork[]',
          model: 'RepairWork',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'repairItemId'
        },
      }
    }
  }
}
