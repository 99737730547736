import { Component, Input, Output, EventEmitter } from '@angular/core';

/**
 * ファイル選択ダイアログを開くボタン
 *
 * @example
 * <bi-file-selector (fileSelect)="processFile($event)">ファイル選択</bi-file-selector>
 */
@Component({
  selector: 'bi-file-selector',
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.scss']
})
export class FileSelectorComponent {

  /**
   * file inputのaccept属性
   */
  @Input() accept: string;

  /**
   * ファイル選択後に発火するイベント
   */
  @Output('fileSelect') fileSelect = new EventEmitter<File>();

  constructor() { }

  /**
   * ファイル選択されたらにfileSelectイベントをemitする
   *
   * @param e inputのchangeイベント
   */
  onFileSelect(e: Event) {
    const input = e.target as HTMLInputElement;
    const file = input.files.item(0);

    this.fileSelect.emit(file);
  }

}
