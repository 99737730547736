import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Chart } from 'chart.js';
import { forkJoin } from 'rxjs';
import { PatrolApi, InspectionApi } from 'loopback';
import * as moment from 'moment';

@Component({
  selector: 'bi-task-chart-widget',
  templateUrl: './task-chart-widget.component.html',
  styleUrls: ['./task-chart-widget.component.scss']
})
export class TaskChartWidgetComponent implements OnInit {

  chart: Chart<'bar'>;
  intervalControl: FormControl<{ start: moment.Moment; end: moment.Moment }>;

  @ViewChild('chart', { static: true }) chartCanvas: ElementRef;

  constructor(
    private patrolApi: PatrolApi,
    private inspectionApi: InspectionApi,
  ) { }

  ngOnInit() {
    const canvas = this.chartCanvas.nativeElement as HTMLCanvasElement;

    this.chart = new Chart<'bar'>(canvas, {
      type: 'bar',
      data: null,
      options: {
        plugins: {
          legend: {
            position: 'left'
          },
          tooltip: {
            mode: 'x',
            titleFont: { size: 14 },
            bodyFont: { size: 14 },
            padding: { top: 12, bottom: 12, left: 10, right: 10 },
          }
        },
        scales: {
          x: {
            type: 'time',
            stacked: true,
            time: {
              unit: 'day',
              displayFormats: { day: 'M月D日' },
              tooltipFormat: 'YYYY年M月D日',
            },
            ticks: {
              source: 'data'
            },
          },
          y: {
            type: 'linear',
            stacked: true,
            min: 0,
            ticks: {
              stepSize: 1
            }
          }
        },
      }
    });

    this.intervalControl = new FormControl({ start: null, end: null });

    this.intervalControl.valueChanges.subscribe(
      interval => {
        this.updateChart(interval.start, interval.end);
      }
    );

    this.intervalControl.setValue({
      start: moment().startOf('day').subtract(29, 'days'),
      end: moment().startOf('day')
    });
  }

  updateChart(start: moment.Moment, end: moment.Moment) {
    const filter = {
      where: {
        status: { neq: 1 },
        finishedAt: { gte: start.toISOString(), lte: end.toISOString() }
      }
    };

    forkJoin([
      this.patrolApi.find(filter),
      this.inspectionApi.find(filter)
    ]).subscribe(
      res => {
        const [patrols, inspections] = res;
        const patrolData: any = [];
        const inspectionData: any = [];
        const date = start.clone();

        while (date.isSameOrBefore(end, 'day')) {
          patrolData.push({
            x: date.toDate(),
            y: patrols.filter(t => moment(t.finishedAt).isSame(date)).length
          });
          inspectionData.push({
            x: date.toDate(),
            y: inspections.filter(t => moment(t.finishedAt).isSame(date)).length
          });
          date.add(1, 'day');
        }

        this.chart.data = {
          datasets: [
            { label: '巡回', data: patrolData, backgroundColor: 'rgb(156, 39, 176)' },
            { label: '診断', data: inspectionData, backgroundColor: 'rgb(233, 30, 99)' }
          ],
          labels: []
        };
        this.chart.update();
      }
    );
  }
}
