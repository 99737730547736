/* eslint-disable */
import {
  Company,
  RepairPlan,
  RepairWork,
  Staff
} from '../index';

declare var Object: any;
export interface RepairPlanWorkInterface {
  "unitCost"?: number;
  "costMultiplier"?: number;
  "nextPlannedYear"?: number;
  "id"?: number;
  "companyId"?: number;
  "planId"?: number;
  "workId"?: number;
  "_linkage"?: any;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId"?: number;
  company?: Company;
  plan?: RepairPlan;
  work?: RepairWork;
  linkage?: RepairPlanWork[];
  updater?: Staff;
}

export class RepairPlanWork implements RepairPlanWorkInterface {
  "unitCost": number;
  "costMultiplier": number;
  "nextPlannedYear": number;
  "id": number;
  "companyId": number;
  "planId": number;
  "workId": number;
  "_linkage": any;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId": number;
  company?: Company;
  plan?: RepairPlan;
  work?: RepairWork;
  linkage?: RepairPlanWork[];
  updater?: Staff;
  constructor(data?: Partial<RepairPlanWorkInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `RepairPlanWork`.
   */
  public static getModelName() {
    return "RepairPlanWork";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of RepairPlanWork for dynamic purposes.
  **/
  public static factory(data: RepairPlanWorkInterface): RepairPlanWork{
    return new RepairPlanWork(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'RepairPlanWork',
      plural: 'RepairPlanWorks',
      path: 'RepairPlanWorks',
      idName: 'id',
      properties: {
        "unitCost": {
          name: 'unitCost',
          type: 'number'
        },
        "costMultiplier": {
          name: 'costMultiplier',
          type: 'number'
        },
        "nextPlannedYear": {
          name: 'nextPlannedYear',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "planId": {
          name: 'planId',
          type: 'number'
        },
        "workId": {
          name: 'workId',
          type: 'number'
        },
        "_linkage": {
          name: '_linkage',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        plan: {
          name: 'plan',
          type: 'RepairPlan',
          model: 'RepairPlan',
          relationType: 'belongsTo',
                  keyFrom: 'planId',
          keyTo: 'id'
        },
        work: {
          name: 'work',
          type: 'RepairWork',
          model: 'RepairWork',
          relationType: 'belongsTo',
                  keyFrom: 'workId',
          keyTo: 'id'
        },
        linkage: {
          name: 'linkage',
          type: 'RepairPlanWork[]',
          model: 'RepairPlanWork',
          relationType: 'embedsOne',
                  keyFrom: '_linkage',
          keyTo: 'id'
        },
        updater: {
          name: 'updater',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'updaterId',
          keyTo: 'id'
        },
      }
    }
  }
}
