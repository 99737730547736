import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MONTH_PICKER_FORMATS } from 'app/pages/building/building-detail/building-detail.component';
import { OptionsService } from 'app/services/options.service';
import { Building } from 'loopback';
import * as moment from 'moment';

@Component({
  selector: 'bi-repair-plan-creator',
  templateUrl: './repair-plan-creator.component.html',
  styleUrls: ['./repair-plan-creator.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MONTH_PICKER_FORMATS }
  ]
})
export class RepairPlanCreatorComponent implements OnInit {

  form: FormGroup<{
    period: FormControl<number>;
    startYear: FormControl<number>;
    ageCountType: FormControl<number>;
    dealingType: FormControl<number>;
    dateBuilt: FormControl<string | Date>;
    numUnits: FormControl<number>;
    buildingArea: FormControl<number>;
    totalArea: FormControl<number>;
    outerWallArea: FormControl<number>;
  }>;

  get okDisabled() {
    return !this.form.valid;
  }

  constructor(
    public opt: OptionsService,
    private formBuilder: FormBuilder,
    private ref: MatDialogRef<RepairPlanCreatorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { building: Building; message: string | string[] },
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      period: [30, Validators.required],
      startYear: [moment().year(), Validators.required],
      ageCountType: [2, Validators.required],
      dealingType: [this.data.building.dealingType, Validators.required],
      dateBuilt: [this.data.building.dateBuilt, Validators.required],
      numUnits: [this.data.building.numUnits, Validators.required],
      buildingArea: [this.data.building.buildingArea, Validators.required],
      totalArea: [this.data.building.totalArea, Validators.required],
      outerWallArea: [this.data.building.outerWallArea, Validators.required]
    });

    if (!Array.isArray(this.data.message)) {
      this.data.message = [this.data.message];
    }
  }

  onDateSelected(date: moment.Moment, controlName: string, picker: MatDatepicker<moment.Moment>) {
    const control = this.form.get(controlName);
    control.setValue(date);
    control.markAsTouched();
    control.markAsDirty();
    picker.close();
  }

  /**
   * OKボタンクリックハンドラ
   */
  onOkClick() {
    this.ref.close(this.form.value);
  }

}
