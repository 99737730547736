import { Component, HostBinding } from '@angular/core';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatFormFieldControl } from '@angular/material/form-field';
import * as moment from 'moment';
import { IntervalInputBase } from '../../classes/interval-input-base';

export const BI_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'YYYY-MM-DD'
  },
  display: {
    dateInput: 'YYYY年M月',
    monthYearLabel: 'YYYY年M月',
    dateA11yLabel: 'YYYY-MM-DD',
    monthYearA11yLabel: 'YYYY-MM'
  }
};

@Component({
  selector: 'bi-month-interval-input',
  templateUrl: './month-interval-input.component.html',
  styleUrls: ['./month-interval-input.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: MonthIntervalInputComponent },
    { provide: MAT_DATE_FORMATS, useValue: BI_DATE_FORMATS }
  ]
})
export class MonthIntervalInputComponent extends IntervalInputBase {
  static idSeq = 0;
  controlType = 'month-interval-input';

  @HostBinding() id = `bi-month-interval-input-${MonthIntervalInputComponent.idSeq++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';

  onMonthSelected(date: moment.Moment, target: 'start' | 'end', picker: MatDatepicker<moment.Moment>) {
    const value = this.form.value;
    value[target] = date;
    this.writeValue(value);
    this.onChange(value);
    picker.close();
  }
}
