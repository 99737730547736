import { Component, Input, HostBinding } from '@angular/core';

/**
 * 巡回のステータス表示用バッジ
 */
@Component({
  selector: 'bi-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent {
  /**
   * ラベルとアイコンの設定
   */
  badges = {
    untouched: { label: '未作業', icon: 'add_circle'},
    touched: { label: '作成中', icon: 'play_circle_filled'},
    unfinished: { label: '作業予定', icon: 'add_circle'},
    unapproved: { label: '未承認', icon: 'error'},
    approved: { label: '承認済', icon: 'check_circle'},
    sendbacked: { label: '要修正', icon: 'error' },
    unknown: { label: '', icon: ''},
  };

  /**
   * 現在のバッジ
   */
  currentBadge;

  @Input() type: 'horizontal' | 'vertical' = 'horizontal';

  /**
   * バッジ名
   */
  @Input()
  set name(name: string) {
    this._name = name;
    this.currentBadge = this.badges[name] || {};
  }
  private _name: string;

  /**
   * スタイル用クラス
   */
  @HostBinding('class') get class() {
 return `${this._name} ${this.type}`;
}

  constructor() { }

}
